import api from "utils/api/api";

const AddOption = async (data) => {
    try {
        console.log(data);
        const request = await api.post(`api/v1/payment/add-option`, data);

        return request.data;
    } catch (err) {
        throw err;
    }
};

export default AddOption;
