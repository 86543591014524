import api from "utils/api/api";

const UpdateMusic = async ({ script_id, data }) => {
    try {
        const response = await api.put(`/api/v1/script/${script_id}/music`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { UpdateMusic };
