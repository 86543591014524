import { useState, useEffect } from "react";

const ProgressProvider = ({ valueStart, valueEnd, children, speed }) => {
    const [value, setValue] = useState(valueStart);

    useEffect(() => {
        // create a interval with 10ms delay and add 1 to value state on each interval
        const interval = setInterval(() => {
            setValue((value) => {
                const newValue = value + 1;
                if (newValue >= valueEnd) {
                    clearInterval(interval);
                    return valueEnd;
                }
                return newValue;
            });

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, speed || 10);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueEnd]);

    return children(value);
};
export default ProgressProvider;
