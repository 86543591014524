import api from "utils/api/api";

const ChangePassword = async ({ password }) => {
    try {
        const response = await api.post("/api/v1/auth/change-password", {
            password,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { ChangePassword };
