import "./PaymentMethod.scss";

const PaymentMethod = ({ method, onClick, selectionned }) => {
    const Icon = method.icon;

    return (
        <div onClick={onClick} className={`${selectionned ? "selected" : ""} PaymentMethodContainer`}>
            {/* {method?.isDefault && (
                <div className="Default">
                    <p>Par défaut</p>
                </div>
            )} */}
            <div className="PaymentMethodContent">
                <Icon className="Icon" style={{ fill: "red" }} />
                <p className="text-sm">{method?.name || "Carte Bancaire"}</p>
            </div>
        </div>
    );
};

export default PaymentMethod;
