import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import * as XLSX from "xlsx";

import { CallTableRow, Layout, Table } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import { useGetAllCallsLogs } from "utils/api/call/GetAllCallsLogs";
import { PatchCallLog } from "utils/api/call/PatchCallLog";
import { GetVoicemailFile } from "utils/api/call/GetVoicemailFile";

import appel_type from "utils/data/journal/appel-type.json";

import "./JournalAppel.scss";

const JournalAppel = () => {
    const [filterActive, setFilterActive] = useState(window.screen.width >= 768 ? true : false);

    const { telephoneSwitchboard } = useMainContext();
    const { showMessage } = useMessageContext();

    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [filterSelect, setFilterSelect] = useState(appel_type[0]);
    const [dates, setDates] = useState({ minDate: "", maxDate: "", gte: "", lte: "" });

    const { data } = useGetAllCallsLogs({
        standard_number_id: telephoneSwitchboard.get?.standard_number?.id,
        skip: (page - 1) * 10,
        limit: itemsPerPage,
        search,
        filter: filterSelect.id,
        minDate: dates.minDate,
        maxDate: dates.maxDate,
    });

    const [listAppelsState, setListAppel] = useState([]);

    const [dropdown, setDropdown] = useState(false);

    const [audio, setAudio] = useState(new Audio());
    const [audioInfo, setAudioInfo] = useState({ call_id: null, paused: true });

    const playResumeAudio = async (call_id) => {
        if (audioInfo.call_id === call_id) {
            if (audio.paused) {
                audio.play();
                setAudioInfo({ call_id, paused: false });
            } else {
                audio.pause();
                setAudioInfo({ call_id, paused: true });
            }
            return;
        }

        if (!audio.src) {
            audio.pause();

            audio.removeEventListener("ended", () => {
                setAudio(new Audio());
            });
        }

        const response = await GetVoicemailFile({ call_id });

        if (response.success) {
            const audioData = response.data;

            const url = "data:audio/wav;base64," + audioData;

            audio.src = url;

            audio.addEventListener("ended", () => {
                setAudio(new Audio());
                setAudioInfo({ call_id: null, paused: false });
            });

            audio.play();

            setAudioInfo({ call_id, paused: false });
        } else {
            console.error(response.error);
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", audio.pause());

        return () => {
            window.removeEventListener("beforeunload", audio.pause());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.data?.logs) {
            (async () => {
                const tab = await transformList(data?.data?.logs);

                setDates((prev) => ({ ...prev, gte: data?.data?.dateGte, lte: data?.data?.dateLte }));
                setListAppel(tab);
            })();
        }
    }, [data]);

    const transformList = async (list) => {
        const appels = [];

        await Promise.all(
            list.map((x) => {
                const dateMonth = new Date(x.date).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
                const dateTime = new Date(x.date).toLocaleTimeString();
                const timestamp = new Date(x.date).getTime();

                appels.push({
                    id: x.id,
                    filter: x.call_type_id === 1 ? 4 : x.call_type_id === 2 ? 2 : 3,
                    icon:
                        x.call_type_id === 1
                            ? "fas fa-voicemail yellow pl-2"
                            : x.call_type_id === 2
                            ? "fas fa-phone-plus green pl-2 mr-2 greenphone"
                            : "fas fa-phone-slash red pl-2 mr-2 redphone",
                    date: `${dateMonth} à ${dateTime}`,
                    correspondant: x.to.replace(/(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5 $6"),
                    color: x.call_type_id === 1 ? "yellow" : x.call_type_id === 2 ? "green" : "red",
                    tel: x.from,
                    time: x.time,
                    commentaire: x.comment,
                    timestamp: timestamp,
                    message_id: x.message_id,
                });
                return true;
            })
        );

        return appels;
    };

    const changeDate = () => {
        const date1 = new Date(document.querySelector("#date1").value);
        const date2 = new Date(document.querySelector("#date2").value);

        const minDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 2, 0, 0);
        const maxDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 25, 59, 59);

        if (date1 > date2) {
            return showMessage({
                text: "La date de début doit être inférieur à la date de fin",
                type: "error",
            });
        }

        setDates({ ...dates, minDate, maxDate });
    };

    const updateComment = async (id, commentaire) => {
        const response = await PatchCallLog(id, commentaire);

        if (!response.success) {
            return showMessage({
                text: "Une erreur est survenue lors de la modification du commentaire",
                type: "error",
            });
        } else {
            showMessage({
                text: "Le commentaire à bien été modifié",
                type: "success",
            });
        }

        const tamp = [];

        listAppelsState.map((x) => {
            if (x.id === id) {
                x.commentaire = commentaire;
            }
            tamp.push(x);
            return true;
        });

        setListAppel(tamp);
    };

    const downloadXlsx = async () => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Call Logs",
            Subject: "Call Logs",
            Author: "Standard en Ligne",
            CreatedDate: new Date(),
        };
        wb.SheetNames.push("Call Logs");

        const tab = [];

        await Promise.all(
            listAppelsState.map((x) => {
                tab.push({
                    Id: x.id,
                    "Date de l'appel": x.date,
                    "Numéro appelé": x.correspondant,
                    "Numéro appelant": x.tel,
                    "Durée de l'appel": x.time,
                    Commentaire: x.commentaire,
                    "Résultat de l'appel": x.filter === 2 ? "Réussi" : x.filter === 3 ? "Echoué" : "Messagerie",
                });
                return true;
            })
        );

        const ws = XLSX.utils.json_to_sheet(tab);
        XLSX.utils.book_append_sheet(wb, ws, "Call_Logs");

        wb.Sheets["Call Logs"] = ws;

        const wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "binary",
        });

        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
            const view = new Uint8Array(buf); //create uint8array as viewer
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
            return buf;
        };

        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Call Logs - " + new Date().toLocaleDateString() + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Layout id="JournalAppel">
            <div className="JournalContainer">
                <span className="FilterText">Filtrer par :</span>
                <i onClick={() => setFilterActive(!filterActive)} className="fad fa-sort-amount-down-alt filter" />

                {filterActive && (
                    <>
                        <div className="FilterContainer select">
                            <button
                                onClick={() => setDropdown(!dropdown)}
                                type="button"
                                className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm h-9"
                                aria-haspopup="listbox"
                                aria-expanded="true"
                                aria-labelledby="listbox-label"
                            >
                                <div className="select-filter flex items-center">
                                    <i className={filterSelect.icon} />
                                    <span className=" block truncate ml-3">{filterSelect.text}</span>
                                </div>

                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg
                                        className="h-5 w-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </button>
                            <ul
                                style={dropdown ? { display: "block" } : { display: "none" }}
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                tabIndex="-1"
                                role="listbox"
                                aria-labelledby="listbox-label"
                                aria-activedescendant="listbox-option-3"
                            >
                                {appel_type.map((x) => {
                                    return (
                                        <li
                                            key={x.id}
                                            onClick={() => {
                                                setDropdown(false);
                                                setFilterSelect(x);
                                            }}
                                            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                                            id="listbox-option-0"
                                        >
                                            <div className="flex items-center">
                                                <i className={x.icon} />

                                                <span className="font-normal ml-3 block truncate">{x.text}</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="FilterDate">
                            <div className="input2">
                                <input
                                    key={dates?.gte}
                                    onChange={changeDate}
                                    type="date"
                                    name="date"
                                    id="date1"
                                    max={
                                        dates?.lte !== "" && dates?.lte !== undefined
                                            ? new Date()?.toISOString().substring(0, 10)
                                            : new Date()?.toISOString().split("T")[0]
                                    }
                                    defaultValue={
                                        dates?.gte !== "" && dates?.gte !== undefined
                                            ? new Date(dates?.gte)?.toISOString()?.substring(0, 10)
                                            : new Date()?.toISOString().split("T")[0]
                                    }
                                    className="shadow-sm block w-25 sm:text-sm border-gray-300 focus:outline-none focus:ring-1 rounded-md h-9 border-[1px] pl-2"
                                />
                            </div>
                            <i className="fas fa-arrows-h text-gray-300 "></i>
                            <div className="input3">
                                <input
                                    onChange={changeDate}
                                    type="date"
                                    name="date"
                                    id="date2"
                                    min={
                                        dates?.gte !== "" && dates?.gte !== undefined
                                            ? new Date(dates?.gte).toISOString().substring(0, 10)
                                            : new Date(
                                                  new Date().getFullYear(),
                                                  new Date().getMonth(),
                                                  new Date().getDate() + 1,
                                                  23,
                                                  59,
                                                  59
                                              )

                                                  .toISOString()
                                                  .split("T")[0]
                                    }
                                    defaultValue={
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate() + 1,
                                            23,
                                            59,
                                            59
                                        )
                                            .toISOString()
                                            .split("T")[0]
                                    }
                                    className="shadow-sm block w-25 sm:text-sm border-gray-300 focus:outline-none focus:ring-1 rounded-md h-9 border-[1px] pl-2"
                                />
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="relative text-gray-600 focus-within:text-gray-400">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            viewBox="0 0 24 24"
                                            className="w-6 h-5"
                                        >
                                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                        </svg>
                                    </button>
                                </span>

                                <input
                                    type="search"
                                    name="q"
                                    className="py-2 border-gray-300 text-sm text-white rounded-md pl-10 focus:bg-white focus:text-gray-900 cursor-default focus:outline-none focus:ring-1 shadow-sm sm:text-sm border-[1px] h-9 w-52"
                                    placeholder="Commentaire, Numéro..."
                                    autoComplete="off"
                                    onChange={(el) => setSearch(el.target.value)}
                                ></input>
                            </div>
                        </div>

                        <button
                            onClick={() => downloadXlsx()}
                            type="button"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-400 hover:text-gray-600 bg-gray-300 hover:bg-gray-300  "
                        >
                            .XLS
                        </button>
                    </>
                )}
            </div>

            <Table
                className="mt-8"
                listHead={[
                    [<input key="checkbox" type="checkbox" />],
                    "DATE",
                    "CORRESPONDANT",
                    "TÉLÉPHONE",
                    "DURÉE",
                    "COMMENTAIRE",
                ]}
                options={{ isFixed: true }}
            >
                {listAppelsState &&
                    listAppelsState.map((x) => (
                        <CallTableRow
                            key={x.id}
                            data={x}
                            updateComment={updateComment}
                            playResumeAudio={playResumeAudio}
                            audioInfo={audioInfo}
                        />
                    ))}
            </Table>

            <ReactPaginate
                nextLabel={<i className="fas fa-chevron-right"></i>}
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={data?.data?.total / itemsPerPage}
                previousLabel={<i className="fas fa-chevron-left"></i>}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </Layout>
    );
};

export default JournalAppel;
