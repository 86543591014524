import api from "utils/api/api";

const PatchAudioMessage = async ({ audio_message_id, data }) => {
    try {
        const response = await api.patch(`/api/v1/script/audio-message/${audio_message_id}`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { PatchAudioMessage };
