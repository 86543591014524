import { useEffect, useState } from "react";
import "./Checkbox.scss";

const InputCheckbox = ({ id, value, checked, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked !== undefined ? checked : false);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
        onChange && onChange(id);
    };

    return (
        <label className="CheckboxBtn">
            {value || ""}
            <input type="checkbox" name="checkbox" onChange={handleCheckbox} checked={isChecked} />
            <span className="checkmark"></span>
        </label>
    );
};

export default InputCheckbox;
