import api from "utils/api/api";
import { useQuery } from "react-query";

const storeData = {};

const GetOutOfPackageRateByPrefix = async ({ prefix }) => {
    try {
        // check if data is already stored
        if (storeData[prefix]) return storeData[prefix];

        const response = await api.get("/api/v1/out-of-package/rates/" + prefix);

        // store data
        storeData[prefix] = response.data;

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetOutOfPackageRateByPrefix = ({ prefix }) =>
    useQuery(["getOutOfPackageRateByPrefix", prefix], async () => await GetOutOfPackageRateByPrefix({ prefix }), {
        enabled: prefix !== undefined,
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
    });

export { GetOutOfPackageRateByPrefix, useGetOutOfPackageRateByPrefix };
