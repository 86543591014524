import AjoutCredits from "./AjoutCredits";
import EnvoyerSms from "./EnvoyerSms";
import AjoutProfil from "./AjoutProfil";
import AjoutChoix from "./AjoutChoix";
import Redirection from "./Redirection";
import EnregistrementAppel from "./EnregistrementAppel";
import PortabiliteEntrante from "./PortabiliteEntrante";
import PortabiliteSortante from "./PortabiliteSortante";
import PackTelesecretariat from "./PackTelesecretariat";

const Boutique = {
    AjoutCredits,
    EnvoyerSms,
    AjoutProfil,
    Redirection,
    AjoutChoix,
    EnregistrementAppel,
    PortabiliteEntrante,
    PortabiliteSortante,
    PackTelesecretariat,
};

export default Boutique;
