const getBase64FromFile = (file, callback) => {
    try {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            callback(reader.result);
        };

        reader.onerror = (error) => {
            console.error("Error: ", error);
        };
    } catch (err) {
        throw err;
    }
};

export default getBase64FromFile;
