import api from "../api";

const CreateCheckoutSession = async ({ customer_id, standard_number_id, amount }) => {
    try {
        const response = await api.post("api/v1/payment/create-checkout-session", {
            customer_id,
            standard_number_id,
            amount,
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export default CreateCheckoutSession;
