const SidebarTitle = ({ onClick, title, Imagesvg }) => {
    return (
        <div onClick={onClick} className="flex preventClose">
            <Imagesvg className="mr-6 flex-shrink-0 h-6 w-6 text-indigo-300 preventClose" />
            <span className="preventClose">{title}</span>
        </div>
    );
};

export default SidebarTitle;
