import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Layout, HeadPage, Boutique, Popup, InputList } from "components";

import useMainContext from "contexts/MainContext";

import image from "assets/images/boutique/ajout-profil.svg";

import check from "assets/picto/validate.svg";
import error from "assets/images/boutique/error.svg";

import configurationImg from "assets/images/boutique/configuration.png";
import configurationHoraire from "assets/images/boutique/configuration-horaire.png";

import "./AjoutProfil.scss";

const AjoutProfil = () => {
    const { telephoneSwitchboard } = useMainContext();

    const navigate = useNavigate();

    const [selectedPopup, setSelectedPopup] = useState(null);

    const close = (data) => {
        if (data === "close") {
            setSelectedPopup(null);
            return;
        }

        if (data?.success) return selectedPopup(1);

        setSelectedPopup(2);
    };

    return (
        <Layout>
            <HeadPage title="Ajouter un profil" isProfil={false} />
            <Boutique.Option
                description="Ajouter un profil vous permet d'indiquer des règlages différents (redirections téléphoniques, annonces vocales, paramètres, routages des appels...) en fonctions des plages horaires"
                img={image}
                bottomDesc={{
                    text: "L'ajout d'un profil coute 10€ HT par mois. Cette somme sera rajoutée à votre abonnement mensuel.",
                    className: "bottom",
                }}
                button={{
                    text: "Ajouter",
                    onClick: () => setSelectedPopup(0),
                }}
            />

            {selectedPopup === 0 && (
                <Popup.PopupStripe
                    onClose={close}
                    option={{
                        id: 1,
                        price: 10,
                        name: "Ajout d'un profil",
                    }}
                    desc={`Achat de l'option Profil supplémentaire (10€ HT par mois)`}
                    standard_number={telephoneSwitchboard?.get?.standard_number?.standard_number}
                />
            )}
            {selectedPopup === 1 && (
                <Popup.PopupClassic className="PopupSuccess">
                    <h2>Le profil a été crée</h2>
                    <img src={check} alt="success" loading="lazy" />
                    <div className="PopupSuccessBtn">
                        <button onClick={() => setSelectedPopup(null)} className="config-plus-tard">
                            Configurer plus tard
                        </button>
                        <button onClick={() => setSelectedPopup(3)}>Configurer le profil</button>
                    </div>
                </Popup.PopupClassic>
            )}
            {selectedPopup === 2 && (
                <Popup.PopupClassic className="PopupFailed">
                    <h2>Le paiement a échoué</h2>
                    <img src={error} alt="failed" loading="lazy" />
                    <button onClick={() => setSelectedPopup(null)}>Fermer</button>
                </Popup.PopupClassic>
            )}
            {selectedPopup === 3 && (
                <Popup.PopupClassic className="PopupConfig">
                    <h2>Configuration profil</h2>
                    <div className="Name">
                        <h2>Nom :</h2>
                        <InputList.InputClassic placeholder="Yanisse" wCustom="w-60" />
                    </div>
                    <div className="PopupConfigBtn">
                        <button onClick={() => setSelectedPopup(null)} className="Enregistrer">
                            Enregistrer
                        </button>
                        <button onClick={() => setSelectedPopup(4)} className="Continuer">
                            Continuer
                        </button>
                    </div>
                </Popup.PopupClassic>
            )}
            {selectedPopup === 4 && (
                <Popup.PopupClassic className="PopupSuccess">
                    <h2>Configuration profil</h2>
                    <img src={configurationImg} alt="config-redirect-numbers" loading="lazy" />
                    <span>
                        Vous pouvez ajouter vos numéros de renvois, à la page « Numéro de renvois » dans l'onglet
                        configuration.
                    </span>
                    <button onClick={() => setSelectedPopup(5)} className="Continuer">
                        Continuer
                    </button>
                </Popup.PopupClassic>
            )}
            {selectedPopup === 5 && (
                <Popup.PopupClassic className="PopupSuccess">
                    <h2>Configuration profil</h2>
                    <img src={configurationHoraire} alt="config-schedule" loading="lazy" />
                    <span>Vous pouvez ajouter la plage horaire du profil crée.</span>
                    <button onClick={() => navigate("/config/numero-renvois")} className="Continuer">
                        Continuer
                    </button>
                </Popup.PopupClassic>
            )}
        </Layout>
    );
};

export default AjoutProfil;
