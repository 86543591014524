import React from "react";
import "./StepDot.scss";

const StepDot = ({ isActive, isCompleted, isFirstStep, step }) => {
    let dotClass = "circle-dot";

    if (isActive) {
        dotClass += " active";
    } else if (isCompleted) {
        dotClass += " completed";
    } else if (isFirstStep) {
        dotClass += " first";
    }

    return <span className={dotClass}></span>;
};

export default StepDot;
