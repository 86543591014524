import React, { useState, useEffect } from "react";

import "./SyntheseVocal.scss";

import useMessageContext from "contexts/MessageContext";

import { InputList } from "components";

import voiceList from "utils/config/studio/voice.json";

import { PatchAudioMessage } from "utils/api/config/StudioVocal/SyntheseVocal/PatchAudioMessage";

const SyntheseVocal = ({ data, updateMessage, onSave }) => {
    const { showMessage } = useMessageContext();

    const [info, setInfo] = useState({ text: data.text || "", update: false });
    const [voiceId, setVoiceId] = useState(voiceList[0].id);

    useEffect(() => {
        if (data.text) {
            setInfo({ ...info, text: data.text });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [isLoading, setIsLoading] = useState(false);

    const [audio] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (data.audio_path) {
            audio.src = data.audio_path;

            audio.addEventListener("ended", () => {
                setIsPlaying(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.audio_path]);

    const updateVocal = async ({ text, voiceId }) => {
        if (onSave) return onSave({ text, voiceId });

        setIsLoading(true);

        const req = await PatchAudioMessage({
            audio_message_id: data.id,
            data: {
                text: text || "",
                voiceId,
            },
        });

        setIsLoading(false);

        if (!req.success) {
            showMessage({
                text: "Une erreur est survenue lors de la mise à jour du message vocal",
                type: "error",
            });
            return;
        }

        updateMessage(text || "");
        setInfo({ ...info, update: false });

        showMessage({
            text: "Le message vocal a bien été mis à jour",
            type: "success",
        });

        audio.pause();

        audio.removeEventListener("ended", () => {
            audio.src = "";
        });

        audio.src = req.data.audio_path;

        audio.addEventListener("ended", () => {
            setIsPlaying(false);
        });

        playAudio();
    };

    const setText = (e) => {
        setInfo({ ...info, text: e });
    };

    const setVoice = (e) => {
        setVoiceId(e.id);
    };

    useEffect(() => {
        if (info.text !== data.text || voiceId !== data.voiceId) {
            setInfo({ ...info, update: true });
        } else {
            setInfo({ ...info, update: false });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info.text, data, voiceId]);

    const playAudio = () => {
        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="SyntheseVocalContainer">
            <div className="SyntheseVocalInput">
                <p className="SyntheseTitle">Composez librement votre message :</p>
                <div className="text-area flex flex-col relative">
                    <InputList.InputTextarea onChange={(e) => setText(e)} defaultValue={info.text} />
                    <div className="absolute bottom-2 right-2">
                        {isLoading ? (
                            <div className="flex items-center">
                                <svg
                                    version="1.1"
                                    id="L9"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    enableBackground="new 0 0 0 0"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="var(--primary-blue)"
                                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                    >
                                        <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            dur="1s"
                                            from="0 50 50"
                                            to="360 50 50"
                                            repeatCount="indefinite"
                                        />
                                    </path>
                                </svg>
                                <p>Chargement...</p>
                            </div>
                        ) : info.update === false ? (
                            !isPlaying ? (
                                <InputList.InputButton
                                    key="play"
                                    text={
                                        <div key="play" className="flex items-center">
                                            <i className="far fa-volume-up" /> <p key="p2">Ecouter</p>
                                        </div>
                                    }
                                    onClick={playAudio}
                                    className="textarea-button"
                                />
                            ) : (
                                <InputList.InputButton
                                    key="pause"
                                    text={
                                        <div key="stop" className="flex items-center">
                                            <i className="fas fa-volume-mute" />
                                            <p>Mettre en pause</p>
                                        </div>
                                    }
                                    onClick={playAudio}
                                    className="textarea-button"
                                />
                            )
                        ) : (
                            <InputList.InputButton
                                key="save"
                                text={
                                    <div key="save" className="flex items-center">
                                        <i className="fas fa-save" /> <p>Enregistrer</p>
                                    </div>
                                }
                                onClick={() => updateVocal({ text: info.text, voiceId })}
                                className="textarea-button"
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="SyntheseVocalVoice">
                <div className="w-fit">
                    <p className="SyntheseTitle">Sélectionnez votre choix</p>

                    <InputList.InputDropdown
                        defaultValue={voiceList[0].text}
                        list={voiceList}
                        wCustom={"w-60"}
                        onChange={setVoice}
                    />
                </div>
            </div>
        </div>
    );
};

export default SyntheseVocal;
