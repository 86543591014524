import React, { useEffect, useState } from "react";

import useGetAllCreditPackage from "utils/api/boutique/credit/GetAllCreditPackage";
import useUpdateAutoRecharge from "utils/api/customer/billing/UpdateAutoRecharge";

import { Layout, HeadPage, Compte } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

const RechargeAutomatique = () => {
    const { customer } = useMainContext();
    const { showMessage } = useMessageContext();

    const { data: response, isLoading } = useGetAllCreditPackage();
    const { mutateAsync: UpdateAutoRecharge } = useUpdateAutoRecharge();

    const [selectedId, setSelectedId] = useState(customer.get?.customer_billing?.credit_package_id);

    useEffect(() => {
        setSelectedId(customer.get?.customer_billing?.credit_package_id);
    }, [customer.get?.customer_billing?.credit_package_id]);

    const handleSave = async (id) => {
        setSelectedId(id);

        const request = await UpdateAutoRecharge({ customer_id: customer.get?.id, credit_package_id: id });

        if (request?.success) {
            showMessage({
                type: "success",
                text: "La recharge automatique a bien été modifiée",
            });

            return;
        }

        showMessage({
            type: "error",
            text: "Une erreur est survenue lors de la modification de la recharge automatique",
        });
    };

    return (
        <Layout>
            <HeadPage isProfil={false} title="Recharge automatique" />
            <p>
                La recharge automatique est le montant débité lorsque avez consommé la totalité de votre forfait. Le
                montant de recharge automatique minimum est fixé à <strong>20€ HT</strong>. Vous pouvez sélectionner un
                autre montant si vous souhaitez augmenter la recharge automatique.
            </p>
            <div className="flex flex-wrap justify-center pt-16 gap-4">
                {!isLoading &&
                    response?.data?.map((item) => {
                        return (
                            <Compte.RechargeAutomatique.Card
                                key={item.id}
                                amount={item.price}
                                isActive={item.id === selectedId}
                                onClick={() => handleSave(item.id)}
                            />
                        );
                    })}
            </div>
        </Layout>
    );
};

export default RechargeAutomatique;
