const CheckAudioFile = (file) => {
    // CHECK IF IS UNDEFINED
    if (file === undefined) {
        return;
    }

    // CHECK IF FILE IS MP3
    if (file.type !== "audio/mpeg") {
        // alert("Le fichier n'est pas un mp3");
        return false;
    }

    // CHECK IF FILE IS BIGGER THAN 5MB
    if (file.size > 5000000) {
        // alert("Le fichier est trop lourd");
        return false;
    }

    // CHECK IF FILE IS NOT EMPTY
    if (file.size === 0) {
        // alert("Le fichier est vide");
        return false;
    }

    return true;
};

export default CheckAudioFile;
