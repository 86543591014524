import { useEffect, useState } from "react";

import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ProgressProvider from "hooks/useProgressProvider";

import "./ConsoCard.scss";

import useMainContext from "contexts/MainContext";

import useGetConsumptionForStandardNumber from "utils/api/consumption/GetConsumptionForStandardNumber";
import useGetInvoiceUpcomingForStandardNumber from "utils/api/customer/invoice/GetInvoiceUpcoming";

const ConsoCard = () => {
    const { customer, telephoneSwitchboard } = useMainContext();

    const [forfaitPercent, setForfaitPercent] = useState(0);

    const [numberOfDays, setNumberOfDays] = useState(0);
    // date1 = the first day and first month of the current year
    const date1 = new Date(new Date().getFullYear(), 0, 1);

    const { data: conso } = useGetConsumptionForStandardNumber({
        standard_number_id: telephoneSwitchboard.get?.standard_number_id,
    });

    const { data: invoice } = useGetInvoiceUpcomingForStandardNumber({
        standard_number_id: telephoneSwitchboard.get?.standard_number_id,
    });

    useEffect(() => {
        if (conso?.data && telephoneSwitchboard.get) {
            const time = conso?.data?.time.split("T")[1].split(".")[0];

            const hour = time.split(":")[0];
            const minutes = time.split(":")[1];
            const seconds = time.split(":")[2];

            const totalSeconds = parseInt(hour) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);

            const forfait = telephoneSwitchboard.get?.telephone_package?.hour_number;

            const percent = ((totalSeconds / (forfait * 3600)) * 100).toFixed(2);

            setForfaitPercent(percent);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conso?.data, telephoneSwitchboard.get]);

    useEffect(() => {
        if (invoice?.data?.next_payment_attempt && numberOfDays === 0) {
            const date_payment = invoice?.data?.next_payment_attempt * 1000;
            const date = new Date(date_payment);

            getNumbersDaysBeetweenDates(date);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice?.data?.next_payment_attempt]);

    const getNumbersDaysBeetweenDates = (date) => {
        const diffTime = Math.abs(new Date(date) - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setNumberOfDays(diffDays - 1);
    };

    const addDaysToDate = (days) => {
        const result = new Date(date1);
        result.setDate(result.getDate() + days);

        // convert result to day month
        const options = { month: "long", day: "numeric" };

        return result.toLocaleDateString("fr-FR", options);
    };

    return (
        <div id="conso">
            <div className="card">
                <p>Forfait</p>
                <div className="ProgressBarContainer">
                    <ProgressProvider valueStart={0} valueEnd={forfaitPercent} speed={5}>
                        {(value) => (
                            <CircularProgressbarWithChildren
                                value={value}
                                styles={buildStyles({
                                    pathColor: "var(--primary-blue)",
                                    trailColor: "#FBFBFB",
                                    strokeLinecap: "butt",
                                })}
                            >
                                <p className="card-forfait">
                                    <span className="percent">{Number(value)?.toFixed(2)}</span> %
                                </p>
                            </CircularProgressbarWithChildren>
                        )}
                    </ProgressProvider>
                </div>
            </div>

            <div className="card">
                <p>Crédits restants</p>
                <ProgressProvider
                    valueStart={0}
                    valueEnd={customer.get?.credit_balance || 0}
                    speed={customer.get?.credit_balance > 100 ? 1 : 5}
                >
                    {(value) => (
                        <div className="card-content">
                            <span id="credits-value">{value.toFixed(2)}€</span>
                        </div>
                    )}
                </ProgressProvider>
            </div>

            <div className="card">
                <p>Prochaine facture</p>
                <ProgressProvider valueStart={0} valueEnd={numberOfDays} speed={1}>
                    {(value) => (
                        <div className="card-content">
                            <span className="upcoming">{addDaysToDate(value)}</span>
                        </div>
                    )}
                </ProgressProvider>
            </div>
        </div>
    );
};

export default ConsoCard;
