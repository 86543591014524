import { InputList } from "components";

import "./Option.scss";

const Option = ({ description, img, bottomDesc, button }) => {
    return (
        <div className="Option">
            <p>{description}</p>
            <img src={img} alt="img-option" loading="lazy" />
            <span className={bottomDesc?.className}>{bottomDesc?.text}</span>
            <InputList.InputButton text={button?.text} onClick={button?.onClick} />
        </div>
    );
};

export default Option;
