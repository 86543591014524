import api from "utils/api/api";

const CreateCampaign = async ({ campaign }) => {
    try {
        const reponse = await api.post("/api/v1/sms/sms-campaign", {
            name: campaign?.name,
            text: campaign?.text,
            isFile: campaign?.isFile,
            numbers: campaign?.numbers,
        });

        return reponse.data;
    } catch (err) {
        throw err;
    }
};

export default CreateCampaign;
