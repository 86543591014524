import api from "../../api";

const NewPorta = async ({ standard_number, payment_method_id }) => {
    try {
        const response = await api.post("/api/v1/payment/portabilite", {
            standard_number,
            payment_method_id,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default NewPorta;
