import api from "utils/api/api";

import { useQuery } from "react-query";

const GetOutOfPackageRates = async ({ skip, limit, search }) => {
    try {
        let params = {};

        if (skip !== undefined) params.skip = skip;
        if (limit !== undefined) params.limit = limit;
        if (search !== undefined) params.search = search;

        const response = await api.get("/api/v1/out-of-package/rates", {
            params,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetOutOfPackageRates = ({ skip, limit, search }) =>
    useQuery(
        ["getOutOfPackageRates", skip, limit, search],
        async () => await GetOutOfPackageRates({ skip, limit, search }),
        {
            staleTime: 1000 * 60 * 60 * 24,
        }
    );

export { GetOutOfPackageRates, useGetOutOfPackageRates };
