import { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

import Pays from "utils/pays-forfait.json";

const InputDropdownCountry = ({ className, word, id, onChange }) => {
    const list = [...Pays.france, ...Pays.europe, ...Pays.world];

    const [dropdown, setDropdown] = useState(false);
    const [select, setSelect] = useState(list[0]);

    const handleChange = (value) => {
        setDropdown(false);
        onChange && onChange(value);
        // console.log(value);
    };

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (!e.target.closest(".input-dropdown")) {
                setDropdown(false);
            }
        });
    }, []);

    return (
        <div className={`relative select ${className || ""} input-dropdown pr-3 `}>
            <button
                onClick={() => setDropdown(!dropdown)}
                type="button"
                className="relative w-max bg-white border-[1px] border-gray-300 rounded-md shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 sm:text-sm h-9 flex items-center"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
            >
                <div className="select-filter flex items-center w-full">
                    <ReactCountryFlag
                        countryCode={select.flag}
                        svg
                        style={{
                            width: "1em",
                            height: "1em",
                        }}
                        title={select.name}
                    />
                    {/* <span id={id || ""} className="ml-3 block truncate">
                        {select.name}
                    </span> */}
                </div>

                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            </button>

            <ul
                style={dropdown ? { display: "block" } : { display: "none" }}
                className="absolute z-10 mt-1 w-max bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                tabIndex={-1}
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
            >
                {list.map((x, i) => {
                    return (
                        <li
                            key={i}
                            onClick={() => {
                                setSelect(x);
                                handleChange(x);
                            }}
                            className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                            id="listbox-option-0"
                            role="option"
                            aria-selected="true"
                        >
                            <div onClick={handleChange} className="flex items-center">
                                <ReactCountryFlag
                                    key={"pays-" + i}
                                    countryCode={x.flag}
                                    svg
                                    style={{
                                        width: "1em",
                                        height: "1em",
                                    }}
                                    title={x.name}
                                />
                                <span className="ml-3 font-normal block truncate">{x.name}</span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default InputDropdownCountry;
