import { useEffect, useState } from "react";

import "./IdentifiantConnexion.scss";

import { InputList } from "components";

import useMessageContext from "contexts/MessageContext";

import { ChangePassword } from "utils/api/auth/ChangePassword";

const IdentifiantConnexion = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const { showMessage } = useMessageContext();

    const [checkPassword, setCheckPassword] = useState({
        upperCase: false,
        lowerCase: false,
        number: false,
        character: false,
        count: false,
        match: false,
        isActive: false,
    });

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const validatePassword = (pwd1, pwd2) => {
        console.log(pwd1, pwd2);

        // has uppercase letter
        const checkUpper = /[A-Z]/.test(pwd1);

        // has lowercase letter
        const checkLower = /[a-z]/.test(pwd1);

        // has number
        const checkNumber = /[0-9]/.test(pwd1);

        // has special character
        // eslint-disable-next-line no-useless-escape
        const checkCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(pwd1);

        // has 8 characters
        const checkLength = pwd1.length > 8;

        // passwords match
        const checkMatch = pwd1 !== "" && pwd1 === pwd2;

        setCheckPassword({
            upperCase: checkUpper,
            lowerCase: checkLower,
            number: checkNumber,
            character: checkCharacter,
            count: checkLength,
            match: checkMatch,
            isActive: true,
        });

        // check all parameters
        // console.log(checkUpper, checkLower, checkNumber, checkCharacter, checkLength, checkMatch);

        if (checkUpper && checkLower && checkNumber && checkCharacter && checkLength && checkMatch) {
            return true;
        } else {
            return false;
        }
    };

    const updatePassword = async () => {
        const check = validatePassword(password, password2);

        if (check) {
            const res = ChangePassword({ password });

            if (!res.success) {
                showMessage({
                    text: "Votre mot de passe a été changé avec succès!",
                    type: "success",
                });
                return;
            } else {
                showMessage({
                    text: "Une erreur est survenue lors de la modification de votre mot de passe",
                    type: "error",
                });
                return;
            }
        }

        showMessage({
            text: "Veuillez vérifier si votre mot de passe correspondant aux conditions",
            type: "error",
        });
        return;
    };

    useEffect(() => {
        if (checkPassword?.isActive) {
            validatePassword(password, password2);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, password2]);

    return (
        <div className="password-container">
            <InputList.InputClassic
                label="Nouveau mot de passe"
                placeholder="********"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type={showPassword ? "text" : "password"}
                icon={
                    <i
                        style={{ color: "#264a6e" }}
                        className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                }
            />
            <InputList.InputClassic
                label="Confirmez le nouveau mot de passe"
                placeholder="********"
                onChange={(e) => setPassword2(e.target.value)}
                id="password2"
                type={showPassword1 ? "text" : "password"}
                icon={
                    <i
                        style={{ color: "#264a6e" }}
                        className={showPassword1 ? "fas fa-eye-slash" : "fas fa-eye"}
                        onClick={() => setShowPassword1(!showPassword1)}
                        id="telecom"
                    />
                }
            />
            {checkPassword?.isActive && (
                <div className="passwordCard" id="input">
                    <p className="title">Votre mot de passe doit contenir:</p>
                    <div className="passwordText">
                        <p>
                            <i
                                className={`${checkPassword.upperCase ? "far fa-check-circle" : "far fa-times-circle"}`}
                            />
                            <span>Possède une majuscule</span>
                        </p>
                        <p>
                            <i
                                className={`${checkPassword.lowerCase ? "far fa-check-circle" : "far fa-times-circle"}`}
                            />
                            <span>Possède une minuscule</span>
                        </p>
                        <p>
                            <i className={`${checkPassword.number ? "far fa-check-circle" : "far fa-times-circle"}`} />
                            <span>Possède un chiffre</span>
                        </p>

                        <p>
                            <i
                                className={`${checkPassword.character ? "far fa-check-circle" : "far fa-times-circle"}`}
                            />
                            <span>Possède un caractère spécial (&/#...)</span>
                        </p>
                        <p>
                            <i className={`${checkPassword.count ? "far fa-check-circle" : "far fa-times-circle"}`} />
                            <span>Contient au moins 8 caractère</span>
                        </p>
                        <p>
                            <i className={`${checkPassword.match ? "far fa-check-circle" : "far fa-times-circle"}`} />
                            <span>Les 2 mot de passes sont identiques</span>
                        </p>
                    </div>
                </div>
            )}
            <div style={{ paddingTop: "15px" }}>
                <InputList.InputButton onClick={() => updatePassword()} text="Valider" />
            </div>
        </div>
    );
};

export default IdentifiantConnexion;
