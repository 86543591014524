import { useState } from "react";

import "./ListNumbers.scss";

import { Table, InputList, Boutique } from "components";

import Blocked from "utils/blocked_numbers.json";

const ListNumbers = ({ updateCampaign }) => {
    const [ext, setExt] = useState("+33");
    const [num, setNum] = useState("");
    const [numbers, setNumbers] = useState([]);
    const [error, setError] = useState(false);

    const addNumber = () => {
        const err = Blocked?.blocked?.map((block) => {
            if (("+33", num.includes(block))) {
                return false;
            }
            return true;
        });

        if (err.includes(false)) {
            return setError(true);
        }

        setError(false);
        setNumbers([...numbers, ext + num]);

        updateCampaign([...numbers, ext + num]);
    };

    const changeIndicatif = (e) => {
        setExt(e.dial_phone);
    };

    const removeNumber = (index) => {
        let newNumbers = [...numbers];
        newNumbers.splice(index, 1);
        setNumbers(newNumbers);
        updateCampaign(newNumbers);
    };

    return (
        <div className="ListNumbersSMSPopup">
            <div className="ListNumbersSMSContainer">
                <h3>Entrez les numéro de vos correspondants</h3>
            </div>

            <div className="Input">
                <InputList.InputPhone ext={ext} num={num} onChange={setNum} setIndicatif={changeIndicatif} />
                <p onClick={() => addNumber(num)}>Soumettre</p>
            </div>
            {error && <p className="red">Ce numéro n'est pas supporté par la plateforme</p>}

            <Table listHead={["Numéro", "Action"]} options={{ isFixed: true }}>
                {numbers?.map((number, index) => (
                    <Boutique.EnvoyerSMS.Popup.ListNumbersTableRow
                        i={index}
                        removeNum={removeNumber}
                        data={number}
                        key={index}
                    />
                ))}
            </Table>
        </div>
    );
};

export default ListNumbers;
