import "./MP3.scss";

import React, { useState } from "react";

import { InputList } from "components";

import useMessageContext from "contexts/MessageContext";

import CheckAudioFile from "helpers/StudioVocal/CheckAudioFile";

import useUploadAudioFile from "utils/api/config/StudioVocal/mp3/UploadAudioFile";

const MP3 = ({ data, onSave }) => {
    const { showMessage } = useMessageContext();

    const [bufferData, setBuffer] = useState(null);

    const { mutateAsync: UploadFile } = useUploadAudioFile();

    const handleFile = async (e) => {
        if (CheckAudioFile(e.target.files[0])) {
            // GET BUFFER FROM FILE
            const buffer = await e.target.files[0].arrayBuffer();

            const inputEl = document.getElementById("upload-mp3");
            inputEl.value = null;
            setBuffer(buffer);

            showMessage({
                type: "success",
                text: "Votre fichier a bien été chargé!",
            });
        } else {
            showMessage({
                type: "error",
                text: "Veuillez vérifier si votre fichier est au format MP3 ou WAV.",
            });
        }
    };

    // Create a new Audio object to play and pause the audio
    const audio = new Audio();

    const playResumeAudio = () => {
        // Check if the audio is not empty
        if (!audio.src) {
            audio.src = URL.createObjectURL(new Blob([bufferData]));
            // Add an event listener to the audio object to remove the object URL once the audio is played
            audio.addEventListener("ended", () => {
                URL.revokeObjectURL(audio.src);
            });
        }

        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
    };

    const upload = async () => {
        const blob = new Blob([bufferData], { type: "audio/mpeg" });

        // CONVERT BLOB TO BASE64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result;

            if (onSave) return onSave({ base64data });

            const request = await UploadFile({
                audio_message_id: data.id,
                bufferData: base64data,
            });
            // console.log(request);

            if (!request.success) {
                return showMessage({
                    type: "error",
                    text: "Une erreur est survenue lors de l'upload du fichier audio.",
                });
            }

            showMessage({
                type: "success",
                text: "Votre fichier audio a bien été uploadé.",
            });
        };
    };

    return (
        <div className="MP3Container">
            <p>Importez votre fichier audio au format MP3 ou WAV</p>

            <i className="fad fa-upload"></i>
            <div>
                {bufferData === null ? (
                    <>
                        <label htmlFor="upload-mp3">Charger</label>
                        <input
                            id="upload-mp3"
                            name="Charger"
                            type="file"
                            accept="audio/mp3"
                            size="5000000"
                            className="absolute opacity-0"
                            onChange={(e) => {
                                // setShowPopup();
                                handleFile(e);
                            }}
                        ></input>
                    </>
                ) : (
                    <div className="flex justify-center mt-5 gap-6 flex-wrap">
                        <InputList.InputButton text="Écouter/Pause" onClick={() => playResumeAudio()} />
                        <InputList.InputButton text="Upload" onClick={() => upload()} />
                        <InputList.InputButton text="Supprimer" onClick={() => setBuffer(null) | audio.pause()} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MP3;
