/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { IbanElement } from "@stripe/react-stripe-js";
import "./ibanFormStyle.scss";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
    base: {
        margin: "5px",
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
            color: "#aab7c4",
        },
        ":-webkit-autofill": {
            color: "#32325d",
        },
    },
    invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
        ":-webkit-autofill": {
            color: "#fa755a",
        },
    },
};

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: "FR",
    style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled }) {
    const customer = JSON.parse(localStorage.getItem("customer"));
    return (
        <form onSubmit={onSubmit} className="login-container">
            <div className="login-form">
                <input name="accountholder-name" value={customer.name} required />

                <input name="email" type="email" value={customer.email} required />

                <div className="iban-container">
                    <IbanElement options={IBAN_ELEMENT_OPTIONS} />
                </div>
                <button type="submit" disabled={disabled}>
                Mandat de Prélèvement SEPA
                </button>
                <div className="description">
                Indiquez votre IBAN comme moyen de paiement de secours afin que votre standard téléphonique ne soit pas suspendu lors d'un échec de paiement par carte bancaire.
                    En inscrivant votre IBAN et en cliquant sur “Valider le prélèvement SEPA”, vous autorisez la société
                    COLOR TELECOM à envoyer des instructions pour effectuer des prélèvements sur votre compte
                    conformément aux conditions générales de ventes et d'utilisations.
                </div>
            </div>
        </form>
    );
}
