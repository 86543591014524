import { React, useState } from "react";
import { Layout, HeadPage, Boutique, Popup, InputList } from "components";

// import image from "assets/images/boutique/redirection.svg";

import useMainContext from "contexts/MainContext";

import image from "assets/images/boutique/redirection.svg";
import check from "assets/picto/validate.svg";
import error from "assets/images/boutique/error.svg";

const Redirection = () => {
    const { telephoneSwitchboard } = useMainContext();

    const [popupAlert, setPopupAlert] = useState({ display: false, data: null });
    const [popup, setPopup] = useState({ display: false, data: null });

    const close = (data) => {
        setPopup({ display: false, data: null });

        if (data === "close") {
            setPopupAlert({ display: false, data: null });
            return;
        }
        if (data && data.success)
            setPopupAlert({
                display: true,
                data: (
                    <div className="popupAlert">
                        <img src={check} alt="picto" />
                        <h2>Paiement réussi</h2>
                        <p>Votre option a bien été activée</p>
                        <div className="popup-btn">
                            <InputList.InputButton
                                text="Fermer"
                                onClick={() => {
                                    setPopupAlert({ display: false, data: null });
                                }}
                            />
                        </div>
                    </div>
                ),
            });
        else {
            setPopupAlert({
                display: true,
                data: (
                    <div className="popupAlert">
                        <img src={error} alt="picto" />
                        <h2>Paiement échoué</h2>
                        <p>{data.message}</p>
                        <div className="popup-btn">
                            <InputList.InputButton
                                text="Fermer"
                                onClick={() => {
                                    setPopupAlert({ display: false, data: null });
                                }}
                            />
                        </div>
                    </div>
                ),
            });
        }
    };
    return (
        <Layout>
            <HeadPage title="Pack 5 numéros de redirection" isProfil={false} />
            <Boutique.Option
                description="Vous pouvez recevoir actuellement vos appels sur 5 numéros différents. Grâce à ce pack, vous pouvez ajouter 5  redirections supplémentaires à votre Standard"
                img={image}
                bottomdescription={{
                    text: "L'ajout de 5 numéros de redirection coûte 5€ HT par mois dès son activation. Cette somme sera rajoutée à votre abonnement mensuel.",
                }}
                button={{ text: "Ajouter", onClick: () => setPopup({ display: true, data: null }) }}
            />

            {popupAlert?.display && <Popup.PopupClassic>{popupAlert.data}</Popup.PopupClassic>}

            {popup.display && (
                <Popup.PopupStripe
                    onClose={close}
                    option={{
                        id: 2,
                        price: 10,
                        name: "Numéro de redirection",
                    }}
                    desc={`Achat de l'option 5 numéros de redirection supplémentaires (10€ HT par mois)`}
                    standard_number={telephoneSwitchboard?.get?.standard_number?.standard_number}
                />
            )}
        </Layout>
    );
};

export default Redirection;
