import api from "utils/api/api";

const AddItem = async ({ menu_id, item }) => {
    try {
        if (menu_id === undefined) throw new Error("menu_id is undefined");
        if (item === undefined) throw new Error("item is undefined");

        const response = await api.post(`/api/v1/script/menu/${menu_id}/item`, item);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { AddItem };
