import { useState } from "react";

import { InputList, Cards } from "components";

import { ReactComponent as CreditCard } from "assets/icons/money/credit_card_outline.svg";
// import { ReactComponent as Iban } from "assets/icons/money/iban_outline.svg";

import "./InformationBancaires.scss";

import InfosCards from "./InfosCards";
import InfosIban from "./InfosIban";

const InformationsBancaires = () => {
    const [selectedMethod, setSelectedMethod] = useState(1);

    return (
        <>
            <div className="InfosBancaires">
                <div>
                    <div className="flex gap-2">
                        <Cards.PaymentMethod
                            selectionned={selectedMethod === 1}
                            method={{ icon: CreditCard, name: "Carte bancaire" }}
                            onClick={() => setSelectedMethod(1)}
                        />
                        {/* <Cards.PaymentMethod
                            onClick={() => setSelectedMethod(2)}
                            selectionned={selectedMethod === 2}
                            method={{ icon: Iban, name: "Prélèvement automatique" }}
                        /> */}
                    </div>
                    <div className="flex mt-5 mb-5">
                        <p className="mr-5 font-medium">Moyen de paiement par défaut</p>
                        <InputList.InputToggle />
                    </div>
                </div>

                {selectedMethod === 2 ? <InfosIban key={"IBAN1"} /> : <InfosCards key={"CARD1"} />}
                <InputList.InputButton className="button" text="Enregistrer" />
            </div>
        </>
    );
};

export default InformationsBancaires;
