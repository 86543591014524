import PopupMessage from "./Message";
import PopupClassic from "./Classic";
import PopupStripe from "./Stripe";

const Popup = {
    PopupMessage,
    PopupClassic,
    PopupStripe,
};

export default Popup;
