import TableRow from "./TableRow";
import Popup from "./Popup";
import CampaignListNumbers from "./CampaignListNumbers";

const EnvoyerSMS = {
    TableRow,
    Popup,
    CampaignListNumbers,
};

export default EnvoyerSMS;
