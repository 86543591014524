import api from "utils/api/api";

const PatchRedirectNumber = async ({ customer_id, data }) => {
    try {
        const response = await api.patch(`/api/v1/customer/${customer_id}/redirect-number`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { PatchRedirectNumber };
