import "./ChangeIBAN.scss";

import { InputList } from "components";

const ChangeIBAN = ({ handleAddIban }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const iban = e.target[1].value;
        const account_holder_name = e.target[0].value;

        handleAddIban(iban, account_holder_name);
    };

    return (
        <form onSubmit={handleSubmit} className="ChangeIBANContainer">
            <InputList.InputClassic className="Input" label="Nom :" placeholder="John" />
            <InputList.InputClassic className="Input" label="Iban :" placeholder="FR91 1756 9000 4067 9763 5793 B27" />
            {/* <div className="ChangeIBAN_button"> */}
            <InputList.InputButton type="submit" text="Enregistrer" />
            {/* </div> */}
        </form>
    );
};
export default ChangeIBAN;
