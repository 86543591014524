import { useEffect, useState } from "react";
import "./ListeRenvois.scss";
import { InputList } from "components";
const ListeRenvois = ({ data, handleItemChange, itemList }) => {
    const [list, setList] = useState([]);
    console.log({ itemList });
    console.log({ data });
    useEffect(() => {
        if (itemList === undefined) return;
        const tab = [];
        itemList?.map((item) => {
            return tab.push({
                id: item.redirect_number.id,
                number: item.redirect_number.number,
            });
        });
        setList(itemList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleUpdateList = (value, checked) => {
        const item = data?.find((item) => item.id === value);
        const check = list?.find((item) => item.redirect_number_id === value);
        if (check) {
            const newList = list?.filter((item) => item.redirect_number_id !== value);
            setList(newList);
            return;
        }
        setList([...list, item]);
    };
    const handleSave = () => {
        const data = {
            isPhoneRedirect: true,
            redirectNumber: list,
        };
        handleItemChange(data);
    };
    return (
        <div className="ListeRenvois">
            <div className="list">
                {data?.map((item) => {
                    return (
                        <div
                            key={`${item.id}div`}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "40px",
                                width: "150px",
                                gap: "10px",
                            }}
                        >
                            <input
                                type="checkbox"
                                key={`${item.id}checkbox`}
                                id={item.id}
                                value={item.id}
                                onChange={(e) => handleUpdateList(e.target.value, e.target.checked)}
                                defaultChecked={itemList?.find((it) => it.redirect_number_id === item.id)}
                            />
                            <label
                                style={{
                                    textAlign: "center",
                                }}
                                htmlFor={item.id}
                                key={`${item.id}_label`}
                                className="item"
                            >
                                {item.number}
                            </label>
                        </div>
                    );
                })}
            </div>
            <InputList.InputButton onClick={handleSave} text="Enregistrer ces numéros" />
        </div>
    );
};
export default ListeRenvois;