import api from "utils/api/api";

const createSetupIntent = async (payementMethod) => {
    try {
        const response = await api.get(`api/v1/payment/create-setup-intent/${payementMethod}`);
        return response.data;
    } catch (err) {
        throw err;
    }
};


const attachPaymentMethod = async (data) => {
    try {
        const response = await api.post(`api/v1/payment/attach-payment-method`,data);
        return response.data;
    } catch (err) {
        throw err;
    }
};
//{payment_method: string, payment_method_type:string}

export { createSetupIntent,attachPaymentMethod };
