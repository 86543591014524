import api from "utils/api/api";

import { useMutation } from "react-query";

const PostNewCard = async ({ token }) => {
    try {
        const response = await api.post("/api/v1/payment/add-card", {
            token,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const usePostNewCard = () => useMutation((token) => PostNewCard({ token }));

export default usePostNewCard;
export { PostNewCard };
