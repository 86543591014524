import "./Radio.scss";

const InputRadio = ({ name, checked, onChange, id }) => {
    return (
        <label className="InputRadio">
            <input
                type="radio"
                onChange={() => {
                    onChange(id);
                }}
                id={id}
                checked={checked}
            />
            {name || ""}
        </label>
    );
};

export default InputRadio;
