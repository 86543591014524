const InputClassic = ({
    label,
    id,
    name,
    placeholder,
    defaultValue,
    wCustom,
    className,
    type,
    required,
    onChange,
    readOnly,
    icon,
    iconPosition,
    border,
    isPhone,
}) => {
    return (
        <div className={`flex flex-col py-2 ${wCustom || "w-full"} ${className || ""} inputClassic`}>
            {label && (
                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={id}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    name={name || ""}
                    id={id || ""}
                    type={type || "text"}
                    defaultValue={defaultValue || ""}
                    placeholder={placeholder || ""}
                    required={required || false}
                    className={`${border !== false ? "border-2" : ""} ${
                        iconPosition === "left" ? "pl-10" : ""
                    } w-full h-9 p-2 text-sm rounded-lg`}
                    onChange={(e) => {
                        if (isPhone) {
                            e.target.value = "+" + e.target.value.replace(/[^0-9]/g, "");
                        }
                        onChange && onChange(e);
                    }}
                    readOnly={readOnly || false}
                />
                {icon && (
                    <div
                        className={`${
                            iconPosition === "left" ? "left-1" : " right-4"
                        } absolute h-[70%] top-[50%] translate-y-[-50%] cursor-pointer`}
                    >
                        {icon}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputClassic;
