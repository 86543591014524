import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./index.css";
import "./tailwind/output.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { MainProvider } from "./contexts";
import { PopupProvider } from "contexts/PopupContext";
import { MessageProvider } from "contexts/MessageContext";
import { GuideProvider } from "contexts/GuideContext";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            staleTime: 1000 * 60 * 0.5, // 30 secondes
            retry: 2,
            retryDelay: 5000, // 5 secondes
        },
    },
});

ReactDOM.render(
    // <GoogleReCaptchaProvider
    //     // config recaptcha
    //     reCaptchaKey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE}`}
    //     language="fr"
    //     scriptProps={{
    //         async: false, // optional, default to false,
    //         defer: false, // optional, default to false
    //         appendTo: "head", // optional, default to "head", can be "head" or "body",
    //         nonce: undefined, // optional, default undefined
    //     }}
    //     container={{
    //         // optional to render inside custom element
    //         element: "captcha",
    //         parameters: {
    //             badge: "bottomright", // optional, default undefined
    //             theme: "dark", // optional, default undefined
    //         },
    //     }}
    // >
    <BrowserRouter>
        <CookiesProvider>
            <MainProvider>
                <PopupProvider>
                    <MessageProvider>
                        <GuideProvider>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </GuideProvider>
                    </MessageProvider>
                </PopupProvider>
            </MainProvider>
        </CookiesProvider>
    </BrowserRouter>,
    document.getElementById("root")
    // </GoogleReCaptchaProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
