import usePopupContext from "contexts/PopupContext";
import useMessageContext from "contexts/MessageContext";

import useGetCustomerBankAccounts from "utils/api/customer/billing/sepa/GetCustomerBankAccounts";
import useAddCustomerBankAccount from "utils/api/customer/billing/sepa/AddCustomerBankAccount";

import { ReactComponent as Flag } from "assets/icons/fr.svg";

import ChangeIBAN from "../ChangeIBAN";
import axios from "axios";

const InfosIban = () => {
    const { showPopup, hidePopup } = usePopupContext();
    const { showMessage } = useMessageContext();

    const { data: sepa, refetch: sepaRefecth } = useGetCustomerBankAccounts();

    const { mutateAsync: addIban } = useAddCustomerBankAccount();

    const handleAddIban = async (iban, account_holder_name) => {
        try {
            if (!iban || !account_holder_name) {
                showMessage({
                    text: "Veuillez remplir tous les champs",
                    type: "error",
                });
                return;
            }

            const checkIban = await axios.get("https://openiban.com/validate/" + iban);

            if (!checkIban?.data?.valid) {
                showMessage({
                    text: "Votre IBAN n'est pas valide",
                    type: "error",
                });
                return;
            }

            const add = await addIban({
                iban,
                account_holder_name,
            });

            if (!add?.success) {
                showMessage({
                    text: "Erreur lors de l'ajout de votre IBAN",
                    type: "error",
                });
                return;
            }

            hidePopup();

            showMessage({
                text: "Votre IBAN a bien été ajouté",
                type: "success",
            });

            await sepaRefecth();
        } catch (error) {
            showMessage({
                text: "Erreur lors de l'ajout de votre IBAN",
                type: "error",
            });
        }
    };

    return (
        <div className="iban">
            {/* <div className="main-iban">
                <h3>IBAN principal</h3>
                <p>
                    <Flag className="Icon" />
                    FR76 3000 3000 3000 3000 3000 300
                </p>
            </div> */}

            <div className="iban-list">
                <p className="title">Vos IBAN</p>
                <div className="Overflow flex">
                    {/* <div className="iban-card">
                        <p className="title">IBAN principal</p>
                        <p className="desc">
                            <Flag className="Icon" />
                            FR76 3000 3000 3000 3000 3000 300
                        </p>
                    </div> */}

                    {sepa?.data?.customer_bank_accounts?.map((iban, index) => (
                        <div key={index} className="iban-card">
                            <p className="title">IBAN</p>
                            <p className="desc">
                                <Flag className="Icon" />
                                {iban.country_code} **** **** **** **** **** {iban.account_number_ending}
                            </p>
                        </div>
                    ))}

                    <div
                        onClick={() => showPopup(<ChangeIBAN handleAddIban={handleAddIban} />)}
                        className="iban-card iban-add"
                    >
                        <p className="title">IBAN secondaire</p>
                        <i className="fas fa-plus" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfosIban;
