import api from "../api";

// file = { name: "file_name", data: "buffer base64" }
const UploadFile = async (file) => {
    try {
        const response = await api.post(`/api/v1/zendesk/upload-file`, file);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { UploadFile };
