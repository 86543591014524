import HorairesComponent from "./HorairesOuverture";
import MenuMultiChoix from "./MenuMultiChoix";
import StudioVocal from "./StudioVocal";
import NumeroRenvois from "./NumeroRenvois";
import Repondeur from "./Repondeur";
import Reglages from "./Reglages";

const Config = {
    HorairesOuverture: HorairesComponent,
    MenuMultiChoix,
    NumeroRenvois,
    Reglages,
    Repondeur,
    StudioVocal,
};

export default Config;
