import { useEffect, useState } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
// import { useIdleTimer } from "react-idle-timer";
import { useCookies } from "react-cookie";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./App.scss";

import "react-loading-skeleton/dist/skeleton.css";

import {
    Home,
    JournalAppel,
    Login,
    Config,
    Consommation,
    Compte,
    ResetPassword,
    ForgotPassword,
    Boutique,
    Impayes,
    Onboarding,
    Release,
    PaymentSuccess,
    PaymentCancel,
    Support,
    Ticket,
} from "./pages";

import useMainContext from "contexts/MainContext";
import usePopupContext from "contexts/PopupContext";
import useMessageContext from "contexts/MessageContext";

import { Popup } from "components";

import { GetCustomerInfo } from "utils/api/customer/index/GetCustomerInfo";
/*
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "backoffice-app@" + process.env.REACT_APP_VERSION,
    // debug: process.env.REACT_APP_NODE_ENV === "dev",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});*/

const stripeKey =
    process.env.REACT_APP_NODE_ENV === "prod" ? process.env.REACT_APP_STRIPE_PROD_KEY : process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(stripeKey);

const App = () => {
    // eslint-disable-next-line no-unused-vars
    const [cookies] = useCookies();

    const { customer, token, telephoneSwitchboard } = useMainContext();

    const [isUnpaid, setIsUnpaid] = useState(false);

    const { message, hideMessage } = useMessageContext();
    const { popup, hidePopup } = usePopupContext();

    const navigate = useNavigate();

    // const onIdle = () => {
    //     if (token?.get) {
    //         removeCookie(process.env.REACT_APP_COOKIE_NAME);
    //     }
    // };

    // useIdleTimer({
    //     onIdle,
    //     timeout: 1000 * 60 * 20,
    // });

    useEffect(() => {
        if (cookies[process.env.REACT_APP_COOKIE_NAME]) {
            fetchCustomer();
            token.set(cookies[process.env.REACT_APP_COOKIE_NAME]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies]);

    const fetchCustomer = async () => {
        const response = await GetCustomerInfo();

        if (!response.success) {
            // Faire la gestion d'erreur
            return;
        }

        const data = response.data;

        telephoneSwitchboard.set(data.telephoneSwitchboard[0]);
        customer.set(data);

        // filter standard_number isUnpaid from list of telephoneSwitchboard
        const isUnpaid = data.telephoneSwitchboard.filter((item) => item?.standard_number?.isUnpaid === true);

        if (isUnpaid.length > 0) {
            setIsUnpaid(true);
        }

        if (!data.isOnboarded) {
            navigate("/onboarding");
        }
    };

    return (
        <>
            <Elements stripe={stripePromise}>
                <Routes>
                    {cookies[process.env.REACT_APP_COOKIE_NAME] !== undefined ? (
                        <>
                            {isUnpaid !== true ? (
                                <Route element={<Home />} path="/">
                                    <Route path="/support/tickets" element={<Support />} />
                                    <Route path="/support/ticket/:id" element={<Ticket />} />
                                    <Route path="/release" element={<Release />} />
                                    <Route path="/" element={<Consommation />} />
                                    <Route path="/journal-appel" element={<JournalAppel />} />
                                    <Route path="/config" element={<Outlet />}>
                                        <Route path="/config/message-accueil" element={<Config.MessageAccueil />} />
                                        <Route path="/config/message-fermeture" element={<Config.MessageFermeture />} />
                                        <Route path="/config/musique" element={<Config.Musique />} />
                                        <Route path="/config/numero-renvois" element={<Config.NumeroRenvois />} />
                                        <Route path="/config/menu-multichoix" element={<Config.MenuMultichoix />} />
                                        {/* <Route path="/config/repondeur" element={<Config.Repondeur />} /> */}
                                        <Route path="/config/reglages" element={<Config.Reglages />} />
                                        <Route
                                            path="/config/horaires-ouverture"
                                            element={<Config.HorairesOuverture />}
                                        />
                                    </Route>
                                    <Route path="/compte" element={<Outlet />}>
                                        <Route path="/compte/tarifs" element={<Compte.Tarifs />} />
                                        <Route
                                            path="/compte/recharge-automatique"
                                            element={<Compte.RechargeAutomatique />}
                                        />
                                        <Route path="/compte/coordonnees" element={<Compte.Coordonnees />} />
                                        <Route
                                            path="/compte/identifiant-connexion"
                                            element={<Compte.IdentifiantConnexion />}
                                        />
                                        <Route
                                            path="/compte/information-bancaire"
                                            element={<Compte.InformationBancaires />}
                                        />
                                        <Route
                                            path="/compte/information-bancaire/setup-stripe-sepa"
                                            element={<Compte.SetupStripeSepa />}
                                        />
                                        <Route path="/compte/factures" element={<Compte.Factures />} />
                                    </Route>
                                    <Route path="/boutique" element={<Outlet />}>
                                        <Route path="/boutique/ajouter-credits" element={<Boutique.AjoutCredits />} />
                                        <Route path="/boutique/ajouter-profil" element={<Boutique.AjoutProfil />} />
                                        <Route path="/boutique/numero-redirection" element={<Boutique.Redirection />} />
                                        <Route
                                            path="/boutique/choix-supplementaire"
                                            element={<Boutique.AjoutChoix />}
                                        />
                                        <Route
                                            path="/boutique/enregistrement-appel"
                                            element={<Boutique.EnregistrementAppel />}
                                        />
                                        <Route
                                            path="/boutique/pack-telesecretariat"
                                            element={<Boutique.PackTelesecretariat />}
                                        />
                                        <Route path="/boutique/envoyer-sms" element={<Boutique.EnvoyerSms />} />
                                        <Route
                                            path="/boutique/portabilite-entrante"
                                            element={<Boutique.PortabiliteEntrante />}
                                        />
                                        <Route
                                            path="/boutique/portabilite-sortante"
                                            element={<Boutique.PortabiliteSortante />}
                                        />
                                    </Route>
                                    <Route path="/onboarding" element={<Onboarding />} />
                                </Route>
                            ) : (
                                <>
                                    <Route path="/payment/success" element={<PaymentSuccess />} />
                                    <Route path="/payment/cancel" element={<PaymentCancel />} />
                                    <Route path="*" element={<Impayes />} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Route element={<Login />} path="/login" />
                            <Route element={<ResetPassword />} path="/reset-password/:code" />
                            <Route element={<ForgotPassword />} path="/forgot-password/" />
                            <Route element={<Login />} path="*" />
                        </>
                    )}
                </Routes>
                {message.isShow && (
                    <Popup.PopupMessage
                        message={message.text}
                        onFinish={hideMessage}
                        type={message.type}
                        updatedAt={message.updatedAt}
                    />
                )}
                {popup.isShow && <Popup.PopupClassic onClose={() => hidePopup()}>{popup.content}</Popup.PopupClassic>}
            </Elements>
        </>
    );
};

export default App;
