import React from "react";
import Skeleton from "react-loading-skeleton";

import useMainContext from "contexts/MainContext";

const Line = ({ title, children, description }) => {
    const { telephoneSwitchboard } = useMainContext();

    return (
        <div className="line">
            {telephoneSwitchboard.get ? (
                <>
                    <span>{title}</span>
                    {children}
                    <p>{description}</p>
                </>
            ) : (
                <Skeleton variant="rectangular" width="500%" height={200} />
            )}
        </div>
    );
};

export default Line;
