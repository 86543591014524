import React from "react";
import "./Validate.scss";

import svg from "assets/picto/validate.svg";

const Validate = ({ validateText, buttonText, setPopup }) => {
    // const [popupFailed, setPopupFailed] = useState();
    // const [popup, setPopup] = useState(false);

    return (
        <div className="ValidateContainer">
            <h3>{validateText}</h3>
            <img src={svg} alt="validate" />

            <div className="button-bottom-popup">
                <div>
                    <button onClick={setPopup}>{buttonText}</button>
                    {/* {popup ? (
                        <Boutique.EnvoyerSMS.Popup.PopupLayout input={false} button={false} onClose={false}>
                            {popupFailed}
                        </Boutique.EnvoyerSMS.Popup.PopupLayout>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
};
export default Validate;
