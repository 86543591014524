import api from "utils/api/api";

const UpdateMenuWithItems = async ({ menu_id, items }) => {
    try {
        const request = await api.post(`api/v1/script/menu/${menu_id}/items`, {
            items,
        });

        return request.data;
    } catch (err) {
        throw err;
    }
};

export { UpdateMenuWithItems };
