import { useEffect, useState } from "react";

import { useListMusic } from "utils/api/config/StudioVocal/ListMusic";

import { InputList } from "components";

import "./FondMusical.scss";

const FondMusical = ({ onChange, defaultValue }) => {
    const [selectedId, setSelectedId] = useState(null);

    const getAllCategoryFromMusic = (musics) => {
        const categoryList = [{ id: 0, text: "Tous" }];

        musics?.forEach((x) => {
            if (!categoryList.find((item) => item.text === x.category)) {
                categoryList.push({ id: categoryList.length, text: x.category });
            }
        });

        return categoryList;
    };

    const [themeList, setThemeList] = useState(getAllCategoryFromMusic([]));

    const { data } = useListMusic();

    const [musicList, setMusicList] = useState([]);

    useEffect(() => {
        if (data) {
            setMusicList(data?.data);
            setThemeList(getAllCategoryFromMusic(data?.data));
        }
    }, [data]);

    const handleSelectTheme = (e) => {
        if (e.text === "Tous") {
            setMusicList(data?.data);
            return;
        }
        setMusicList(data?.data.filter((x) => x.category === e.text));
    };

    // Create a new Audio object to play and pause the audio with useState
    const [audio, setAudio] = useState(new Audio());
    const [audioInfo, setAudioInfo] = useState({ url: "", paused: true });

    const playResumeAudio = async (url) => {
        if (audio.src !== url) {
            if (!audio.src) {
                audio.pause();

                audio.removeEventListener("ended", () => {
                    setAudio(new Audio());
                });
            }

            audio.src = url;

            // Add an event listener to the audio object to remove the object URL once the audio is played
            audio.addEventListener("ended", () => {
                setAudio(new Audio());
                setAudioInfo({ url: "", paused: false });
            });

            audio.play();
            setAudioInfo({ url, paused: false });
            return;
        }

        if (audio.paused) {
            audio.play();
            setAudioInfo({ url, paused: false });
        } else {
            audio.pause();
            setAudioInfo({ url, paused: true });
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", audio.pause());

        return () => {
            window.removeEventListener("beforeunload", audio.pause());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectMusic = (music) => {
        setSelectedId(music.id);
        onChange({
            isUpload: false,
            audio_path: music.audio_path,
        });
    };

    return (
        <div className="FondMusical">
            {selectedId === null && defaultValue && (
                <div className={`container`}>
                    <div className="music">
                        <InputList.InputRadio
                            // onChange={() => handleSelectMusic(x)}
                            // id={null}
                            checked={selectedId === null}
                            name={"Musique actuelle"}
                        />
                    </div>
                    <div className="icon" onClick={() => playResumeAudio(defaultValue)}>
                        <i
                            className={`${
                                audioInfo.url === defaultValue
                                    ? audioInfo.paused
                                        ? "fas fa-play"
                                        : "fas fa-pause"
                                    : "far fa-volume"
                            }`}
                        />
                    </div>
                </div>
            )}

            <InputList.InputDropdown onChange={handleSelectTheme} defaultValue={"Tous"} list={themeList} />

            {musicList
                ?.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                })
                ?.map((x) => {
                    return (
                        <div key={x.id} className={`container`}>
                            <div className="music">
                                <InputList.InputRadio
                                    onChange={() => handleSelectMusic(x)}
                                    id={x.id}
                                    checked={selectedId === x.id}
                                    name={x.name}
                                />
                            </div>
                            <div className="icon" onClick={() => playResumeAudio(x?.audio_path)}>
                                <i
                                    className={`${
                                        audioInfo.url === x?.audio_path
                                            ? audioInfo.paused
                                                ? "fas fa-play"
                                                : "fas fa-pause"
                                            : "far fa-volume"
                                    }`}
                                />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default FondMusical;
