import React from "react";
import { Layout, HeadPage, Compte } from "components";

const IdentifiantConnexion = () => {
    return (
        <Layout>
            <HeadPage isProfil={false} title="Modifier mon mot de passe" />
            <Compte.IdentifiantConnexion />
        </Layout>
    );
};

export default IdentifiantConnexion;
