import { useEffect, useRef } from "react";
import "./PopupMessage.scss";

const PopupMessage = ({ message, onFinish, type, updatedAt }) => {
    const messageRef = useRef(null);

    // type= success, error, info, warning

    // get border-right-color with type
    const borderRight = () => {
        switch (type) {
            case "success":
                return "#32CD32";
            case "error":
                return "#f74a53";
            case "info":
                return "#007bff";
            case "warning":
                return "#ffc107";
            default:
                return "#32CD32";
        }
    };

    useEffect(() => {
        const element = messageRef.current;

        if (message) {
            if (!element) return;

            element?.classList.remove("MessageAnimated");
            element?.classList.remove("MessageShow");

            setTimeout(() => {
                element?.classList.add("MessageAnimated");
                element?.classList.add("MessageShow");
            }, 10);

            const timer = setTimeout(() => {
                element?.classList.remove("MessageShow");
                setTimeout(onFinish, 300);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedAt]);

    return (
        <div
            ref={messageRef}
            id="PopupMessage"
            style={{
                alignItems: "center",
                borderRight: `15px solid ${borderRight()}`,
            }}
        >
            <p>
                {type === "success" && <i className="fad fa-check-circle" style={{ color: "green" }} />}
                {type === "error" && <i className="fad fa-times-circle" style={{ color: "red" }} />}
                {type === "info" && <i className="fad fa-info-circle" style={{ color: "blue" }} />}
                {type === "warning" && <i className="fad fa-exclamation-circle" style={{ color: "orange" }} />}

                {message}
            </p>

            <div className="messageBar">
                <div id="loading-bar"></div>
            </div>
        </div>
    );
};

export default PopupMessage;
