import { useEffect, useRef, useState } from "react";

import { InputList } from "components";

import useMainContext from "contexts/MainContext";

import "./JoursOuverture.scss";

const JoursOuverture = ({ data, slide, profile_id, func, canDelete }) => {
    const { telephoneSwitchboard } = useMainContext();

    const [isActiveStart, setIsActiveStart] = useState(false);
    const [isActiveEnd, setIsActiveEnd] = useState(false);

    const mouseMoveRef = useRef();
    const mouseUpRef = useRef();

    // Define function to set isActive state variable to false
    const handleMouseUp = () => {
        setIsActiveStart(false);
        setIsActiveEnd(false);
    };

    // Create function to schedule handleDrag to be executed at the next frame
    const scheduleHandleDrag = (e, id, slotId, key) => {
        requestAnimationFrame(() => func.handleDrag(e, id, slotId, key));
    };

    // Add event listener for "selectstart" event
    document.addEventListener("selectstart", (event) => {
        if (isActiveStart || isActiveEnd) {
            event.preventDefault();
        }
    });

    window.addEventListener("mouseup", () => {
        handleMouseUp();
        window.removeEventListener("mousemove", mouseMoveRef.current);
        window.removeEventListener("mouseup", mouseUpRef.current);
    });

    useEffect(() => {
        if (isActiveStart !== false) {
            mouseMoveRef.current = (e) => {
                scheduleHandleDrag(e, isActiveStart, data.id, "start");
            };
            mouseUpRef.current = handleMouseUp;
            window.addEventListener("mousemove", mouseMoveRef.current);
            window.addEventListener("mouseup", mouseUpRef.current);
        } else {
            window.removeEventListener("mousemove", mouseMoveRef.current);
            window.removeEventListener("mouseup", mouseUpRef.current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveStart]);

    useEffect(() => {
        if (isActiveEnd !== false) {
            mouseMoveRef.current = (e) => {
                scheduleHandleDrag(e, isActiveEnd, data.id, "end");
            };
            mouseUpRef.current = handleMouseUp;
            window.addEventListener("mousemove", mouseMoveRef.current);
            window.addEventListener("mouseup", mouseUpRef.current);
        } else {
            window.removeEventListener("mousemove", mouseMoveRef.current);
            window.removeEventListener("mouseup", mouseUpRef.current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveEnd]);

    return (
        <div className="JoursOuvertureContainer">
            {canDelete && <i className="fas fa-times JoursClose" onClick={() => func.handleRemoveSlot(data.id)}></i>}
            <div className="JoursContainer">
                <h2>
                    <strong>Jours d'ouverture</strong>
                </h2>
                <div className="Jours">
                    {data?.days?.map((item) => (
                        <div
                            key={item.id}
                            className={`circle ${item.isActive ? "circle-active" : ""}`}
                            onClick={() => func.handleUpdateDay(data.id, item.id)}
                        >
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
                <div className="Reset" onClick={() => func.handleResetSlot(data.id)}>
                    <h3>Réinitialiser</h3>
                </div>
            </div>
            <div className="Horaires">
                <h2>
                    <strong>Horaires</strong>
                </h2>
                <p>Double cliquez sur la timeline pour ajouter une plage horaire</p>
                <div
                    id="TimelineBar"
                    data-slotid={data.id}
                    className="TimelineContainer"
                    onDoubleClick={(e) => func.handleDoubleClick(e)}
                >
                    {data?.hours.map((item, index) => (
                        <div
                            key={item.id}
                            data-slotid={data.id}
                            onDoubleClick={(e) => func.handleDoubleClick(e, data.id, item.id)}
                            style={{
                                left: `${(item.valStart * 100) / slide.max}%`,
                                width: `${((item.valEnd - item.valStart) * 100) / slide.max}%`,
                            }}
                            className="Timeline"
                        >
                            <div
                                name="start"
                                className="Wrist"
                                onMouseDown={() => setIsActiveStart(item.id)}
                                onMouseUp={() => setIsActiveStart(false)}
                                onTouchMove={(e) => func.handleDrag(e, item.id, data.id, "start")}
                            >
                                <p className="WristTime">{func.convertToHours(item.valStart)}</p>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div
                                name="end"
                                className="Wrist"
                                onMouseDown={() => setIsActiveEnd(item.id)}
                                onTouchMove={(e) => func.handleDrag(e, item.id, data.id, "end")}
                            >
                                <p>{func.convertToHours(item.valEnd)}</p>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                        </div>
                    ))}
                </div>

                <h2>
                    <strong>Profil</strong>
                </h2>
                <InputList.InputDropdown
                    defaultValue={
                        "Profil " + telephoneSwitchboard.get?.Profile?.find((x) => x.id === profile_id)?.profile_number
                    }
                    list={telephoneSwitchboard.get?.Profile?.map((item) => ({
                        id: item.id,
                        text: "Profil " + item?.profile_number,
                    }))}
                    onChange={(e) => func.handleChangeProfile(data.id, e.id)}
                />
            </div>
        </div>
    );
};
export default JoursOuverture;
