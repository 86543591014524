import api from "utils/api/api";

import { useQuery } from "react-query";

const GetCustomerBankAccounts = async () => {
    try {
        const response = await api.get("/api/v1/sepa/bank-accounts");

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetCustomerBankAccounts = () => {
    return useQuery("customerBankAccounts", GetCustomerBankAccounts, {
        staleTime: 0,
    });
};

export default useGetCustomerBankAccounts;
export { GetCustomerBankAccounts };
