import "./CampaignListNumbers.scss";

const CampaignListNumbers = ({ numbers }) => {
    return (
        <div className="CampaignListNumbers">
            <h3>Liste:</h3>
            <ul>
                {numbers.map((num, id) => (
                    <li key={id}>
                        <p>{num}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CampaignListNumbers;
