import api from "utils/api/api";

import { useMutation } from "react-query";

const UpdateAutoRecharge = async ({ customer_id, credit_package_id }) => {
    try {
        if (customer_id === undefined || credit_package_id === undefined) {
            throw new Error("customer_id and credit_package_id is required");
        }

        const response = await api.patch(
            "/api/v1/customer/" + customer_id + "/billing/auto-recharge/" + credit_package_id
        );

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useUpdateAutoRecharge = () =>
    useMutation((customer_id, credit_package_id) => UpdateAutoRecharge(customer_id, credit_package_id));

export default useUpdateAutoRecharge;
export { UpdateAutoRecharge };
