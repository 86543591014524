import api from "utils/api/api";

const GetRedirectNumbers = async ({ customer_id }) => {
    try {
        if (!customer_id) throw new Error("customer_id is required");

        const response = await api.get(`/api/v1/customer/${customer_id}/redirect-number`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { GetRedirectNumbers };
