import { useState } from "react";

import "./CreditLayout.scss";

import { Popup, InputList } from "components";

import useMainContext from "contexts/MainContext";

import CreditsCard from "./CreditsCard";

import useGetAllCreditPackage from "utils/api/boutique/credit/GetAllCreditPackage";
import useGetCustomerInfo from "utils/api/customer/index/GetCustomerInfo";

import check from "assets/picto/validate.svg";
import error from "assets/images/boutique/error.svg";

const CreditLayout = () => {
    const { telephoneSwitchboard, customer } = useMainContext();

    const [popup, setPopup] = useState({ display: false, data: null });
    const [popupAlert, setPopupAlert] = useState({ display: false, data: null });

    const { data: response, isLoading } = useGetAllCreditPackage();
    const { refetch: GetCustomerInfo } = useGetCustomerInfo();

    const close = (data) => {
        setPopup({ display: false, data: null });
        if (data.success) {
            setPopupAlert({
                display: true,
                data: (
                    <div className="popupAlert">
                        <img src={check} alt="picto" />
                        <h2>Paiement réussi</h2>
                        <p>
                            {data.data.amount}€ de crédits sont ajoutés à votre compte pour un montant de{" "}
                            {Number(Number(data.data.amount) * 1.2).toFixed(2)}
                            €.
                        </p>
                        <div className="popup-btn">
                            <InputList.InputButton
                                text="Fermer"
                                onClick={() => {
                                    setPopupAlert({ display: false, data: null });
                                }}
                            />
                        </div>
                    </div>
                ),
            });
            refreshCredit();
        } else {
            setPopupAlert({
                display: true,
                data: (
                    <div className="popupAlert">
                        <img src={error} alt="picto" />
                        <h2>Paiement échoué</h2>
                        <p>{data.message}</p>
                        <div className="popup-btn">
                            <InputList.InputButton
                                text="Fermer"
                                onClick={() => {
                                    setPopupAlert({ display: false, data: null });
                                }}
                            />
                        </div>
                    </div>
                ),
            });
        }
    };

    const refreshCredit = async () => {
        const newCustomer = await GetCustomerInfo();
        customer.set({ ...customer.get, credit_balance: newCustomer?.data?.data?.credit_balance });
    };

    return (
        <div className="AddCreditsContainer">
            <div className="AddCreditsCardList">
                {!isLoading &&
                    response?.data
                        ?.sort((a, b) => (parseFloat(a.price) < parseFloat(b.price) ? -1 : 1))
                        ?.map((x, i) => {
                            return (
                                <CreditsCard
                                    key={x.id}
                                    data={x}
                                    index={i}
                                    loadPopup={() => setPopup({ display: true, data: x })}
                                />
                            );
                        })}
            </div>

            {popup?.display && (
                <Popup.PopupStripe
                    onClose={close}
                    credit_package={popup?.data}
                    desc={`Pack crédit ${popup?.data?.price}€`}
                    standard_number={telephoneSwitchboard.get.standard_number.standard_number}
                />
            )}

            {popupAlert?.display && <Popup.PopupClassic>{popupAlert.data}</Popup.PopupClassic>}
        </div>
    );
};

export default CreditLayout;
