import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import useMainContext from "contexts/MainContext";

import "./Header.scss";
import { ReactComponent as Clock } from "./images/clock.svg";
import { ReactComponent as Coins } from "./images/coins.svg";
import { ReactComponent as Phone } from "./images/phone.svg";
import { ReactComponent as Logo } from "./images/logo.svg";

import ProgressProvider from "hooks/useProgressProvider";

import useGetConsumptionForStandardNumber from "utils/api/consumption/GetConsumptionForStandardNumber";

const Header = () => {
    const { customer, telephoneSwitchboard } = useMainContext();

    const [forfaitPercent, setForfaitPercent] = useState(0);
    const [forfaitMinutes, setForfaitMinutes] = useState(0);

    const { data: conso } = useGetConsumptionForStandardNumber({
        standard_number_id: telephoneSwitchboard.get?.standard_number_id,
    });

    useEffect(() => {
        if (conso?.data && telephoneSwitchboard.get) {
            const time = conso?.data?.time.split("T")[1].split(".")[0];

            const hour = time.split(":")[0];
            const minutes = time.split(":")[1];
            const seconds = time.split(":")[2];

            const totalSeconds = parseInt(hour) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);

            const forfait = telephoneSwitchboard.get?.telephone_package?.hour_number;

            const percent = ((totalSeconds / (forfait * 3600)) * 100).toFixed(2);

            setForfaitPercent(percent);

            const timeString = `${hour}h${minutes}`;

            setForfaitMinutes(timeString);
        }
    }, [conso, telephoneSwitchboard.get]);

    return (
        <header id="Header">
            <div id="LogoContainer">
                <Logo />
                {/* <img src={logoWhite} alt="Standard en ligne" /> */}
            </div>
            <div id="CustomerInfos">
                <div className="CustomerNumber">
                    <p>
                        <strong>
                            {customer.get?.customer_address?.company_name
                                ? customer.get.customer_address.company_name.charAt(0).toUpperCase() +
                                  customer.get.customer_address.company_name.slice(1)
                                : "XX"}
                        </strong>
                    </p>
                    <div>
                        <Phone />
                        <span>
                            {
                                // display a dropdown if the customer has more than one standard number
                                customer.get?.telephoneSwitchboard?.length > 1 ? (
                                    <select
                                        name="standardNumber"
                                        id="standardNumber"
                                        onChange={(e) => {
                                            telephoneSwitchboard.change(
                                                customer.get.telephoneSwitchboard.find(
                                                    (item) => String(item.standard_number_id) === String(e.target.value)
                                                )?.id
                                            );
                                        }}
                                        defaultChecked={telephoneSwitchboard.get?.standard_number_id}
                                    >
                                        {customer.get.telephoneSwitchboard.map((item) => (
                                            <option key={item.standard_number_id} value={item.standard_number_id}>
                                                {item.standard_number.standard_number.replace(
                                                    /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
                                                    "$1 $2 $3 $4 $5 $6"
                                                )}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <strong>
                                        {telephoneSwitchboard?.get?.standard_number?.standard_number
                                            ? "+" +
                                              telephoneSwitchboard?.get?.standard_number?.standard_number.replace(
                                                  /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
                                                  "$1 $2 $3 $4 $5 $6"
                                              )
                                            : "+33 X XX XX XX XX"}
                                    </strong>
                                )
                            }
                        </span>
                    </div>
                </div>
                <div className="CustomerForfait">
                    <p>
                        <strong>{telephoneSwitchboard?.get?.telephone_package?.name || "Forfait xh/mois"}</strong>
                    </p>
                    <div className="CustomerForfaitSVG">
                        <Clock />
                        <div id="ForfaitBar">
                            <ProgressProvider valueStart={0} valueEnd={forfaitPercent}>
                                {(value) => <div className="progress" style={{ width: `${value}%` }}></div>}
                            </ProgressProvider>
                        </div>
                        <span>
                            <strong style={{ paddingLeft: "10px" }}>{forfaitMinutes}</strong>
                        </span>
                    </div>
                </div>
                <div className="CustomerCredits">
                    <span>
                        <strong>CRÉDITS</strong>
                    </span>
                    <div className="CustomerCreditsSVG">
                        <Coins />
                        <span>
                            <strong>{customer.get?.credit_balance?.toFixed(2)}€</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div id="AddCredits">
                <button
                    id="StandardButton"
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white"
                >
                    <Link to="/boutique/ajouter-credits">
                        <strong>Ajouter des crédits</strong>
                    </Link>
                </button>
            </div>
        </header>
    );
};

export default Header;
