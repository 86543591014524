// import { useState, useEffect } from "react";

import { Config, Layout, HeadPage } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import { UpdateMusic } from "utils/api/config/StudioVocal/music/UpdateMusic";
import { DisableEnableScriptKey } from "utils/api/config/reglages/DisableEnableScriptKey";

const Musique = () => {
    const { telephoneSwitchboard } = useMainContext();

    const { showMessage } = useMessageContext();

    // const [message, setMessage] = useState({});

    // useEffect(() => {
    //     if (telephoneSwitchboard.get?.script) {
    //         setMessage(
    //             telephoneSwitchboard.get?.script?.Audio_Message?.find((item) => item.message_type === "Musique")
    //         );
    //     }
    // }, [telephoneSwitchboard.get]);

    const handleChangeMusic = async (data) => {
        showMessage({
            type: "info",
            text: "Modification en cours...",
        });

        const response = await UpdateMusic({
            script_id: telephoneSwitchboard.get?.script?.id,
            data: data,
        });

        if (!response.success) {
            showMessage({
                type: "error",
                text: "La modification n'a pas été prise en compte.",
            });

            return;
        }

        showMessage({
            type: "success",
            text: "La modification a été prise en compte.",
        });
    };

    const handleChangeToogle = async (data) => {
        showMessage({
            type: "info",
            text: "Modification en cours...",
        });

        const req = await DisableEnableScriptKey({
            script_id: telephoneSwitchboard.get?.script?.id,
            key: "isMusic",
            value: data,
        });

        if (!req.success) {
            showMessage({
                type: "error",
                text: "La modification n'a pas été prise en compte.",
            });

            return;
        }

        showMessage({
            type: "success",
            text: "La modification a été prise en compte.",
        });
    };

    const listAction = [
        {
            id: 1,
            text: "Bibliothèque",
            component: (
                <Config.StudioVocal.FondMusical
                    key={"s1" + telephoneSwitchboard?.get?.id}
                    onChange={handleChangeMusic}
                    defaultValue={telephoneSwitchboard?.get?.script?.MusicHold[0]?.audio_path}
                />
            ),
        },
        // {
        //     id: 2,
        //     text: "Importer un fichier son",
        //     component: <Config.StudioVocal.MP3 key={"s2"} data={{ ...message }} />,
        // },
    ];

    return (
        <Layout>
            <HeadPage isProfil={false} title="Studio Vocal" />
            <Config.StudioVocal.StudioVocalLayout
                key={telephoneSwitchboard?.get?.id + "" + listAction?.length}
                id="studio-vocal-musique"
                title="Musique d'attente"
                list={listAction}
                handleChangeToogle={handleChangeToogle}
                defaultToogle={telephoneSwitchboard.get?.script?.isMusic}
            />
        </Layout>
    );
};

export default Musique;
