import React from "react";
import { Layout, HeadPage, Boutique } from "components";
import image from "../../../assets/images/boutique/portabilite-entrante.svg";

const PortabiliteEntrante = () => {
    return (
        <Layout>
            <HeadPage title="Portabilité entrante" isProfil={false} />
            <Boutique.Option
                description="Conservez votre numéro historique grâce à la portabilité et configurez votre Standard Téléphonique virtuel sans changer de numéro."
                img={image}
                bottomdescription={{
                    text: "L’acte de portabilité coûte 30€ HT en une seule fois. Cette somme sera rajoutée à votre prochaine facture.",
                }}
                button={{
                    text: "Ajouter",
                    onClick: () => {},
                }}
            />
        </Layout>
    );
};

export default PortabiliteEntrante;
