import { useNavigate } from "react-router-dom";

import "./PaymentCancel.scss";

import logo from "assets/images/logo/sel.svg";
import deconnect from "assets/icons/deconnect.svg";
import fail from "assets/picto/invalid.svg";

const PaymentCancel = () => {
    const navigate = useNavigate();

    return (
        <div className="pagesuccess">
            <div className="navbar">
                <img src={logo} alt="logo" />
                <img src={deconnect} alt="bouton de deconnexion du backoffice" />
            </div>
            <div className="content">
                <div className="content-data">
                    <img src={fail} alt="icone croix" />
                    <p className="number">Paiement échoué</p>
                    <div className="infos">
                        <div className="info">
                            <p className="name">Montant TTC :</p>
                            <p className="price">23,88 €</p>
                        </div>
                        <button onClick={() => navigate("/")}>Retour vers la page de paiement</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentCancel;
