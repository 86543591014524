import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoginHeader, InputList } from "components";
import "./ResetPassword.scss";

const ResetPassword = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [password1, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [checkPassword, setCheckPassword] = useState({
        upperCase: false,
        lowerCase: false,
        number: false,
        character: false,
        count: false,
        match: false,
        isActive: false,
    });

    const validatePassword = (pwd1, pwd2) => {
        // console.log(pwd1, pwd2);

        // has uppercase letter
        const checkUpper = /[A-Z]/.test(pwd1);

        // has lowercase letter
        const checkLower = /[a-z]/.test(pwd1);

        // has number
        const checkNumber = /[0-9]/.test(pwd1);

        // has special character
        // eslint-disable-next-line no-useless-escape
        const checkCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(pwd1);

        // has 8 characters
        const checkLength = pwd1.length > 8;

        // passwords match
        const checkMatch = pwd1 !== "" && pwd1 === pwd2;

        setCheckPassword({
            upperCase: checkUpper,
            lowerCase: checkLower,
            number: checkNumber,
            character: checkCharacter,
            count: checkLength,
            match: checkMatch,
            isActive: true,
        });

        // check all parameters
        // console.log(checkUpper, checkLower, checkNumber, checkCharacter, checkLength, checkMatch);

        if (checkUpper && checkLower && checkNumber && checkCharacter && checkLength && checkMatch) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        (async () => {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/code/${code}`);

            if (!data.ok) navigate("/");
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updatePassword = async () => {
        const password = password1;
        const passwordConfirm = password2;

        const check = validatePassword(password, passwordConfirm);

        if (!check) return;

        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/code/${code}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                password,
            }),
        });

        if (data.ok) {
            return navigate("/");
        }
    };

    return (
        <div>
            <LoginHeader />
            <div className="content">
                <h1>Réinitialiser votre mot de passe</h1>
                <div className="form">
                    <div className="input flex flex-col">
                        <InputList.InputClassic
                            type="password"
                            placeholder="Nouveau mot de passe"
                            name="password"
                            className=""
                            onChange={(e) => {
                                validatePassword(e.target.value, password2);
                                setPassword(e.target.value);
                            }}
                        />
                        <InputList.InputClassic
                            type="password"
                            placeholder="Confirmez votre nouveau mot de passe"
                            name="password"
                            onChange={(e) => {
                                validatePassword(e.target.value, password1);
                                setPassword2(e.target.value);
                            }}
                        />
                        {checkPassword?.isActive && (
                            <div className="passwordCard" id="input">
                                <p className="title">Votre mot de passe doit contenir:</p>
                                <div className="passwordText">
                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.upperCase ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Possède une majuscule</span>
                                    </p>
                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.lowerCase ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Possède une minuscule</span>
                                    </p>
                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.number ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Possède un chiffre</span>
                                    </p>

                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.character ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Possède un caractère spécial (&/#...)</span>
                                    </p>
                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.count ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Contient au moins 8 caractère</span>
                                    </p>
                                    <p>
                                        <i
                                            className={`${
                                                checkPassword.match ? "far fa-check-circle" : "far fa-times-circle"
                                            }`}
                                        />
                                        <span>Les 2 mot de passes sont identiques</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>

                    <button onClick={updatePassword}>Confirmer</button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
