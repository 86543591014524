const InputRange = ({ defaultValue, maxValue, minValue, text, onChange }) => {
    return (
        <div className="pt-7 w-fit">
            <p>{text} :</p>
            <div className="p-1 flex items-center ">
                <input
                    onChange={(e) => onChange(e.target.value)}
                    type="range"
                    className=""
                    min={minValue}
                    max={maxValue}
                    value={defaultValue}
                />
            </div>
        </div>
    );
};

export default InputRange;
