import Coordonnees from "./Coordonnees";
import Factures from "./Factures";
import IdentifiantConnexion from "./IdentifiantConnexion";
import InformationBancaires from "./InformationBancaires";
import RechargeAutomatique from "./RechargeAutomatique";
import SetupStripeSepa from "./SetupStripeSepa";
import Tarifs from "./Tarifs";

const Compte = { Coordonnees, Factures, IdentifiantConnexion, InformationBancaires, RechargeAutomatique, Tarifs, SetupStripeSepa };

export default Compte;
