import { React, useState } from "react";
import { Layout, HeadPage, Boutique, Popup } from "components";
import image from "../../../assets/images/boutique/pack-telesecretariat.svg";

const PackTelesecretariat = () => {
    const [showFirstPopup, setShowFirstPopup] = useState(false);
    const [showSecondPopup, setShowSecondPopup] = useState(false);

    const handleContinuerClick = () => {
        setShowFirstPopup(false);
        setShowSecondPopup(true);
    };

    const handleSecondPopupClose = () => {
        setShowSecondPopup(false);
    };
    return (
        <Layout>
            <HeadPage title="Pack Télésecrétariat" isProfil={false} />
            <Boutique.Option
                description="Une télésecrétaire décroche les appels au nom de votre entreprise et se charge de prendre le message. Vous recevez par sms et mail l'ensemble des informations liées à l'appel (Numéro de l'appelant, nom, entreprise, objet de l'appel)"
                img={image}
                bottomdescription={{
                    text: "L'ajout de votre pack télésecretariat coûte 10€ HT par mois dès son activation. Cette somme sera rajoutée à votre abonnement mensuel. Chaque appel transferé vers le télésecrétariat sera facturé  2€ HT depuis vos crédits.",
                }}
                button={{ text: "Ajouter", onClick: () => setShowFirstPopup(true) }}
            />
            {showFirstPopup && (
                <Popup.PopupClassic className="PopupSuccess">
                    <h1>Le pack télésecrétariat a été activée</h1>
                    <i class="fas fa-check-circle"></i>
                    <button onClick={handleContinuerClick}>Continuer</button>
                </Popup.PopupClassic>
            )}
            {showSecondPopup && (
                <Popup.PopupClassic className="PopupFailed">
                    <h1>Le paiement a échoué</h1>
                    <i class="fas fa-times-circle"></i>
                    <button onClick={handleSecondPopupClose}>Fermer</button>
                </Popup.PopupClassic>
            )}
        </Layout>
    );
};

export default PackTelesecretariat;
