import Skeleton from "react-loading-skeleton";

import "./Table.scss";

const Table = ({ listHead, children, options = {}, className, style }) => {
    const { isFixed } = options;

    return (
        <div className="TableResponsive">
            <table className={`${className || ""} TableAll`} style={style || {}}>
                <thead style={isFixed ? { top: 0 } : {}} className={`${isFixed ? "sticky z-10" : ""} bg-white`}>
                    <tr>
                        {listHead?.map((item, index) => {
                            return (
                                <th key={index} style={{ whiteSpace: "pre-line" }}>
                                    {item}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {children?.length > 0 ? (
                        children
                    ) : (
                        <>
                            <tr>
                                <td colSpan={listHead?.length}>
                                    <Skeleton variant="rectangular" width="100%" height={50} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={listHead?.length}>
                                    <Skeleton variant="rectangular" width="100%" height={50} />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
