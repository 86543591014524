import React, { useState } from "react";

import "./CallTableRow.scss";
const CallTableRow = ({ data, updateComment, playResumeAudio, audioInfo }) => {
    const [comment, setComment] = useState(data.commentaire || "");

    const editComment = async (e) => {
        e.preventDefault();
        updateComment(data.id, comment);
    };

    return (
        <tr>
            <td>
                <input type="checkbox" />
                <i style={{ width: "15px" }} className={data.icon}></i>
            </td>

            <td>
                <p>{data.date}</p>
            </td>

            <td className="flex items-center gap-4">
                <p className={`${data.color} font-bold`}>{data.correspondant}</p>
                {data.message_id && (
                    <i
                        className={`${
                            audioInfo.call_id === data.id
                                ? audioInfo.paused
                                    ? "fas fa-play"
                                    : "fas fa-pause"
                                : "far fa-volume"
                        }`}
                        onClick={() => playResumeAudio(data.id)}
                    ></i>
                )}
            </td>

            <td>
                <p className={`${data.color} font-bold`}>{data.tel}</p>
            </td>

            <td>
                <p>{data.time}</p>
            </td>

            <td>
                <form onSubmit={editComment} className="flex items-center justify-between mr-4">
                    <input
                        className="w-full mr-2 px-2"
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                    />
                    {data.commentaire !== comment && (
                        <button type="submit">
                            <i className="fas fa-paper-plane" onClick={editComment}></i>
                        </button>
                    )}
                </form>
            </td>
        </tr>
    );
};

export default CallTableRow;
