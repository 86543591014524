import React from "react";

import "./TableRow.scss";

const TableRow = ({ data, removeNum, i }) => {
    return (
        <tr className="SMSTableRow">
            <td className="Numbers">
                <p>{data}</p>
            </td>
            <td>
                <i
                    className="far fa-trash"
                    onClick={() => {
                        removeNum(i);
                    }}
                ></i>
            </td>
        </tr>
    );
};

export default TableRow;
