import React, { useState, useReducer, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Layout, Table, InputList, Boutique, HeadPage, Popup } from "components";

import "./EnvoyerSms.scss";

import useMainContext from "contexts/MainContext";

import CreateCampaign from "utils/api/boutique/sms/CreateCampaign";
import SendCampaign from "utils/api/boutique/sms/SendCampaign";

const initialState = {
    selectedFile: null,
    campaign: { name: "", text: "", smscount: 0, isFile: false, numbers: [] },
    actions: {
        back: null,
        next: null,
        button: false,
        input: false,
        btnText: "Suivant",
        update: null,
    },
    popup: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_SELECTED_FILE":
            return { ...state, selectedFile: action.payload };
        case "SET_NUMBERS":
            return { ...state, numbers: action.payload };
        case "UPDATE_CAMPAIGN":
            return { ...state, campaign: action.payload };
        case "UPDATE_ACTIONS":
            return { ...state, actions: action.payload };
        case "SET_POPUP":
            return { ...state, popup: action.payload };
        case "CREATE_CAMPAIGN":
            // console.log("CREATE_CAMPAIGN", state.campaign);

            const createCampaign = async () => {
                const campaignData = await CreateCampaign({ campaign: state.campaign });

                if (campaignData.success) {
                    // console.log("campaignData", campaignData);

                    const sendCampaignData = await SendCampaign({ campaign: campaignData.data });

                    if (sendCampaignData.success) {
                        window.location.reload();
                    }
                }
            };

            createCampaign();

            return { ...state };
        case "RESET":
            return initialState;
        default:
            throw new Error();
    }
};

const EnvoyerSms = () => {
    const { customer } = useMainContext();

    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = useState();

    const [state, dispatch] = useReducer(reducer, initialState);

    const updateName = (e) => {
        dispatch({ type: "UPDATE_CAMPAIGN", payload: { ...state.campaign, name: e.target.value } });
    };

    const updateMessage = (message, smscount) => {
        dispatch({ type: "UPDATE_CAMPAIGN", payload: { ...state.campaign, text: message, smscount } });
    };

    const updateNumbers = (nums) => {
        dispatch({ type: "UPDATE_CAMPAIGN", payload: { ...state.campaign, numbers: nums } });
    };

    const editAction = ({ backNumber, nextNumber, button, input, btnText, update }) => {
        dispatch({
            type: "UPDATE_ACTIONS",
            payload: {
                back: () => editPopup(backNumber),
                next: () => editPopup(nextNumber),
                button,
                input,
                btnText,
                update,
            },
        });
    };

    const editPopup = async (popupNumber) => {
        const actions = {
            1: {
                backNumber: null,
                nextNumber: null,
                button: false,
                input: false,
                btnText: "Suivant",
                update: null,
            },
            2: {
                backNumber: 1,
                nextNumber: 4,
                button: true,
                input: true,
                btnText: "Suivant",
                update: null,
            },
            3: {
                backNumber: 1,
                nextNumber: 4,
                button: true,
                input: true,
                btnText: "Suivant",
                update: null,
            },
            4: {
                backNumber: 1,
                nextNumber: 5,
                button: true,
                input: true,
                btnText: "Envoyer",
                update: () => dispatch({ type: "CREATE_CAMPAIGN" }),
            },
            5: {
                backNumber: null,
                nextNumber: null,
                button: false,
                input: false,
                btnText: "Fermer",
                update: false,
            },
        };

        editAction(actions[popupNumber]);

        dispatch({
            type: "SET_POPUP",
            payload: popupNumber,
        });
    };

    const popupDisplay = useMemo(() => {
        return {
            1: (
                <Boutique.EnvoyerSMS.Popup.SelectImport
                    importFile={(fileData) => setSelectedFile(fileData)}
                    editPopup={editPopup}
                />
            ),
            2: <Boutique.EnvoyerSMS.Popup.ListNumbers updateCampaign={updateNumbers} />,
            3: <Boutique.EnvoyerSMS.Popup.ImportedFile file={selectedFile} updateCampaign={updateNumbers} />,
            4: <Boutique.EnvoyerSMS.Popup.WriteMessage updateMsg={updateMessage} />,
            5: (
                <Boutique.EnvoyerSMS.Popup.Validate
                    setPopup={() => dispatch({ type: "SET_POPUP", payload: false })}
                    validateText={"Votre campagne sms est en cours d’envoi"}
                    buttonText={"Fermer"}
                    close={true}
                />
            ),
        }[state.popup];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.popup, state.campaign]);

    return (
        <Layout classNameCustom="EnvoyerSMS">
            <div id="sms" style={{ width: "fit-content" }}>
                <HeadPage isProfil={false} title="Mes campagnes SMS" />

                <h2>Envoyer un sms</h2>

                <div className="mt-5 mb-5">
                    <p className="text-base mb-2">L'envoi de SMS utilise les crédits disponibles sur votre compte.</p>
                    <InputList.InputButton
                        onClick={() => navigate("/boutique/ajouter-credits")}
                        text={"Ajouter des crédits"}
                        classNameCustom="h-9 px-5"
                    />
                </div>
            </div>

            <div className="EnvoyerSMSContainer">
                <InputList.InputClassic
                    label="Nom de campagne"
                    id="nomCampagne"
                    placeholder="Campagne SMS Parent d'élève"
                    defaultValue={state.campaign?.name}
                    wCustom="w-1/2 pb-6"
                    type="text"
                    required={true}
                    onChange={(e) => {
                        updateName(e);
                    }}
                    className="pb-0 mr-4"
                />

                <InputList.InputButton
                    onClick={() => editPopup(1)}
                    classNameCustom="h-9 px-8"
                    text="Nouvelle Campagne"
                    isActive={customer.get.id !== undefined}
                />
            </div>

            {state.popup !== false && (
                <Popup.PopupClassic
                    onClose={() =>
                        dispatch({
                            type: "SET_POPUP",
                            payload: false,
                        })
                    }
                >
                    <Boutique.EnvoyerSMS.Popup.PopupLayout
                        pressBack={state.actions.back}
                        pressNext={state.actions.next}
                        input={state.actions.input}
                        button={state.actions.button}
                        btnText={state.actions.btnText}
                        update={state.actions.update}
                        setName={updateName}
                        name={state.campaign?.name}
                    >
                        {popupDisplay}
                    </Boutique.EnvoyerSMS.Popup.PopupLayout>
                </Popup.PopupClassic>
            )}

            <h2 style={{ paddingTop: "20px" }}>Historique des campagnes sms</h2>
            <Table listHead={["Nom", "Nombre de correspondant", "Actions", "Date"]} options={{ isFixed: true }}>
                {customer?.get?.smsCampaign?.map((data, index) => (
                    <Boutique.EnvoyerSMS.TableRow data={data} key={index} />
                ))}
            </Table>
        </Layout>
    );
};

export default EnvoyerSms;
