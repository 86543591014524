import "./Onboarding.scss";

import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import ProgressBar from "./ProgressBar";

import AnnonceAccueil from "components/onBoarding/AnnonceAccueil";
import HoraireOuverture from "components/onBoarding/HoraireOuverture";
import ModeRenvoi from "components/onBoarding/ModeRenvoi";
import MandatSEPA from "components/onBoarding/MandatSEPA";

import { GetCustomerInfo } from "utils/api/customer/index/GetCustomerInfo";
import { GetOutOfPackageRateByPrefix } from "utils/api/boutique/package/GetOutOfPackageRateByPrefix";

import useMainContext from "contexts/MainContext";

import UpdateOnboarding from "utils/api/auth/onboarding/UpdateOnboarding";

import useMessageContext from "contexts/MessageContext";

const Onboarding = () => {
    const { customer, telephoneSwitchboard } = useMainContext();

    const navigation = useNavigate();
    const { showMessage } = useMessageContext();

    useEffect(() => {
        if (customer.get?.isOnboarded) {
            navigation("/");
        } else {
            setDatas();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.get]);

    const [data, setData] = useState({
        redirect_type: 1,
        redirect: [],
        billing: {},
        id: {},
    });

    const [welcome, setWelcome] = useState({
        text: "",
        voiceId: "Lea",
    });

    const [music, setMusic] = useState({
        audio_path: "",
        isUpload: false,
    });

    const setDatas = () => {
        setWelcome({
            text: telephoneSwitchboard.get?.script?.Audio_Message?.find((e) => e.message_type === "Accueil").text,
            voiceId: telephoneSwitchboard.get?.script?.Audio_Message?.find((e) => e.message_type === "Accueil")
                .voice_id,
        });

        setMusic({
            audio_path: telephoneSwitchboard.get?.script?.MusicHold[0].audio_path,
            isUpload: false,
        });
    };

    const update = async (update) => {
        const key = Object.keys(update)[0];
        setData((data) => ({ ...data, [key]: update[key] }));
    };

    const [progress, setProgress] = useState(25);
    const [componentIndex, setComponentIndex] = useState(0);

    const handleNext = async () => {
        const error = await handleError();
        if (error) {
            showMessage({
                text: error,
                type: "error",
            });
            return;
        }

        if (progress < 100) {
            setProgress(progress + 25);
        }

        setComponentIndex(componentIndex + 1);
        contentRef.current.scrollTo(0, 0);

        setData({
            ...data,
            id: {
                script: customer.get.telephoneSwitchboard[0].script_id,
                profile: customer.get.telephoneSwitchboard[0].Profile[0].id,
                standard_number: customer.get.telephoneSwitchboard[0].standard_number.standard_number,
            },
        });

        if (componentIndex === 3) {
            const dataSend = { ...data };

            const req = await UpdateOnboarding(dataSend);

            if (!req?.success) {
                return;
            }

            const response = await GetCustomerInfo();
            const infos = response.data;

            telephoneSwitchboard.set(infos.telephoneSwitchboard[0]);
            customer.set(infos);
            navigation("/");
        }
    };

    const handleError = async () => {
        let message;
        switch (componentIndex) {
            case 0:
                if (!welcome.text) return (message = "Veuillez entrer un message d'accueil");
                if (music.audio_path === undefined) return (message = "Veuillez sélectionner une musique d'attente");
                break;

            case 2:
                if (!data.redirect.length > 0)
                    return (message = "Veuillez ajouter au minimum un numéro de renvoi (+...)");

                const error = {
                    message: "",
                    error: false,
                };

                await Promise.all(
                    data.redirect.map(async (number) => {
                        const { data: rate } = await GetOutOfPackageRateByPrefix({
                            prefix: number.number,
                        });

                        const prefixData = {
                            isInclude: rate?.isInclude,
                            isEnable: rate?.isEnable,
                            tarif: rate?.tarif,
                        };

                        if (!prefixData.isEnable || !prefixData.isInclude) {
                            error.error = true;
                            error.message = "Un ou plusieurs numéro ne sont pas disponible";

                            // break the loop
                            return;
                        }
                    })
                );

                if (error.error) {
                    return error.message;
                }

                // console.log(data);

                break;
            case 3:
                if (!data.billing.city) return (message = "Veuillez entrer une ville");
                if (!data.billing.zip) return (message = "Veuillez entrer un code postal");
                if (!data.billing.address) return (message = "Veuillez entrer une adresse");
                if (!data.billing.country) return (message = "Veuillez entrer un pays");
                if (data.billing.iban) {
                    let checkIban = await fetch("https://openiban.com/validate/" + data.billing.iban);
                    checkIban = await checkIban.json();
                    if (!checkIban.valid) return (message = "Veuillez entrer un IBAN valide");
                }
                if (!data.billing.iban) return (message = "Veuillez entrer un IBAN");
                if (!data.billing.company_name) return (message = "Veuillez entrer un nom d'entreprise");
                if (!data.billing.firstname) return (message = "Veuillez entrez un prénom");
                if (!data.billing.lastname) return (message = "Veuillez entrer un nom");
                break;
            default:
                break;
        }
        return message;
    };

    const components = [
        <AnnonceAccueil
            welcome={telephoneSwitchboard.get?.script?.Audio_Message?.find((e) => e.message_type === "Accueil")}
            script={telephoneSwitchboard.get?.script}
            setWelcome={setWelcome}
            setMusic={setMusic}
        />,
        <HoraireOuverture handleNext={handleNext} />,
        <ModeRenvoi update={update} />,
        <MandatSEPA update={update} />,
    ];
    const contentRef = useRef(null);

    return (
        <div className="onBoarding">
            <div className="Content" ref={contentRef}>
                <ProgressBar progress={progress} />
                <h1>Réglage de votre standard</h1>
                {components[componentIndex] || (
                    <div>
                        Création de votre espace client en cours...
                        <i class="fas fa-spinner-third fa-spin"></i>
                    </div>
                )}
                {componentIndex !== 1 && componentIndex !== 4 && (
                    <button
                        className="Button"
                        onClick={() => {
                            handleNext();
                        }}
                    >
                        Suivant
                    </button>
                )}
            </div>
        </div>
    );
};

export default Onboarding;
