import api from "utils/api/api";

import { useMutation } from "react-query";

const SetDefaultCard = async ({ card_id }) => {
    try {
        const response = await api.get(`/api/v1/payment/card/${card_id}/set-default`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useSetDefaultCard = () => useMutation((card_id) => SetDefaultCard({ card_id }));

export default useSetDefaultCard;
export { SetDefaultCard };
