import InputRange from "./Range";
import InputToggle from "./Toggle";
import InputDropdown from "./Dropdown";
import InputDropdownCountry from "./DropdownCountry";
import InputClassic from "./Classic";
import InputButton from "./Button";
import InputComboboxes from "./Comboboxes";
import InputCheckbox from "./Checkbox";
import InputTextarea from "./Textarea";
import InputPhone from "./Phone";
import InputDouble from "./InputDouble";
import InputRadio from "./InputRadio";

const InputList = {
    InputRange,
    InputToggle,
    InputDropdown,
    InputDropdownCountry,
    InputClassic,
    InputButton,
    InputComboboxes,
    InputCheckbox,
    InputTextarea,
    InputPhone,
    InputDouble,
    InputRadio,
};

export default InputList;
