import React from "react";

import { Cards, InputList } from "components";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";

import { ReactComponent as Visa } from "assets/icons/money/visa.svg";
import { ReactComponent as MasterCard } from "assets/icons/money/mastercard.svg";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";
import usePopupContext from "contexts/PopupContext";

import useGetAllCards from "utils/api/customer/billing/card/GetAllCards";
import useSetDefaultCard from "utils/api/customer/billing/card/SetDefaultCard";
import useDeleteCard from "utils/api/customer/billing/card/DeleteCard";
import usePostNewCard from "utils/api/customer/billing/card/PostNewCard";

import "./InfosCards.scss";

const InfosCards = () => {
    const stripe = useStripe();
    const elements = useElements();

    const { customer } = useMainContext();
    const { showPopup } = usePopupContext();
    const { showMessage } = useMessageContext();

    const { data: cards, refetch: refetchCards } = useGetAllCards({ customer_id: customer.get.id });

    const { mutateAsync: SetDefaultCard } = useSetDefaultCard();
    const { mutateAsync: DeleteCard } = useDeleteCard();
    const { mutateAsync: PostNewCard } = usePostNewCard();

    const changeDefault = async (card_id) => {
        const req = await SetDefaultCard({
            card_id,
        });

        if (!req.success) {
            return showMessage({
                type: "error",
                text: "Une erreur est survenue lors du changement de carte par défaut",
            });
        }

        refetchCards();
    };

    const saveCard = async (e) => {
        e.preventDefault();

        // create a card token
        const cardElement = elements.getElement(CardElement);

        const { error, token } = await stripe.createToken(cardElement);

        if (!error) {
            const req = await PostNewCard({
                token: token.id,
            });

            if (req.success) {
                refetchCards();

                return showMessage({
                    type: "success",
                    text: "La carte a bien été ajoutée",
                });
            }
        }

        return showMessage({
            type: "error",
            text: "Une erreur est survenue lors de la sauvegarde de la carte",
        });
    };

    const addCard = () => {
        return showPopup(
            <form className="addCardForm" onSubmit={saveCard}>
                <CardElement />
                <InputList.InputButton type="submit" disabled={!stripe} className="button" text="Ajouter la carte" />
            </form>
        );
    };

    const removeCard = async (card_id) => {
        const req = await DeleteCard({
            card_id,
        });

        if (!req.success) {
            return showMessage({
                type: "error",
                text: "Une erreur est survenue lors de la suppression de la carte",
            });
        }

        refetchCards();

        showMessage({
            type: "success",
            text: "La carte a bien été supprimée",
        });
    };

    return (
        <div>
            <p className="card-container-title">Carte bancaire principale</p>
            <div className="Overflow main-card">
                {cards?.data
                    ?.filter((card) => card.isDefault)
                    ?.map((card, index) => (
                        <React.Fragment key={card?.last + "-" + String(index)}>
                            {card.brand === "visa" ? (
                                <>
                                    <Visa className="Icon" />
                                    <p>Visa/Electron ****{card.last4} </p>
                                </>
                            ) : (
                                <>
                                    <MasterCard className="Icon" />
                                    <p>MasterCard ****{card.last4}</p>
                                </>
                            )}
                        </React.Fragment>
                    ))}
            </div>
            <h3 className="card-container-title"> Vos cartes bancaires</h3>
            <div className="Overflow flex gap-2 list-cards">
                {cards?.data?.map((card, index) => (
                    <Cards.CreditCard
                        id={index}
                        remove={removeCard}
                        changeDefault={() => changeDefault(card.id)}
                        key={index}
                        card={card}
                    />
                ))}
                <Cards.CreditCard addCard={addCard} />
            </div>
        </div>
    );
};

export default InfosCards;
