import { useState } from "react";

import "./SelectImport.scss";

const SelectImport = ({ importFile, editPopup }) => {
    const [error, setError] = useState([false, ""]);

    // const handleFile = (e) => {
    //     const file = e.target.files[0];
    //     const ext = file.name.split(".");
    //     // console.log(ext[1]);
    //     // console.log(ext[1] === "xlsx");
    //     if (ext[1] === "csv" || ext[1] === "xlsx") {
    //         importFile(file);
    //         return;
    //     }
    //     setError([true, "Le fichier importé n’est pas au bon format, veuillez réessayer avec le format indiqué."]);
    // };

    return (
        <div className="ImportContainer">
            <h3>Comment voulez-vous ajouter vos contacts ?</h3>

            <div className="Import" type="file" name="file">
                {/* <div className="ImportCard">
                    <input
                        type="file"
                        name="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFile}
                        multiplefiles="false"
                        style={{
                            position: "absolute",
                            opacity: "0",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                        }}
                    />

                    <i className="far fa-arrow-to-bottom Icon" />
                    <p className="Title">Importer un fichier (csv ou xls)</p>
                </div> */}

                <div className="ImportCard" onClick={() => editPopup(2)}>
                    <i className="far fa-pencil Icon"></i>
                    <p className="Title">Importer manuellement</p>
                </div>
            </div>

            {error[0] && <p className="Error">{error[1]}</p>}
        </div>
    );
};

export default SelectImport;
