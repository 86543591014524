import api from "../../api";

const SendCampaign = async ({ campaign }) => {
    try {
        const response = await api.post(`/api/v1/sms/sms-campaign/send/${campaign?.id}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default SendCampaign;
