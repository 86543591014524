import api from "utils/api/api";

const GetInvoicePdf = async ({ invoice_id }) => {
    try {
        const response = await api.get(`/api/v1/invoice/${invoice_id}/pdf`);

        console.log("response >> ", response.data);
        return response.data;
    } catch (err) {
        throw err;
    }
};
export { GetInvoicePdf };

