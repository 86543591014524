import { useState } from "react";
import { InputList, LoginHeader } from "components";

import useMessageContext from "contexts/MessageContext";

import { ForgotPasswordEmail } from "utils/api/auth/ForgotPassword";

import "./ForgotPassword.scss";
const ForgotPassword = () => {
    const { showMessage } = useMessageContext();
    const [text, setText] = useState("");

    const sendForm = () => {
        ForgotPasswordEmail({ email: text })
            .then(() => {
                showMessage({
                    text: "Un email vous a été envoyé.",
                    type: "success",
                });
            })
            .catch(() => {
                showMessage({
                    text: "Une erreur est survenue. Veuillez contacter le support.",
                    type: "error",
                });
            });
    };

    return (
        <div>
            <LoginHeader />
            <div className="content">
                <h1>Réinitialisez votre mot de passe</h1>
                <div className="form">
                    <div className="input">
                        <InputList.InputClassic
                            type="email"
                            name="email"
                            placeholder={"Adresse mail"}
                            // value={mail[0]}
                            onChange={(e) => {
                                setText(e.target.value);
                                // console.log(e);
                            }}
                        />
                        {/* {mail[2]? <img src={check} />:null} */}
                    </div>
                    <button onClick={sendForm}>Confirmer</button>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;