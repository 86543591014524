import React, { createContext, useState } from "react";

import data from "utils/data/guide/guide.json";

import { GuidePopup } from "components";

const GuideContext = createContext();

const GuideProvider = ({ children }) => {
    const [path, setPath] = useState("/");

    const [data, setData] = useState({
        title: "",
        desc: "",
        element: "",
        position: {},
    });

    const [isDisplayed, setIsDisplayed] = useState(false);

    const [step, setStep] = useState({ id: 1, step_id: 1, stepsNumber: 3 });

    const value = {
        path: {
            get: path,
            set: setPath,
        },
        data: {
            get: data,
            set: setData,
        },
        isDisplayed: {
            get: isDisplayed,
            set: setIsDisplayed,
        },
        step: {
            get: step,
            set: setStep,
        },
    };

    return <GuideContext.Provider value={value}>{children}</GuideContext.Provider>;
};

const useGuideContext = () => {
    const context = React.useContext(GuideContext);

    if (context === undefined) {
        throw new Error("useGuideContext must be used within a GuideProvider");
    }

    const start = () => {
        const actualStep = data[0];
        const nextSubStep = actualStep.steps[0];

        context.data.set({
            title: nextSubStep.title,
            desc: nextSubStep.desc,
            element: nextSubStep.element,
            position: nextSubStep.position,
        });

        context.path.set(actualStep.path);

        context.step.set({ id: 1, step_id: 1, stepsNumber: actualStep.steps.length });

        return context.isDisplayed.set(true);
    };

    const nextStep = async () => {
        const { step } = context;
        const { id, step_id } = step.get;

        const dataId = id - 1;

        const actualStep = data[dataId];
        const nextSubStep = actualStep.steps[step_id];

        if (nextSubStep) {
            context.data.set({
                title: nextSubStep.title,
                desc: nextSubStep.desc,
                element: nextSubStep.element,
                position: nextSubStep.position,
            });

            context.step.set({ id, step_id: step_id + 1, stepsNumber: actualStep.steps.length });

            return;
        } else {
            // check if next step exist
            const nextStep = data[id];

            if (nextStep) {
                const nextSubStep = nextStep.steps[0];

                context.data.set({
                    title: nextSubStep.title,
                    desc: nextSubStep.desc,
                    element: nextSubStep.element,
                    position: nextSubStep.position,
                });

                context.path.set(nextStep.path);

                context.step.set({ id: id + 1, step_id: 1, stepsNumber: nextStep.steps.length });
                return;
            }
        }

        return context.isDisplayed.set(false);
    };

    const datas = context.data.get;
    const step = context.step.get;

    const guideElement = (
        <GuidePopup
            id="guide-popup"
            title={datas.title}
            desc={datas.desc}
            position={datas.position}
            onNext={step?.step_id < step.stepsNumber ? nextStep : null}
            currentStep={step?.step_id}
            stepsNumber={step?.stepsNumber}
            onFinish={step?.step_id === step.stepsNumber ? nextStep : null}
        />
    );

    return { ...context, nextStep, start, guideElement };
};

export { GuideContext, GuideProvider };
export default useGuideContext;
