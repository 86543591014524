import { useQuery } from "react-query";

import api from "utils/api/api";

const GetMenuByStandardNumberID = async ({ standard_number_id }) => {
    try {
        if (standard_number_id === undefined) throw new Error("standard_number_id is undefined");

        const response = await api.get(`/api/v1/script/${standard_number_id}/menu`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetMenuByStandardNumberID = ({ standard_number_id, key }) =>
    useQuery(
        ["getMenu", standard_number_id, key],
        async () => await GetMenuByStandardNumberID({ standard_number_id }),
        {
            enabled: standard_number_id !== undefined,
        }
    );

export { GetMenuByStandardNumberID, useGetMenuByStandardNumberID };
