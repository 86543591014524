import { useEffect, useState } from "react";

import "./Coordonnees.scss";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import pays from "utils/pays.json";

import { InputList } from "components";

import { PatchCustomerInfo } from "utils/api/customer/index/PatchCustomerInfo";
import { PatchAddressInfo } from "utils/api/address/PatchAddressInfo";

const Coordonnees = () => {
    const { customer } = useMainContext();

    const { showMessage } = useMessageContext();

    const [profil, setProfile] = useState({
        lastname: "",
        firstname: "",
        email: "",
        main_number: "",
    });

    const [company, setCompany] = useState({
        company_name: "",
        zip: "",
        city: "",
        country: "",
        address: "",
    });

    useEffect(() => {
        setProfile({
            lastname: customer.get.lastname || "",
            firstname: customer.get.firstname || "",
            email: customer.get.email || "",
            main_number: customer.get.main_number || "",
        });

        setCompany({
            company_name: customer.get?.customer_address?.company_name,
            zip: customer.get?.customer_address?.zip || "",
            city: customer.get?.customer_address?.city || "",
            country: customer.get?.customer_address?.country || "",
            address: customer.get?.customer_address?.address || "",
        });
    }, [customer.get]);

    const sendForm = async (el) => {
        el.preventDefault();

        const responseUser = await PatchCustomerInfo(customer.get.id, profil);
        const responseCompany = await PatchAddressInfo(customer.get.id, company);

        if (responseUser.success && responseCompany.success) {
            showMessage({
                text: "Vos données ont bien été mises à jour",
                type: "success",
            });

            customer.set({
                ...customer.get,
                ...profil,
                customer_address: { ...customer.get.customer_address, ...company },
            });
        } else {
            showMessage({
                text: "Une erreur est survenue lors de la mise à jour de vos données",
                type: "error",
            });
        }
    };

    return (
        <div className="CoordonneesComponent">
            <form onSubmit={sendForm}>
                <div className="side">
                    <InputList.InputClassic
                        label="Société"
                        id="societe"
                        defaultValue={company.company_name}
                        onChange={(event) => setCompany({ ...company, company_name: event.target.value })}
                        required
                    />

                    <div className="flex w-full justify-between">
                        <InputList.InputClassic
                            label="Nom"
                            id="lname"
                            wCustom="w-[45%]"
                            defaultValue={profil.lastname}
                            onChange={(event) => setProfile({ ...profil, lastname: event.target.value })}
                            required
                        />
                        <InputList.InputClassic
                            label="Prénom"
                            id="fname"
                            wCustom="w-[45%]"
                            defaultValue={profil.firstname}
                            onChange={(event) => setProfile({ ...profil, firstname: event.target.value })}
                            required
                        />
                    </div>

                    <InputList.InputClassic
                        label="Email"
                        id="email"
                        type="email"
                        defaultValue={profil.email}
                        onChange={(event) => setProfile({ ...profil, email: event.target.value })}
                        required
                    />
                    <InputList.InputClassic
                        label="Téléphone"
                        id="phone"
                        type="phone"
                        defaultValue={profil.main_number}
                        onChange={(event) => setProfile({ ...profil, main_number: event.target.value })}
                        required
                    />
                </div>
                <div className="side">
                    <div className="pt-2">
                        <InputList.InputTextarea
                            defaultValue={company.address}
                            onChange={(event) => setCompany({ ...company, address: event })}
                            label={"Adresse"}
                        />
                    </div>

                    <div className="flex w-full justify-between">
                        <InputList.InputClassic
                            label="Code postal"
                            id="zip"
                            wCustom="w-[45%]"
                            onChange={(event) => setCompany({ ...company, zip: event.target.value })}
                            defaultValue={company.zip}
                            required
                        />
                        <InputList.InputClassic
                            label="Ville"
                            id="city"
                            wCustom="w-[45%]"
                            onChange={(event) => setCompany({ ...company, city: event.target.value })}
                            defaultValue={company.city}
                            required
                        />
                    </div>
                    <div className="flex flex-col py-2">
                        <InputList.InputDropdown
                            wCustom="w-full"
                            list={pays}
                            word="name"
                            id="pays"
                            defaultValue={company.country}
                            onChange={(event) => setCompany({ ...company, country: event })}
                            label={"Pays"}
                            obj={true}
                        />
                    </div>
                    <div className="pt-6">
                        <InputList.InputButton type="submit" text="Mettre à jour mes coordonnées" />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Coordonnees;
