import { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { HeadPage, InputList, Layout } from "components";

import "./Ticket.scss";

import attach from "assets/icons/attach.svg";

import useGetCustomerTicket from "utils/api/zendesk/GetCustomerTicket";
import { UploadFile } from "utils/api/zendesk/UploadFile";
import { UpdateCustomerTicket } from "utils/api/zendesk/UpdateCustomerTicket";

import getBase64FromFile from "utils/helpers/getBase64FromFile";

import useMessageContext from "contexts/MessageContext";

const Ticket = () => {
    const { showMessage } = useMessageContext();

    const { id } = useParams();

    const { data: customerTickets, refetch: refetchCustomerTickets } = useGetCustomerTicket(id);
    const ticket = customerTickets?.data;

    const [showImage, setShowImage] = useState(false);

    const [selectedImages, setSelectedImages] = useState([]);

    const handleUpdate = async (e) => {
        e.preventDefault();

        let uploads = [];
        // check if selectedImages is not empty
        if (selectedImages.length > 0) {
            // upload images
            await Promise.all(
                selectedImages.map((image) => {
                    return new Promise(async (resolve) => {
                        const uploadData = await UploadFile({
                            name: image.info.name,
                            data: image.imageData,
                        });

                        if (uploadData.success) {
                            uploads.push(uploadData.data.upload.token);
                        }

                        resolve();
                    });
                })
            );
        }

        const { success } = await UpdateCustomerTicket(id, e.target.answer.value, uploads);

        if (success) {
            showMessage({
                type: "success",
                message: "Votre ticket a bien été mis à jour",
            });

            refetchCustomerTickets();

            // remove the value of the input
            e.target.answer.value = "";

            // remove the selected images
            setSelectedImages([]);
        } else {
            showMessage({
                type: "error",
                message: "Une erreur est survenue",
            });
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        let dateFormat = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;

        // check if date is today or yesterday
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (
            d.getDate() === today.getDate() &&
            d.getMonth() === today.getMonth() &&
            d.getFullYear() === today.getFullYear()
        ) {
            dateFormat = `Aujourd'hui à ${d.getHours()}:${d.getMinutes()}`;
        }

        if (
            d.getDate() === yesterday.getDate() &&
            d.getMonth() === yesterday.getMonth() &&
            d.getFullYear() === yesterday.getFullYear()
        ) {
            dateFormat = `Hier à ${d.getHours()}:${d.getMinutes()}`;
        }

        return dateFormat;
    };

    const fileInputRef = useRef(null);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file && isImageFile(file)) {
            // Créer l'objet contenant les informations de l'image
            const imageInfo = {
                lastModified: file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type,
            };

            // Convertir l'image en données base64
            getBase64FromFile(file, (result) => {
                // Ajouter l'image et ses informations à l'état selectedImages
                setSelectedImages((prevImages) => [...prevImages, { imageData: result, info: imageInfo }]);
            });
        } else {
            showMessage({
                text: "Veuillez sélectionner une image valide (format .jpg, .png, .gif, etc.).",
                type: "error",
            });
        }
    };

    const isImageFile = (file) => {
        const acceptedImageTypes = ["image/jpeg", "image/png", "image/gif"];
        return file && acceptedImageTypes.includes(file.type);
    };

    const handleOpenFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <Layout className="ticket">
            <div className="flex">
                <Link to="/support/tickets">
                    <p>
                        <i className="fas fa-chevron-left" /> Retour &nbsp;
                    </p>
                </Link>
                <HeadPage title={" Ticket : " + ticket?.ticket?.raw_subject || ""} isProfil={false} />
            </div>

            <div className="answers">
                {ticket?.comments?.map((comment) => {
                    return (
                        <div
                            className={`answer ${
                                comment.author_id === ticket?.ticket?.requester_id ? "answer-author" : "answer-service"
                            }`}
                            key={comment?.created_at}
                        >
                            <div className={`answer-header`}>
                                <p>
                                    <b>
                                        {comment.author_id === ticket?.ticket?.requester_id
                                            ? comment?.via.source.to.name
                                            : "Support Technique"}
                                    </b>
                                </p>
                                <p>{formatDate(comment?.created_at)}</p>
                            </div>
                            <div className="answer-content">
                                <p>{comment?.body}</p>
                            </div>

                            {comment?.attachments?.length > 0 && (
                                <div className="answer-attachments">
                                    {comment?.attachments?.map((attachment) => {
                                        return (
                                            <div
                                                className="answer-attachment"
                                                key={attachment?.id}
                                                onClick={() => setShowImage(attachment.content_url)}
                                            >
                                                {/* <a href={attachment?.content_url} target="_blank"> */}
                                                <img src={attachment.content_url} alt="answer-img" />
                                                {/* </a> */}
                                                <i className="fas fa-search-plus" />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {["open", "hold", "pending"].includes(ticket?.ticket?.status) && (
                <form onSubmit={handleUpdate}>
                    <div className="relative">
                        <InputList.InputTextarea defaultValue={""} required={true} id="answer" />
                        <label className="file-label" onClick={handleOpenFileInput}>
                            <img src={attach} alt="attach" /> Piece jointe
                        </label>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileInputChange}
                            accept=".jpg, .jpeg, .png, .gif"
                        />
                    </div>

                    {selectedImages?.length > 0 && (
                        <div className="selected-images-container">
                            <div className="selected-images">
                                {selectedImages.map((imageData, index) => (
                                    <div key={index} className="image-container">
                                        <i
                                            onClick={() =>
                                                setSelectedImages((prevImages) =>
                                                    prevImages.filter(
                                                        (image) => image.imageData !== imageData.imageData
                                                    )
                                                )
                                            }
                                            className="far fa-trash"
                                        ></i>
                                        <img src={imageData?.imageData} alt={`Importation n${index}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <InputList.InputButton text="Envoyer" type={"submit"} />
                </form>
            )}

            {showImage !== false && (
                <div
                    className="image-zoom"
                    onClick={(e) => {
                        // check if the click is on the image or outside
                        if (e.target.classList.contains("image-zoom")) {
                            setShowImage(false);
                        }
                    }}
                >
                    <div className="image-zoom-content">
                        <img src={showImage} alt="zoom" />
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Ticket;
