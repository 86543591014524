import { createContext, useState, useContext } from "react";

const MainContext = createContext();

const MainProvider = ({ children }) => {
    const [customer, setCustomer] = useState({});
    const [token, setToken] = useState(undefined);

    const [invoices, setInvoices] = useState(null);
    const [callLogs, setCallLogs] = useState(null);

    const [telephoneSwitchboard, setTelephoneSwitchboard] = useState({});

    const [menu, setMenu] = useState(null);
    const [profile, setProfile] = useState(null);

    const [music, setMusic] = useState([]);

    const updateTelephoneSwitchboard = (key, value) => {
        key.split(".").reduce((acc, cur, i, arr) => {
            if (i === arr.length - 1) {
                acc[cur] = value;
            }
            return acc[cur];
        }, telephoneSwitchboard);

        setTelephoneSwitchboard({ ...telephoneSwitchboard, [key]: value });

        const tab = [];
        customer.telephoneSwitchboard.map((item) => {
            if (item.id === telephoneSwitchboard.id) {
                return tab.push({ ...item, [key]: value });
            }
            return tab.push(item);
        });

        updateCustomer("telephoneSwitchboard", tab);
    };

    const updateCustomer = (key, value) => {
        setCustomer({ ...customer, [key]: value });
    };

    const changeTelephoneSwitchboard = (id) => {
        const telephoneSwitchboard = customer.telephoneSwitchboard.find((item) => item.id === id);
        setTelephoneSwitchboard(telephoneSwitchboard);

        setMenu(null);
        setProfile(null);
    };

    const value = {
        customer: {
            get: customer,
            set: setCustomer,
            update: updateCustomer,
        },

        token: {
            get: token,
            set: setToken,
        },

        invoices: {
            get: invoices,
            set: setInvoices,
        },

        callLogs: {
            get: callLogs,
            set: setCallLogs,
        },

        telephoneSwitchboard: {
            get: telephoneSwitchboard,
            set: setTelephoneSwitchboard,
            update: updateTelephoneSwitchboard,
            change: changeTelephoneSwitchboard,
        },

        menu: {
            get: menu,
            set: setMenu,
        },

        profile: {
            get: profile,
            set: setProfile,
        },

        music: {
            get: music,
            set: setMusic,
        },
    };

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export { MainProvider, MainContext };

const useMainContext = () => {
    const context = useContext(MainContext);

    if (!context) {
        throw new Error("useMainContext must be used within a MainProvider");
    }

    return context;
};

export default useMainContext;
