import api from "utils/api/api";

import { useQuery } from "react-query";

const GetPlanningOfCustomer = async (customer_uuid) => {
    try {
        const response = await api.get(`/api/v1/telephone-switchboard/planning/customer/${customer_uuid}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetPlanningOfCustomer = (customer_uuid) =>
    useQuery(["GetPlanningOfCustomer", customer_uuid], () => GetPlanningOfCustomer(customer_uuid), {
        enabled: !!customer_uuid,
    });

export { useGetPlanningOfCustomer, GetPlanningOfCustomer };
