import { Layout, HeadPage, Compte } from "components";

const InformationBancaires = () => {
    return (
        <Layout className="InfosBancaires">
            <HeadPage isProfil={false} title="Informations Bancaires" />
            <Compte.InformationsBancaires />
        </Layout>
    );
};

export default InformationBancaires;
