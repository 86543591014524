import React, { useState } from "react";
import ReactPaginate from "react-paginate";

import { useGetOutOfPackageRates } from "utils/api/boutique/package/GetOutOfPackageRates";

import { Layout, HeadPage, Table, InputList, Compte } from "components";

import "./Tarifs.scss";

const Tarifs = () => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const { data: outOfPackage } = useGetOutOfPackageRates({
        skip: (page - 1) * 10,
        limit: 10,
        search,
    });

    const listInclude = [
        {
            id: 0,
            text: "Toutes les destinations",
        },
        {
            id: 1,
            text: "Destinations incluses",
        },
        {
            id: 2,
            text: "Destinations hors forfait",
        },
    ];

    return (
        <Layout>
            <HeadPage isProfil={false} title="Tarifs">
                <div className="header-top">
                    <p>Filtrer par :</p>
                    <InputList.InputClassic
                        className={"input-classic"}
                        border={true}
                        placeholder="Rechercher..."
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <InputList.InputDropdown
                        classNameCustom={"input-dropdown"}
                        defaultValue={listInclude[0].text}
                        list={listInclude}
                    />
                </div>
            </HeadPage>

            <div className="header-info text-green-600">
                <p>
                    Les destinations en vert sont incluses dans votre forfait. Le tarif indiqué est valable lorsque vous
                    dépassez votre forfait
                </p>
            </div>

            <Table
                key={`table-${outOfPackage?.data}`}
                listHead={["Pays", "Tarif HT", "Destination incluse"]}
                options={{ isFixed: true }}
            >
                {outOfPackage?.data?.rates?.map((item, index) => {
                    return <Compte.Tarifs.TableRow key={item.id} data={item} />;
                })}
            </Table>
            {!search && (
                <ReactPaginate
                    nextLabel={<i className="fas fa-chevron-right"></i>}
                    onPageChange={(e) => setPage(e.selected + 1)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={outOfPackage?.data?.total / 10}
                    previousLabel={<i className="fas fa-chevron-left"></i>}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                />
            )}
        </Layout>
    );
};

export default Tarifs;
