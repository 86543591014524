import api from "utils/api/api";

import { useQuery } from "react-query";

const GetCustomerInfo = async () => {
    try {
        const response = await api.get("/api/v1/customer/info");
        const isStripeSepaRequired = window.localStorage.getItem('isStripeSepaRequired')
        if(Boolean(isStripeSepaRequired) && response?.status === 200) {
            window.localStorage.removeItem("isStripeSepaRequired")
            window.localStorage.removeItem("customer")
            window.location.href = "/"
        }
        window.localStorage.setItem("isAdmin", response?.data?.data?.isAdmin)
        window.localStorage.setItem("isCentrex", response?.data?.data?.isCentrex)
        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetCustomerInfo = () => {
    return useQuery("customerInfo", GetCustomerInfo, {
        staleTime: 0,
    });
};

export default useGetCustomerInfo;
export { GetCustomerInfo };
