import { useState } from "react";

import "./ModeRenvoi.scss";
import RenvoyerVers from "./RenvoyerVers/RenvoyerVers";

const ModeRenvoi = ({ update }) => {
    const [activeButton, setActiveButton] = useState("Cascade");
    const [renvoyerVersList, setRenvoyerVersList] = useState([{ name: "", number: "", priority: 0 }]);
    const [nextKey, setNextKey] = useState(1);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        let id = 1;

        switch (buttonName) {
            case "Cascade":
                id = 1;
                break;
            case "Simultané":
                id = 2;
                break;
            case "Aléatoire":
                id = 3;
                break;
            default:
                break;
        }

        update({
            redirect_type: id,
        });
    };

    const handleAddRenvoyerVers = () => {
        if (renvoyerVersList.length < 4) {
            setRenvoyerVersList([...renvoyerVersList, { name: "", number: "", priority: nextKey }]);
            setNextKey(nextKey + 1);
        }
    };

    const handleRemoveRenvoyerVers = (keyToRemove) => {
        setRenvoyerVersList(renvoyerVersList.filter((renvoyerVers) => renvoyerVers.priority !== keyToRemove));
    };

    const handleRenvoyerVersChange = (index, field, value) => {
        const updatedList = [...renvoyerVersList];
        updatedList[index][field] = value;

        setRenvoyerVersList(updatedList);

        update({
            redirect: updatedList,
        });
    };

    return (
        <div className="ModeRenvoi">
            <h2>Mode de renvoi</h2>
            <div className="ModeRenvoiSelect">
                <button
                    className={activeButton === "Cascade" ? "active red" : ""}
                    style={{
                        backgroundColor: activeButton === "Cascade" ? "#F74A53" : "",
                        color: activeButton === "Cascade" ? "#FFFFFF" : "",
                    }}
                    onClick={() => handleButtonClick("Cascade")}
                >
                    Cascade
                </button>
                <button
                    className={activeButton === "Simultané" ? "active" : ""}
                    style={{
                        backgroundColor: activeButton === "Simultané" ? "#F74A53" : "",
                        color: activeButton === "Simultané" ? "#FFFFFF" : "",
                    }}
                    onClick={() => handleButtonClick("Simultané")}
                >
                    Simultané
                </button>
                <button
                    className={activeButton === "Aléatoire" ? "active" : ""}
                    style={{
                        backgroundColor: activeButton === "Aléatoire" ? "#F74A53" : "",
                        color: activeButton === "Aléatoire" ? "#FFFFFF" : "",
                    }}
                    onClick={() => handleButtonClick("Aléatoire")}
                >
                    Aléatoire
                </button>
            </div>

            <div className="RenvoyerVersList">
                <h2>Renvoyer vers</h2>
                {renvoyerVersList?.map((renvoyerVers, index) => (
                    <RenvoyerVers
                        key={renvoyerVers.priority}
                        data={renvoyerVers}
                        onRemove={handleRemoveRenvoyerVers}
                        onChange={(field, value) => handleRenvoyerVersChange(index, field, value)}
                    />
                ))}
                {renvoyerVersList.length < 4 && (
                    <button className="ButtonAdd" onClick={handleAddRenvoyerVers}>
                        + Ajouter un nouveau numéro
                    </button>
                )}
            </div>

            <h3 style={{ paddingTop: "30px", fontWeight: "600" }}>
                Indiquez l’indicatif international lorsque vous ajoutez un numéro de renvoi (Exemple: +33 pour la France
                Métropolitaine).
            </h3>
        </div>
    );
};

export default ModeRenvoi;
