import React, { useEffect, useState } from "react";

import "./MenuMultichoix.scss";

import { Config, Layout, HeadPage, Table, Info, InputList } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import listKey from "utils/config/menumultichoix/key-list.json";

import { GetRedirectNumbers } from "utils/api/config/NumeroRenvoi/GetRedirectNumbers";
import { GetMenuByStandardNumberID } from "utils/api/config/menu/GetMenuByStandardNumberID";
import { AddItem } from "utils/api/config/menu/AddItem";
// import { EditItem } from "utils/api/config/menu/EditItem";
import { RemoveItem } from "utils/api/config/menu/RemoveItem";
import { DisableEnableMenu } from "utils/api/config/menu/DisableEnableMenu";
import { UpdateMenuWithItems } from "utils/api/config/menu/UpdateMenuWithItems";

const MenuMultichoix = () => {
    const { telephoneSwitchboard, customer, menu } = useMainContext();
    const { showMessage } = useMessageContext();

    const [isMenuActive, setIsMenuActive] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [selectedProfile, setSelectedProfile] = useState(null);

    const [list, setList] = useState([]);
    const [listProfile, setListProfile] = useState([]);

    const [listNumber, setListNumber] = useState([]);

    const listAction = [
        {
            id: 1,
            text: "Renvoi vers mes numéros",
        },
        {
            id: 2,
            text: "Diffuser une annonce vocale",
        },
    ];

    useEffect(() => {
        if (telephoneSwitchboard.get?.id === undefined) return;

        fetchMenu();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telephoneSwitchboard.get]);

    useEffect(() => {
        if (telephoneSwitchboard.get?.id === undefined) return;
        if (customer.get?.id === undefined) return;

        fetchRedirectNumber();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telephoneSwitchboard.get, customer.get]);

    useEffect(() => {
        if (selectedProfile === null) return;

        setListProfile(list[selectedProfile]);
        setIsMenuActive(list[selectedProfile]?.isActive);
    }, [list, selectedProfile]);

    const fetchRedirectNumber = async () => {
        const request = await GetRedirectNumbers({ customer_id: customer.get?.id });

        if (request?.success) {
            const tab = [];

            request?.data?.map((item) => {
                return tab.push({
                    id: item.id,
                    number: item.number,
                });
            });

            setListNumber(tab);
            return;
        }
    };

    const fetchMenu = async () => {
        const response = await GetMenuByStandardNumberID({
            standard_number_id: telephoneSwitchboard.get?.standard_number_id,
        });

        if (response?.success) {
            const tab = {};

            // transform this data : [[], []] to this data : {0: [], 1: []} and the key are the id of profile
            response?.data?.map((item, index) => {
                return (tab[item.id] = item.Menu[0]);
            });

            menu.set(tab);

            const key = Object.keys(tab)[0];

            setList(tab);
            setListProfile(tab[key]);
            setIsMenuActive(tab[key]?.isActive);
            setSelectedProfile(key);
        }
    };

    const handleChangeInput = async (keyWord, value, id) => {
        if (keyWord === "key") {
            // check if the key is already used
            const keyList = listProfile?.MenuItem?.find((item) => item.key === value);

            if (keyList) {
                showMessage({
                    type: "error",
                    text: "La touche est déjà utilisée",
                });

                return;
            }
        }

        handleUpdateItem(id, {
            [keyWord]: value,
        });
    };

    const handleAdd = async () => {
        let key = "";

        if (listProfile?.MenuItem?.length > 0) {
            const keyList = listProfile?.MenuItem?.map((item) => item.key);
            const keyMax = Math.max(...keyList);
            key = String(keyMax + 1);
        } else {
            key = "0";
        }

        showMessage({
            type: "info",
            text: "Modification en cours ...",
        });

        setIsLoading(true);

        const response = await AddItem({
            menu_id: listProfile?.id,
            item: {
                description: `Menu Item de ${customer.get.firstname}`,
                key,
                isPhoneRedirect: true,
            },
        });

        setIsLoading(false);

        if (response?.success) {
            const tab = list;

            tab[selectedProfile].MenuItem.push(response?.data);

            showMessage({
                type: "success",
                text: "Ajout réussi",
            });

            fetchMenu();

            return;
        }

        showMessage({
            type: "error",
            text: "Une erreur est survenue",
        });
    };

    const handleDelete = async (id) => {
        const item_id = listProfile?.MenuItem[id]?.id;

        showMessage({
            type: "info",
            text: "Modification en cours ...",
        });

        setIsLoading(true);

        const reponse = await RemoveItem({
            item_id,
        });

        setIsLoading(false);

        if (reponse?.success) {
            const tab = list;

            tab[selectedProfile].MenuItem.splice(id, 1);

            showMessage({
                type: "success",
                text: "Ajout réussi",
            });

            fetchMenu();

            return;
        }

        showMessage({
            type: "error",
            text: "Une erreur est survenue",
        });

        return;
    };

    const handleUpdateItem = async (id, data) => {
        const tab = list;

        const item = tab[selectedProfile].MenuItem[id];

        if (data.isPhoneRedirect === undefined) {
            tab[selectedProfile].MenuItem[id] = {
                ...item,
                ...data,
            };
        } else {
            if (data.isPhoneRedirect) {
                tab[selectedProfile].MenuItem[id] = {
                    ...item,
                    isPhoneRedirect: data.isPhoneRedirect,
                    MenuItem_RedirectNumber: data.redirectNumber,
                };
            } else {
                tab[selectedProfile].MenuItem[id] = {
                    ...item,
                    isPhoneRedirect: data.isPhoneRedirect,
                    MenuItem_AudioMessage: [
                        {
                            ...item.MenuItem_AudioMessage[0],
                            audio_message: { ...item.MenuItem_AudioMessage[0].audio_message, ...data.audioMessage },
                        },
                    ],
                };

                handleSave();
            }
        }

        setList(tab);

        return;
    };

    const activeMenu = async (value) => {
        showMessage({
            type: "info",
            text: value ? "Activation en cours ..." : "Désactivation en cours ...",
        });

        setIsLoading(true);

        const data = await DisableEnableMenu({
            menu_id: listProfile?.id,
            value,
        });

        setIsLoading(false);

        if (data?.success) {
            setIsMenuActive(value);
            return;
        }

        showMessage({
            type: "error",
            text: "Une erreur est survenue",
        });
    };

    const handleSave = async () => {
        const tab = list;

        const data = {
            id: listProfile?.id,
            items: [],
        };

        let isError = false;

        tab[selectedProfile].MenuItem.map((item) => {
            const itemData = {
                id: item.id,
                key: item.key,
                name: item.name,
                isPhoneRedirect: item.isPhoneRedirect,
            };

            if (item.isPhoneRedirect) {
                if (item.MenuItem_RedirectNumber.length === 0) {
                    isError = true;
                    return showMessage({
                        type: "error",
                        text: "Veuillez ajouter au moins un numéro de redirection pour la touche " + item.key,
                    });
                }

                itemData.redirectNumber = item.MenuItem_RedirectNumber;
            } else {
                const audioMessage = item.MenuItem_AudioMessage[0]?.audio_message;

                itemData.audioMessage = {
                    text: audioMessage.text,
                    voiceId: audioMessage.voiceId,
                    buffer: audioMessage.buffer,
                    isText: audioMessage.isText,
                };
            }

            return data.items.push(itemData);
        });

        if (isError) return;

        setIsLoading(true);

        showMessage({
            type: "info",
            text: "Modification en cours ...",
        });

        const request = await UpdateMenuWithItems({
            menu_id: data.id,
            items: data.items,
        });

        if (request?.success) {
            showMessage({
                type: "success",
                text: "Modification réussie",
            });

            fetchMenu();
        } else {
            showMessage({
                type: "error",
                text: "Une erreur est survenue",
            });
        }

        setIsLoading(false);
    };

    return (
        <Layout id="MenuMultiChoix" className="MenuMultiChoix">
            <HeadPage title="Menu MultiChoix" onChangeProfil={(e) => setSelectedProfile(e.id)} />
            {isMenuActive ? (
                <>
                    <Table listHead={["Touche", "Nom", "Action", ""]} options={{ isFixed: true }}>
                        {listProfile?.MenuItem?.sort((a, b) => a.key - b.key)?.map((item, index) => {
                            const data = {
                                ...item,
                                index: index,
                                canDelete: listProfile?.MenuItem?.length > 1,
                                handleChangeInput,
                                handleDelete,
                            };

                            return (
                                <Config.MenuMultiChoix.TableRow
                                    key={item?.id}
                                    data={data}
                                    handleUpdateItem={handleUpdateItem}
                                    listNumber={listNumber}
                                    listAction={listAction}
                                    listKey={listKey}
                                    isLoading={isLoading}
                                />
                            );
                        })}
                    </Table>

                    {!isLoading && listProfile?.MenuItem?.length < 5 && (
                        <p onClick={() => handleAdd()} className="AddKey onActive onHover pt-3 text-base">
                            + Ajouter une nouvelle touche
                        </p>
                    )}

                    <InputList.InputButton
                        className="SaveButton"
                        text="Sauvegarder"
                        onClick={() => handleSave()}
                        isActive={!isLoading}
                        style={{ marginRight: "20px" }}
                    />

                    <InputList.InputButton
                        className="DeleteButton"
                        text="Supprimer le menu"
                        onClick={() => activeMenu(false)}
                        isActive={!isLoading}
                    />
                </>
            ) : (
                <Info className="Info" title="Redirigez vos clients vers le bon service ou le bon interlocuteur">
                    <p>
                        Modifiez votre message d’accueil si vous activez le menu multichoix afin que vos clients
                        puissent sélectionner les touches 1, 2, 3
                    </p>
                    <p>
                        (Exemple: Pour joindre le service commercial, tapez 1, pour joindre le service technique, tapez
                        2)
                    </p>
                    <InputList.InputButton
                        text="ACTIVER LE MENU MULTICHOIX"
                        onClick={() => activeMenu(true)}
                        isActive={!isLoading}
                    />
                    <p className="Warning">
                        <i className="far fa-exclamation-triangle" />
                        <strong>ATTENTION</strong>
                    </p>
                    <p>
                        <strong>
                            Si vous activez le menu vocal, n'oubliez pas de modifier votre annonce d'accueil pour
                            prévenir vos clients qu'ils doivent appuyer sur la touche 1, 2, 3...
                        </strong>
                    </p>
                </Info>
            )}
        </Layout>
    );
};

export default MenuMultichoix;
