import StudioVocalLayout from "./StudioVocalLayout";
import MP3 from "./MP3";
import SyntheseVocal from "./SyntheseVocal";
import FondMusical from "./FondMusical";

const StudioVocal = {
    MP3,
    SyntheseVocal,
    FondMusical,
    StudioVocalLayout,
};

export default StudioVocal;
