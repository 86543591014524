import "./CreditsCard.scss";

import { InputList } from "components";

import check from "assets/icons/check.svg";
import checkwhite from "assets/icons/check-white.svg";

const CreditsCard = ({ data, index, loadPopup }) => {
    return (
        <div className={`credit-card${index === 2 ? " credit-card-blue" : ""}`}>
            <div className="card-header">
                <h3>{data.price} €</h3>
                <span>de crédits</span>
            </div>
            <div className="card-body">
                <div className="offer">
                    <img src={index === 2 ? checkwhite : check} alt="picto" loading="lazy" />
                    <p>{data.minutes} minutes</p>
                </div>

                <div className="separator">
                    <div className="separator-left" />
                    <p>ou</p>
                    <div className="separator-right" />
                </div>

                <div className="offer">
                    <img src={index === 2 ? checkwhite : check} alt="picto" loading="lazy" />
                    <p>{data.sms} sms</p>
                </div>
                <div className="separator">
                    <div className="separator-left"></div>
                    <p>ou</p>
                    <div className="separator-right"></div>
                </div>

                <div className="offer">
                    <img src={index === 2 ? checkwhite : check} alt="picto" loading="lazy" />
                    <p>10 appels télésecretariat</p>
                </div>
                <div className="separator">
                    <div className="separator-left"></div>
                    <p>ou</p>
                    <div className="separator-right"></div>
                </div>

                <div className="offer">
                    <img src={index === 2 ? checkwhite : check} alt="picto" loading="lazy" />
                    <p>10 push vocaux</p>
                </div>
            </div>
            <div className="card-footer">
                <InputList.InputButton text="Acheter" onClick={loadPopup} />
            </div>
        </div>
    );
};

export default CreditsCard;
