import api from "utils/api/api";

const CreateCustomerTicket = async (ticket) => {
    try {
        const response = await api.post(`/api/v1/zendesk/create-ticket`, ticket);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { CreateCustomerTicket };
