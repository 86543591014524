import { createContext, useState, useContext } from "react";

export const MessageContext = createContext(null);

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState({
        isShow: false,
        text: "",
        type: "info",
        updatedAt: new Date(),
    });

    return <MessageContext.Provider value={{ message, setMessage }}>{children}</MessageContext.Provider>;
};

const useMessageContext = () => {
    const context = useContext(MessageContext);

    if (context === undefined) {
        throw new Error("useMessageContent must be used within a MessageProvider");
    }

    const { message, setMessage } = context;

    const showMessage = ({ text, type }) => {
        setMessage({
            isShow: true,
            text,
            type,
            updatedAt: new Date(),
        });
    };

    const hideMessage = () => {
        setMessage({
            isShow: false,
            text: "",
            type: "info",
        });
    };

    return {
        message,
        showMessage,
        hideMessage,
    };
};

export default useMessageContext;
