import { InputList } from "components";

import "./TableRow.scss";

const TableRow = ({ data }) => {
    const { canDelete, handleChangeInput, handleChangePriority, handleChangeName } = data;

    const handleChangeActive = (value) => handleChangeInput("isActive", value, data.index);
    const handleChangePhone = (value) => handleChangeInput("number", value.target.value, data.index);

    return (
        <tr>
            <td>
                <InputList.InputToggle defaultValue={data.isActive} onChange={handleChangeActive} />
            </td>
            <td>
                <div className="RedirectNumberInput">
                    <InputList.InputClassic defaultValue={data.number} onChange={handleChangePhone} isPhone />
                    {data.prefix &&
                        (data?.prefix?.isEnable ? (
                            !data?.prefix?.isInclude && (
                                <p>
                                    {Number(data?.prefix?.tarif)?.toFixed(2)}€/min
                                    <br /> de hors forfait
                                </p>
                            )
                        ) : (
                            <p>Destination non autorisée</p>
                        ))}
                </div>
            </td>
            <td>
                <InputList.InputClassic
                    defaultValue={data.name}
                    onChange={(value) => handleChangeName(value.target.value, data.index)}
                />
            </td>
            <td>
                {canDelete && (
                    <InputList.InputButton
                        text={<i style={{ fontSize: "15px" }} className="fad fa-trash-alt"></i>}
                        isSquare
                        onClick={() => data.handleDelete(data.index)}
                    />
                )}

                {data.canUp && (
                    <InputList.InputButton
                        text={<i style={{ fontSize: "15px" }} className="fad fa-arrow-up"></i>}
                        isSquare
                        onClick={() => handleChangePriority(data.index, "up")}
                    />
                )}

                {data.canDown && (
                    <InputList.InputButton
                        text={<i style={{ fontSize: "15px" }} className="fad fa-arrow-down"></i>}
                        isSquare
                        onClick={() => handleChangePriority(data.index, "down")}
                    />
                )}
            </td>
        </tr>
    );
};

export default TableRow;
