import "./HoraireOuverture.scss";
import { Config } from "components";
import { PatchHorairesOuvertures } from "utils/api/config/HorairesOuverture/PatchHorairesOuverture";
import { useGetPlanningOfCustomer } from "utils/api/config/HorairesOuverture/GetPlanningOfCustomer";
import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

const HoraireOuverture = ({ handleNext }) => {
    const { showMessage } = useMessageContext();
    const { customer } = useMainContext();

    const { data: planning } = useGetPlanningOfCustomer(customer.get?.id);

    const saveToDB = async (data) => {
        const planningUpdate = {};

        if (!planning?.data) return;

        await Promise.all(
            planning?.data?.map((item) => {
                const days = data?.days?.filter((day) => day.profile_id === item.profile_id);
                // remove profile_id from days
                days.map((day) => delete day.profile_id);

                if (planningUpdate[item.id]) {
                    return (planningUpdate[item.id] = [...planningUpdate[item.id], ...days]);
                } else {
                    return (planningUpdate[item.id] = [...days]);
                }
            })
        );

        // get all keys of planningUpdate and loop through them
        const keys = Object.keys(planningUpdate);

        await Promise.all(
            keys.map(async (key) => {
                const item = planningUpdate[key];

                const request = await PatchHorairesOuvertures(
                    {
                        name: data.name,
                        days: item,
                    },
                    key
                );

                if (!request.success) {
                    showMessage({
                        type: "error",
                        text: "La modification n'a pas été prise en compte.",
                    });

                    return Promise.reject();
                }

                showMessage({
                    type: "success",
                    text: "La modification a été prise en compte.",
                });
                return handleNext();
            })
        );

        handleNext();
    };

    return (
        <div className="HoraireOuverture">
            <h3>Horaire d'ouverture</h3>
            <Config.HorairesOuverture.HorairesOuverture data={planning?.data} onSave={saveToDB} />
        </div>
    );
};

export default HoraireOuverture;
