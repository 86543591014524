import { useState } from "react";

import "./StudioVocalLayout.scss";

import Skeleton from "react-loading-skeleton";

import { InputList } from "components";

import useMainContext from "contexts/MainContext";

const StudioVocalLayout = ({ id, title, list, handleChangeToogle, defaultToogle }) => {
    const [cat, setCat] = useState(list[0]?.id);

    const { customer } = useMainContext();

    const handleCatChange = (value) => {
        if (value === cat) {
            return;
        }

        const element = document.querySelector(".StudioAnim");
        element.style.transform = cat < value ? "translateX(-100%)" : "translateX(100%)";
        element.style.opacity = "0";

        setTimeout(() => {
            element.style.transform = cat < value ? "translateX(100%)" : "translateX(-100%)";
        }, 150);

        setTimeout(() => {
            element.style.transform = "translateX(0%)";
            element.style.opacity = "1";
            setCat(value);
        }, 300);
    };

    return (
        <div id={id || "StudioContainer"} className="StudioContainer">
            {title && (
                <div className="pt-5 pl-1 w-full justify-between flex">
                    <p>{title}</p>
                    <div className="flex items-center justify-between">
                        {customer.get?.id ? (
                            <>
                                <p className="pr-3">Activer</p>
                                <InputList.InputToggle onChange={handleChangeToogle} defaultValue={defaultToogle} />
                            </>
                        ) : (
                            <Skeleton variant="rectangular" width="75px" height={35} />
                        )}
                    </div>
                </div>
            )}

            <div className="StudioBody">
                <div className="StudioButton">
                    {list?.map((x) => {
                        return (
                            <button
                                key={x.id}
                                className={`${cat === x.id ? "StudioButtonSelected" : "unselectedButton"}`}
                                onClick={() => handleCatChange(x.id)}
                                type="button"
                            >
                                {x.text}
                            </button>
                        );
                    })}
                </div>

                <div className="StudioContent">
                    <div className="StudioAnim">
                        {customer.get?.id ? (
                            list?.map((x) => {
                                if (x.id === cat) {
                                    return x.component;
                                }
                                return null;
                            })
                        ) : (
                            <Skeleton variant="rectangular" width="100%" height="300px" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudioVocalLayout;
