const InputDouble = ({
    label,
    id,
    placeholder,
    defaultValue,
    wCustom,
    className,
    type,
    required,
    onChange,
    indicatif,
}) => {
    return (
        <div className={`flex flex-col py-2 ${wCustom || "w-full"} ${className || ""}`}>
            {label && (
                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={id}>
                    {label}
                </label>
            )}
            <div className="flex flex-row items-center border-2 rounded p-1">
                <span className="border-r-2 pr-1 pl-1 font-medium w-20">{indicatif}</span>
                <input
                    maxLength={10}
                    id={id}
                    type={type || "text"}
                    value={defaultValue}
                    placeholder={placeholder}
                    required={required || false}
                    className="px-2 "
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
        </div>
    );
};

export default InputDouble;
