import { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import "./Dropdown.scss";

const InputDropdown = ({
    defaultValue,
    list,
    wCustom,
    classNameCustom,
    word,
    id,
    mlCustom,
    onChange,
    obj,
    isDisable = false,
}) => {
    const [dropdown, setDropdown] = useState(false);
    const [select, setSelect] = useState(defaultValue || null);

    const handleChange = (value) => {
        setDropdown(false);
        onChange && onChange(obj ? value[word] : value);
    };

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (!e.target.closest(".input-dropdown")) {
                setDropdown(false);
            }
        });
    }, []);

    return (
        <div className={`dropdown select ${wCustom || "w-32"} ${classNameCustom || ""} input-dropdown`}>
            {defaultValue ? (
                <>
                    <button
                        onClick={() => !isDisable && setDropdown(!dropdown)}
                        type="button"
                        className="sm:text-sm "
                        aria-haspopup="listbox"
                        aria-expanded="true"
                        aria-labelledby="listbox-label"
                    >
                        <div className="select-filter ">
                            <span id={id || ""} className={`${mlCustom || "ml-3"}`}>
                                {select}
                            </span>
                        </div>

                        <span className="icon">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </button>

                    <ul
                        style={dropdown ? { display: "block" } : { display: "none" }}
                        className="shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 sm:text-sm"
                        tabIndex={-1}
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                    >
                        {list?.map((x, i) => {
                            return (
                                <li
                                    key={i}
                                    onClick={() => {
                                        setSelect(x[word || "text"]);
                                        handleChange(x);
                                    }}
                                    className="select-none"
                                    id="listbox-option-0"
                                    role="option"
                                    aria-selected="true"
                                >
                                    <div className="flex items-center">
                                        <span className={`${mlCustom || "ml-3"} font-normal block truncate`}>
                                            {x[word || "text"]}
                                        </span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </>
            ) : (
                <Skeleton variant="rectangular" style={{ marginTop: 30 }} className={"red"} height={40} />
            )}
        </div>
    );
};

export default InputDropdown;
