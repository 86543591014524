import api from "utils/api/api";

import { useMutation } from "react-query";

const DeleteCard = async ({ card_id }) => {
    try {
        const response = await api.post("/api/v1/payment/delete-card", { card_id });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useDeleteCard = () => useMutation((card_id) => DeleteCard({ card_id }));

export default useDeleteCard;
export { DeleteCard };
