import PopupLayout from "./Layout";
import SelectImport from "./SelectImport";
import ListNumbers from "./ListNumbers";
import ImportedFile from "./ImportedFile";
import WriteMessage from "./WriteMessage";
import Validate from "./Validate";
import TableRow from "./ListNumbers/TableRow";
import PaymentFailed from "./PaymentFailed";

const Popup = {
    PopupLayout,
    SelectImport,
    ListNumbers,
    ListNumbersTableRow: TableRow,
    ImportedFile,
    WriteMessage,
    Validate,
    PaymentFailed,
};

export default Popup;
