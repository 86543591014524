import Skeleton from "react-loading-skeleton";

const InputTextarea = ({ id, onChange, defaultValue, placeholder, label, readOnly, required }) => {
    return (
        <>
            {typeof defaultValue === "string" ? (
                <>
                    {label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
                    <textarea
                        id={id || ""}
                        // key={defaultValue}
                        className="border-2 border-black-600 p-3 w-[100%]"
                        onChange={(e) => onChange && onChange(e.target.value)}
                        defaultValue={defaultValue || ""}
                        readOnly={readOnly || false}
                        placeholder={placeholder || ""}
                        required={required || false}
                    />
                </>
            ) : (
                <Skeleton variant="rectangular" width="100%" height={200} />
            )}
        </>
    );
};

export default InputTextarea;
