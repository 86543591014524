import api from "../api";

import { useQuery } from "react-query";

const GetVoicemailFile = async ({ call_id }) => {
    try {
        if (call_id === undefined) throw new Error("call_id is undefined");

        const response = await api.get(`/api/v1/voicemail/file/${call_id}`, {
            headers: {
                "Content-Type": "audio/wav",
                // download: 1,
            },
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetVoicemailFile = ({ call_id }) =>
    useQuery(["voicemailFile", call_id], () => GetVoicemailFile({ call_id }), {
        enabled: call_id !== undefined,
    });

export default useGetVoicemailFile;
export { GetVoicemailFile };
