import React from "react";

import "./TableRow.scss";

const TableRow = ({ data, handleDownload }) => {
    return (
        <tr className="hover:bg-sky-100">
            <td className="flex justify-center items-center">
                <i className="fas fa-file-alt"></i>
                <i style={{ width: "15px" }} className={data.icon}></i>
            </td>

            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <p>{new Date(data?.invoiceDate).toLocaleDateString()}</p>
            </td>

            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <p>{Number(data?.totalIncludingTax).toFixed(2)} €</p>
            </td>

            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {data.amountPaid === 0 ? (
                    <div className="flex justify-between w-full">
                        <p className="text-[#E82A29] w-[49%]">Impayée</p>
                        <hr className="w-[1%] h-[20px] bg-gray-200 " />
                        <div className="w-[49%] flex justify-center">
                            <button className="blue hover:bg-gray-200 rounded-md border-2 px-2 ">Payer</button>
                        </div>
                    </div>
                ) : (
                    <p className="text-[#56C541] w-fit ">Payée</p>
                )}
            </td>

            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <p className="pl-12 cursor-pointer" onClick={handleDownload}>
                    Télécharger
                </p>
            </td>
        </tr>
    );
};

export default TableRow;
