import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LocalStorageChecker = ({itemKey,itemValidValue}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkLocalStorage = () => {
      const itemValue = localStorage.getItem(itemKey);

      if (itemValue === null) return;

      if (itemValue !== itemValidValue) {
        navigate('/compte/information-bancaire/setup-stripe-sepa');
      }
    };

    checkLocalStorage();
  }, [navigate, itemKey, itemValidValue]);

  return null;
};

export default LocalStorageChecker;
