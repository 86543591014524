import { useQuery } from "react-query";

import api from "utils/api/api";

const GetAllCallsLogs = async ({ standard_number_id, skip, limit, search, filter, minDate, maxDate }) => {
    try {
        if (standard_number_id === undefined) throw new Error("standard_number_id is undefined");

        const response = await api.get(`/api/v1/call/${standard_number_id}`, {
            params: {
                skip,
                limit,
                search,
                filter,
                minDate,
                maxDate,
            },
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetAllCallsLogs = ({ standard_number_id, skip, limit, search, filter, minDate, maxDate }) =>
    useQuery(
        ["callsLogs", standard_number_id, skip, limit, search, filter, minDate, maxDate],
        async () => await GetAllCallsLogs({ standard_number_id, skip, limit, search, filter, minDate, maxDate }),
        {
            enabled: standard_number_id !== undefined,
        }
    );

export { GetAllCallsLogs, useGetAllCallsLogs };
