import Factures from "./Factures";
import Coordonnees from "./Coordonnees";
import IdentifiantConnexion from "./IdentifiantConnexion";
import RechargeAutomatique from "./RechargeAutomatique";
import InformationsBancaires from "./InformationsBancaires";
import Tarifs from "./Tarifs";

const Compte = {
    Factures,
    Coordonnees,
    IdentifiantConnexion,
    RechargeAutomatique,
    Tarifs,
    InformationsBancaires,
};

export default Compte;
