import { Link } from "react-router-dom";

const SidebarPopup = ({ sub, handleClick }) => {
    return (
        <li key={sub.id} className="preventClose">
            <h4 className="preventClose">{sub.title}</h4>
            <ul className="PopupList preventClose">
                {sub.children.map((subsub, index) => {
                    return (
                        <Link onClick={handleClick} key={index} to={subsub.link}>
                            <li className="preventClose">{subsub.text}</li>
                        </Link>
                    );
                })}
            </ul>
        </li>
    );
};

export default SidebarPopup;
