// create context with react
import { createContext, useState, useContext } from "react";

// create context
export const PopupContext = createContext(null);

export const PopupProvider = ({ children }) => {
    const [popup, setPopup] = useState({
        isShow: false,
        content: null,
    });

    return <PopupContext.Provider value={{ popup, setPopup }}>{children}</PopupContext.Provider>;
};

const usePopupContext = () => {
    const context = useContext(PopupContext);

    if (context === undefined) {
        throw new Error("usePopupContent must be used within a PopupProvider");
    }

    const { popup, setPopup } = context;

    const showPopup = (content) => {
        setPopup({
            isShow: true,
            content,
        });
    };

    const hidePopup = () => {
        setPopup({
            isShow: false,
            content: null,
        });
    };

    return {
        popup,
        showPopup,
        hidePopup,
    };
};

export default usePopupContext;
