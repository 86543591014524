import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useCookies } from "react-cookie";

import check from "assets/picto/validate.svg";
// import invalid from "../../assets/picto/invalid.svg";

import "./Login.scss";
import { InputList, LoginHeader } from "components";

const Login = () => {
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie] = useCookies([process.env.REACT_APP_COOKIE_NAME]);
    const [handleError, setHandleError] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const [mail, setMail] = useState(["", false, false]);

    const sendForm = async (e) => {
        e.preventDefault();

        const email = e?.target?.email?.value;
        const password = e?.target?.password?.value;
        const remember = e?.target?.remember?.checked;

        let data = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                password,
            }),
        });

        const error = data.ok;
        data = await data.json();

        if (!error) {
            setHandleError([true, data.message]);
            return;
        }

        const days = remember
            ? new Date().setMonth(new Date().getMonth() + 1)
            : new Date().setDate(new Date().getDate() + 1);

        setCookie(process.env.REACT_APP_COOKIE_NAME, data.token, {
            path: "/",
            expires: new Date(days),
        });
    };

    return (
        <div>
            <LoginHeader />
            <div className="login">
                <div className="login-form">
                    <h1>Espace client</h1>

                    <form id="login-form" action="#" method="POST" onSubmit={sendForm}>
                        {handleError[0] && (
                            <div
                                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                role="alert"
                            >
                                <strong className="font-bold">Erreur ! </strong>
                                <span className="block sm:inline">{handleError[1]}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                    <svg
                                        onClick={() => {
                                            setHandleError([]);
                                        }}
                                        className="fill-current h-6 w-6 text-red-500"
                                        role="button"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <title>Fermer</title>
                                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                    </svg>
                                </span>
                            </div>
                        )}

                        <p className="label">Email</p>
                        <div className="input">
                            <InputList.InputClassic
                                type="email"
                                name="email"
                                // value={mail[0]}
                                autoComplete="email"
                                required
                                onChange={(e) =>
                                    setMail([
                                        e.target.value,
                                        e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
                                    ])
                                }
                                border={false}
                            />
                            {mail[1] && <img alt="check" src={check} loading="lazy" />}
                        </div>

                        <div className="error">
                            {mail[0].length >= 1 && !mail[1] && <p className="error">L'email est incorrect</p>}
                        </div>

                        <p className="label">Mot de passe</p>
                        <div className="input">
                            <InputList.InputClassic
                                type={showPassword ? "text" : "password"}
                                name="password"
                                // value={(pass[0], password)}
                                required
                                icon={
                                    <i
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        className={`fas fa-eye${showPassword ? "-slash" : ""}`}
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            color: isHovered ? "#3d79b5" : "#264a6e",
                                        }}
                                    ></i>
                                }
                                // onChange={(e) => {
                                //     setPass([e.target.value, true]);
                                // }}
                                border={false}
                            />
                            {/* {pass[1] && <img alt="check" src={check} loading="lazy" />} */}
                        </div>

                        <div
                            className="checkbox"
                            style={{
                                alignItems: "center",
                                display: "flex",
                                width: "50%",
                            }}
                        >
                            <input
                                type="checkbox"
                                name="remember"
                                id="remember"
                                // checked={rememberMe}
                                // onChange={() => setRememberMe(!rememberMe)}
                                style={{ marginRight: "5px" }}
                            />
                            <label htmlFor="remember">Se souvenir de moi</label>
                        </div>

                        {/* <GoogleReCaptcha
                            refreshReCaptcha={refreshReCaptcha}
                            onVerify={(token) => captcha !== true && onVerify(token)}
                        /> */}

                        <NavLink to="/forgot-password" href="./">
                            <p className="link">Mot de passe oublié ?</p>
                        </NavLink>

                        <button type="submit">Connexion</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
