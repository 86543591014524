import api from "utils/api/api";

import { useMutation } from "react-query";

const AddCustomerBankAccount = async ({ iban, account_holder_name }) => {
    try {
        if (iban === undefined || account_holder_name === undefined) {
            throw new Error("Missing parameters");
        }

        const response = await api.post("/api/v1/sepa/bank-account", {
            iban,
            account_holder_name,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useAddCustomerBankAccount = () => {
    return useMutation("addCustomerBankAccount", AddCustomerBankAccount);
};

export default useAddCustomerBankAccount;
export { AddCustomerBankAccount };
