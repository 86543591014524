import { useState } from "react";
import { Link } from "react-router-dom";

import "./Support.scss";

import { HeadPage, InputList, Layout, Table, Popup } from "components";

import progress from "assets/icons/in-progress.svg";
import history from "assets/icons/history.svg";
// import validate from "assets/picto/validate.svg";

import useGetCustomerTickets from "utils/api/zendesk/GetCustomerTickets";

import SupportPopup from "components/Support/Popup/Popup";

const Support = () => {
    const { data: customerTickets, refetch: refetchCustomerTickets } = useGetCustomerTickets();
    const tickets = customerTickets?.data?.tickets;

    // status list : new, open, pending, hold, solved, closed
    const [selectedType, setSelectedType] = useState(["new", "open", "pending", "hold"]);
    const [searchQuery, setSearchQuery] = useState("");

    const [showPopup, setShowPopup] = useState(false);

    // type (ticket.status) : string[]
    const countTicketsByType = (type) => {
        return tickets?.filter((ticket) => type.includes(ticket.status)).length;
    };

    const filteredTickets = tickets?.filter((ticket) => {
        return (
            selectedType.includes(ticket.status) &&
            (ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
                ticket.description.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });

    const formatDate = (date) => {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat("fr", { year: "numeric" }).format(d);
        const mo = new Intl.DateTimeFormat("fr", { month: "short" }).format(d);
        const da = new Intl.DateTimeFormat("fr", { day: "2-digit" }).format(d);
        return `${da} ${mo} ${ye}`;
    };

    const formatHour = (date) => {
        const d = new Date(date);
        const options = { hour: "2-digit", minute: "2-digit" };
        const heureFormatee = d.toLocaleTimeString("fr-FR", options);
        return heureFormatee;
    };

    return (
        <Layout className="support">
            <HeadPage title="Support" isProfil={false} className="flex justify-between">
                <InputList.InputButton text="Nouveau ticket" onClick={() => setShowPopup(true)} />
            </HeadPage>
            <div className="selector">
                <div className="selector-left">
                    <div
                        className={`type ${selectedType === "new" ? "selected" : ""}`}
                        onClick={() => setSelectedType(["new", "open", "pending", "hold"])}
                    >
                        <img src={progress} alt="progress" /> En cours{" "}
                        <span>{countTicketsByType(["new", "open", "pending", "hold"])}</span>
                    </div>
                    <div
                        className={`type ${selectedType === "solved" ? "selected" : ""}`}
                        onClick={() => setSelectedType(["solved", "closed"])}
                    >
                        <img src={history} alt="history" /> Historique{" "}
                        <span>{countTicketsByType(["solved", "closed"])}</span>
                    </div>
                </div>
                <div className="selector-right">
                    <p>Rechercher:</p>{" "}
                    <InputList.InputClassic onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} />
                </div>
            </div>
            <Table listHead={["Nom", "Date", "Action"]}>
                {filteredTickets
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.map((ticket) => {
                        return (
                            <tr key={ticket.id}>
                                <td>{ticket.subject}</td>
                                <td>
                                    {formatDate(ticket.created_at)} à {formatHour(ticket.created_at)}
                                </td>
                                <td>
                                    <Link to={`/support/ticket/${ticket.id}`}>
                                        <i className="far fa-eye mr-2"></i>
                                    </Link>
                                    <i className="far fa-trash"></i>
                                </td>
                            </tr>
                        );
                    })}
            </Table>

            {showPopup && (
                <Popup.PopupClassic onClose={() => setShowPopup(false)}>
                    <SupportPopup
                        onFinish={() => {
                            refetchCustomerTickets();
                            setShowPopup(false);
                        }}
                    />
                </Popup.PopupClassic>
            )}
        </Layout>
    );
};

export default Support;
