import React, { useEffect, useState } from 'react';

import PaymentSetupForm from './PaymentSetupForm';
import { createSetupIntent } from 'utils/api/stripe';


const SetupStripeSepa = () => {
    const [clientSecret, setClientSecret] = useState(null)
    useEffect(()=>{
        const getClientSecret = ()=>{
          createSetupIntent('sepa_debit').then(res=>{
              setClientSecret(res.data.client_secret);
            })
        }
        getClientSecret();
    },[])
  return (
      <>{clientSecret !== null ? (<PaymentSetupForm clientSecret={clientSecret} />): null}</>
  )
}

export default SetupStripeSepa