import Credits from "./Credits";
import EnvoyerSMS from "./EnvoyerSMS";
import Option from "./Option";

const Boutique = {
    Credits,
    EnvoyerSMS,
    Option,
};

export default Boutique;
