import { useQuery } from "react-query";

import api from "../api";

const GetConsumptionForStandardNumber = async ({ standard_number_id }) => {
    try {
        const response = await api.get(`/api/v1/consumption/${standard_number_id}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetConsumptionForStandardNumber = ({ standard_number_id }) =>
    useQuery(
        ["Consumption", standard_number_id],
        async () => await GetConsumptionForStandardNumber({ standard_number_id }),
        {
            enabled: standard_number_id !== undefined,
        }
    );

export default useGetConsumptionForStandardNumber;
export { GetConsumptionForStandardNumber };
