import { useQuery } from "react-query";

import api from "utils/api/api";

const GetAllCards = async ({ customer_id }) => {
    try {
        const response = await api.get(`/api/v1/payment/customer/${customer_id}/list-cards`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetAllCards = ({ customer_id }) =>
    useQuery(["getAllCards", customer_id], async () => await GetAllCards({ customer_id }), {
        enabled: customer_id !== undefined,
        staleTime: 0,
    });

export default useGetAllCards;
export { GetAllCards };
