import ConsoCard from "./ConsoCard";
import NombreAppel from "./NombreAppel";

const ConsoComponent = () => {
    return (
        <div>
            <ConsoCard />
            <NombreAppel />
        </div>
    );
};

export default ConsoComponent;
