import React from "react";
import { Layout, Config, HeadPage } from "components";

const Repondeur = () => {
    return (
        <Layout>
            <HeadPage isProfil={false} title="Répondeur" />
            <Config.Repondeur />
        </Layout>
    );
};

export default Repondeur;
