import "./Card.scss";

import { InputList } from "components";

const Card = ({ amount, isActive, onClick }) => {
    return (
        <div className={`${isActive ? "RechargeActive" : ""} RechargeCard`}>
            <p>{amount}€</p>
            <InputList.InputButton text="Valider" className="w-[100%]" onClick={onClick} />
        </div>
    );
};

export default Card;
