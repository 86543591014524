import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";

import ConfirmPayment from "utils/api/boutique/payment/ConfirmPayment";

import AddCredits from "utils/api/boutique/payment/AddCredits";
import AddOption from "utils/api/script/AddOption";
import NewPorta from "utils/api/boutique/payment/NewPorta";

import "./CheckoutForm.scss";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

const CheckoutForm = ({ close, standard_number, credit_package_id, option, porta }) => {
    const { telephoneSwitchboard } = useMainContext();
    const { showMessage } = useMessageContext();

    const stripe = useStripe();
    const elements = useElements();
    const [text, setText] = useState("Payer");
    const [error, setError] = useState({ error: false, message: "" });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError({ error: false, message: "ok" });
        setText([<i className="far fa-spinner fa-spin flex items-center" />, "Paiement en cours..."]);

        if (!stripe || !elements) {
            return;
        }
        // if (!standard_number || !credit_package_id || !option) {
        //     return;
        // }

        // create payment method
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });

        if (error) {
            console.log("[error]", error);
            setText("Payer");
            setError({ error: true, message: error.message });
            showMessage({ type: "error", text: error.message });
            return;
        }

        let result;

        if (option) {
            result = await AddOption({
                standard_number,
                option_id: option.id,
                payment_method_id: paymentMethod.id,
            });
        } else if (porta) {
            result = await NewPorta({
                standard_number,
                payment_method_id: paymentMethod.id,
            });
        } else {
            result = await AddCredits({
                standard_number,
                credit_package_id,
                isRecharge: false,
                payment_method_id: paymentMethod.id,
            });
        }

        if (!result.success) {
            setText("Payer");
            setError({ error: true, message: "Une erreur est survenue lors du paiement, veuillez réessayer" });
            showMessage({ type: "error", text: "Une erreur est survenue lors du paiement, veuillez réessayer" });
            return;
        }

        if (result?.data?.requires_action) {
            // confirm payment intent with payment method
            const { paymentIntent, error } = await stripe.confirmCardPayment(result.data?.payment_intent_client_secret, {
                payment_method: paymentMethod.id,
            });

            if(error) {
                setText("Payer");
                setError({ error: true, message: error.message });
                showMessage({ type: "error", text: error.message });
                return;
            }

            switch (paymentIntent.status) {
                case "succeeded":
                    const confirmPayment = await ConfirmPayment({
                        payment_id: result.data?.payment_id,
                        script_id: telephoneSwitchboard?.get?.id,
                    });
            
                    if (confirmPayment.success) {
                        showMessage({ type: "success", text: "Paiement effectué avec succès" });
                        close(confirmPayment);
                    } else {
                        showMessage({ type: "error", text: "Une erreur est survenue lors de la confirmation du paiement" });
                        close(confirmPayment);
                    }
                    break;

                case "requires_payment_method":
                    showMessage({ type: "error", text: "Veuillez choisir un autre moyen de paiement"})
                    close(confirmPayment)
                    break;

                case "requires_confirmation":
                    showMessage({ type: "error", text: "Veuillez confirmer votre paiement"})
                    close(confirmPayment)
                    break;
                        
                default:
                    break;
            }
        }else if (result?.data?.data?.isSuccess) {
            const confirmPayment = await ConfirmPayment({
                payment_id: result.data?.payment_id,
                script_id: telephoneSwitchboard?.get?.id,
            });
            if (confirmPayment.success) {
                showMessage({ type: "success", text: "Paiement effectué avec succès" });
                close(confirmPayment);
            } else {
                showMessage({ type: "error", text: "Une erreur est survenue lors de la confirmation du paiement" });
                close(confirmPayment);
            }
        }

        
    };

    const cardStyle = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#32325d",
                fontFamily: "Arial, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d",
                },
            },
            invalid: {
                fontFamily: "Arial, sans-serif",
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    return (
        <form onSubmit={handleSubmit} className="stripe-form">
            <CardElement options={cardStyle} className="card-element" />
            {error.error && (
                <div className="error">
                    <p className="error">{error.message}</p>
                </div>
            )}
            <button type="submit" disabled={!stripe} className="bg-[#264a6e] text-white px-2 py-2 rounded-lg">
                <div className="flex justify-between">{text}</div>
            </button>
        </form>
    );
};

export default CheckoutForm;
