import React from "react";

import "./Reglages.scss";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import { HeadPage, Layout, Config } from "components";

import { PatchScript } from "utils/api/config/reglages/PatchScript";

const Reglages = () => {
    const { telephoneSwitchboard } = useMainContext();

    const { showMessage } = useMessageContext();

    const handleUpdate = async (value) => {
        if (value.key && value.value !== null) {
            const request = await PatchScript({
                script_id: telephoneSwitchboard.get?.script.id,
                data: { [value.key]: value.value },
            });

            if (!request.success) {
                showMessage({
                    type: "error",
                    text: "Une erreur est survenue lors de la modification du script",
                });
                return;
            }

            showMessage({
                type: "success",
                text: "La modification a bien été prise en compte",
            });

            const response = await request.data;

            telephoneSwitchboard.update("script", response);

            return;
        }
    };

    return (
        <Layout>
            <HeadPage isProfil={false} title="Réglages" />
            <Config.Reglages
                key={telephoneSwitchboard.get?.script?.id}
                script={telephoneSwitchboard.get?.script}
                handleUpdate={handleUpdate}
            />
        </Layout>
    );
};

export default Reglages;
