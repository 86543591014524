import api from "../api";

const SuccessCheckoutSessionUnPaid = async (customer_id, standard_number_id) => {
    try {
        const response = await api.post(`/api/v1/payment/checkout-session-completed-unpaid`, {
            customer_id,
            standard_number_id,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export default SuccessCheckoutSessionUnPaid;
