import React, { useEffect, useState } from "react";

import DefaultTimeTable from "utils/config/horaires/default-timetable.json";
import _ from "lodash";

import { Config } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import "./HorairesOuverture.scss";

const HorairesOuverture = ({ onSave, data, saveWithEvent, update }) => {
    const { telephoneSwitchboard } = useMainContext();
    const { showMessage } = useMessageContext();

    const hours = 24;
    const minutesSeparator = 5;
    const heightHours = (60 / minutesSeparator) * 8;
    const oneHour = (60 / minutesSeparator) * 1;

    const min = 0;
    const max = (60 / minutesSeparator) * hours; // 24h

    const [list, setList] = useState([]);

    useEffect(() => {
        if (data) {
            getSchedule();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, telephoneSwitchboard?.get?.id]);

    const getSchedule = async () => {
        const defaultList = DefaultTimeTable;

        let newList = [];

        await Promise.all(
            data?.map(async (planning) => {
                await Promise.all(
                    planning?.Planning_OpeningDays?.map((item) => {
                        // convert day like {1, 2, 3} to [{id: 1, name: "L", isActive: true}, {id: 2, name: "M", isActive: true}, {id: 3, name: "M", isActive: true}]
                        const days = item.days?.map((item) => {
                            return defaultList.days.find((day) => day.id === item).id;
                        });

                        const hours = item.Planning_OpeningDaysHours.map((x) => {
                            const start = x.startHours.split(":");
                            const end = x.endHours.split(":");

                            const startInMinutes = parseInt(start[0]) * 60 + parseInt(start[1]);
                            const endInMinutes = parseInt(end[0]) * 60 + parseInt(end[1]);

                            return {
                                id: item.id,
                                valStart: startInMinutes / minutesSeparator,
                                valEnd: endInMinutes / minutesSeparator,
                            };
                        });

                        return newList.push({
                            id: item.id,
                            days: defaultList.days.map((item) => {
                                return { ...item, isActive: days.includes(item.id) };
                            }),
                            hours,
                            profile_id: planning.profile_id,
                        });
                    })
                );
            })
        );

        setList(newList);
    };

    const convertToHours = (val) => {
        const hours = Math.floor(val / (60 / minutesSeparator));
        const minutes = (val % (60 / minutesSeparator)) * minutesSeparator;

        if (hours === 24) return "23:59";

        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    };

    const valueXOfElement = (e) => {
        let clientX = e.clientX;

        if (e.type === "touchmove") {
            clientX = e.touches[0].clientX;
        }

        const bar = document.querySelector("#TimelineBar");
        const { left, width } = bar.getBoundingClientRect();

        const value = Math.round(((clientX - left) / width) * max);

        return value;
    };

    const handleDrag = _.throttle((e, id, slotId, key) => {
        if (e.target.tagName === "P") {
            return;
        }
        const value = valueXOfElement(e);

        setList((current) => {
            const newList = current.map((slot) => {
                if (slot.id === slotId) {
                    slot.hours.map((hour) => {
                        if (hour.id === id) {
                            if (key === "start") {
                                if (value >= min) {
                                    hour.valStart = value < hour.valEnd ? value : hour.valEnd - 1;

                                    // check if end - start < 1
                                    if (hour.valEnd - value < oneHour) {
                                        hour.valEnd = value + oneHour;
                                        // check if end < max
                                        if (hour.valEnd > max) {
                                            hour.valEnd = max;
                                            hour.valStart = hour.valEnd - oneHour;
                                        }
                                    }
                                }
                            }

                            if (key === "end") {
                                if (value <= max) {
                                    hour.valEnd = value > hour.valStart ? value : hour.valStart + 1;
                                }

                                // check if start - end < 1
                                if (value - hour.valStart < oneHour) {
                                    hour.valStart = value - oneHour;
                                    // check if start > min
                                    if (hour.valStart < min) {
                                        hour.valStart = min;
                                        hour.valEnd = hour.valStart + oneHour;
                                    }
                                }
                            }
                        }
                        return hour;
                    });
                }
                return slot;
            });
            return newList;
        });
    }, 10);

    const handleChangeProfile = (slotId, profileId) => {
        const newList = list.map((slot) => {
            if (slot.id === slotId) {
                slot.profile_id = profileId;
            }
            return slot;
        });

        setList(newList);
    };

    const handleUpdateDay = (slotId, dayId) => {
        const newList = list.map((slot) => {
            if (slot.id === slotId) {
                slot.days.map((day) => {
                    if (day.id === dayId) {
                        if (day.isActive) {
                            day.isActive = false;
                        } else {
                            // check if day is already active in another slot
                            const isAlreadyActive = list
                                .filter((x) => x.profile_id === slot.profile_id)
                                .find((item) => {
                                    return item.days.find((day) => day.id === dayId && day.isActive);
                                });

                            if (!isAlreadyActive) {
                                day.isActive = true;
                            } else {
                                return showMessage({
                                    type: "error",
                                    text: "Vous ne pouvez pas ajouter deux fois le même jour sur un profil",
                                });
                            }
                        }
                    }
                    return day;
                });
            }
            return slot;
        });

        setList(newList);
    };

    const handleAddSlot = () => {
        setList((current) => {
            if (current.length >= 3) return current;
            const profile_id = current[0]?.profile_id || telephoneSwitchboard.get?.Profile[0]?.id;

            return [
                ...current,
                {
                    id: list[list?.length - 1]?.id + 1 || 1,
                    days: [
                        { id: 1, name: "L", isActive: false },
                        { id: 2, name: "M", isActive: false },
                        { id: 3, name: "M", isActive: false },
                        { id: 4, name: "J", isActive: false },
                        { id: 5, name: "V", isActive: false },
                        { id: 6, name: "S", isActive: false },
                        { id: 7, name: "D", isActive: false },
                    ],
                    hours: [{ id: 1, valStart: 100, valEnd: 150 }],
                    profile_id,
                },
            ];
        });
    };

    const handleRemoveSlot = (slotId) => {
        setList((current) => current.filter((item) => item?.id !== slotId));
    };

    const handleAddThumb = (e) => {
        const value = valueXOfElement(e);

        let valStart = value - (60 / minutesSeparator) * 2;
        let valEnd = value + (60 / minutesSeparator) * 2;

        if (e.target.children.length > 0) {
            const slotId = e.target.children[0].getAttribute("data-slotid");
            const slotInfo = list.find((item) => item.id === Number(slotId));

            if (slotInfo === undefined) {
                return;
            }

            const foundStart = slotInfo.hours.find((item) => item.valEnd > valStart && item.valEnd < valEnd);

            if (foundStart) {
                valStart = foundStart.valEnd + 1;
                valEnd = valStart + (60 / minutesSeparator) * 4;
            }

            const foundEnd = slotInfo.hours.find((item) => item.valStart < valEnd && item.valStart > valStart);

            if (foundEnd) {
                valEnd = foundEnd.valStart - 1;
            }

            setList((current) => {
                const newList = [...current];

                const slotIndex = newList.findIndex((item) => item.id === Number(slotId));

                newList[slotIndex].hours.push({
                    id: newList[slotIndex].hours.length + 1,
                    valStart: valStart < min ? min : valStart,
                    valEnd: valEnd > max ? max : valEnd,
                });

                return newList;
            });
        } else {
            setList((current) => {
                const newList = [...current];

                const slotIndex = newList.findIndex((item) => item.id === Number(e.target.getAttribute("data-slotid")));

                newList[slotIndex].hours.push({
                    id: newList[slotIndex].hours.length + 1,
                    valStart,
                    valEnd,
                });

                return newList;
            });
        }
    };

    const handleRemoveThumb = (slot_id, time_id) => {
        setList((current) => {
            const newThumbs = current.map((item) => {
                if (item.id === slot_id) {
                    item.hours = item.hours.filter((item) => item.id !== time_id);
                }
                return item;
            });
            return newThumbs;
        });
    };

    const handleDoubleClick = (e, slot_id, hours_id) => {
        if (e.target.classList.contains("Wrist") || e.target.classList.contains("line")) {
            return;
        }

        if (e.target.classList.contains("Timeline")) {
            handleRemoveThumb(slot_id, hours_id);
        } else {
            handleAddThumb(e);
        }
    };

    const handleResetSlot = (slotId) => {
        setList((current) => {
            const newList = current.map((item) => {
                if (item.id === slotId) {
                    item.days = [...DefaultTimeTable.days];
                    item.hours = [
                        {
                            ...DefaultTimeTable.hours[0],
                            valEnd: heightHours,
                        },
                    ];
                }
                return item;
            });
            return newList;
        });
    };

    const saveDataToDB = async () => {
        /**
         * Convert the list to json like this :
         * {
         * "name": "string",
         * "profile_id": 1,
         * "days": [
         * {
         * "name": "string",
         * days: [1,2,3,4,5,6,7],
         * "hours": [
         * {
         *   startHours: "08:30",
         *  endHours: "09:30"
         * }
         * ]
         * }
         */

        const tamp = [];

        list.map((item) => {
            const days = [];
            const hours = [];

            item.days
                ?.filter((x) => x.isActive === true)
                ?.map((day) => {
                    if (day) {
                        days.push(item.days.indexOf(day) + 1);
                    }
                    return days;
                });

            item.hours.map((hour) => {
                const tampStart = convertToHours(hour.valStart);
                const tampEnd = convertToHours(hour.valEnd);

                hours.push({
                    startHours: tampStart,
                    endHours: tampEnd,
                });
                return hours;
            });

            return tamp.push({
                days,
                hours,
                profile_id: item.profile_id,
            });
        });

        const data = {
            name: "schedule-" + telephoneSwitchboard.get?.standard_number?.standard_number,
            days: tamp,
        };

        onSave(data);
    };

    useEffect(() => {
        if (saveWithEvent) {
            saveDataToDB();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveWithEvent]);

    return (
        <>
            <div id="horaires" className="flex flex-col gap-8 mb-5" key={telephoneSwitchboard.get?.id}>
                {list?.map((item) => {
                    return (
                        <Config.HorairesOuverture.JoursOuverture
                            key={
                                `slot-${item.id}-${list.indexOf(item)}-${Math.random() * 1000}` +
                                telephoneSwitchboard.get?.id
                            }
                            data={item}
                            profile_id={item.profile_id}
                            slide={{ min, max, hours, minutesSeparator }}
                            func={{
                                convertToHours,
                                handleChangeProfile,
                                handleUpdateDay,
                                handleDoubleClick,
                                handleDrag,
                                handleRemoveSlot,
                                handleResetSlot,
                            }}
                            canDelete={list?.length > 1}
                        />
                    );
                })}
            </div>

            <div className="HorairesButton">
                {list?.length < 3 && (
                    <button className="ButtonAdd" onClick={handleAddSlot}>
                        + Ajouter un nouveau créneau
                    </button>
                )}

                <button className="ButtonSave" onClick={() => saveDataToDB()}>
                    Enregistrer
                </button>
            </div>
        </>
    );
};

export default HorairesOuverture;
