import React from "react";

import { Layout, HeadPage, ConsoComponent } from "components";

import useMainContext from "contexts/MainContext";

const Consommation = () => {
    const { telephoneSwitchboard } = useMainContext();

    return (
        <Layout>
            <HeadPage isProfil={false} title="Ma consommation" />
            <ConsoComponent key={telephoneSwitchboard?.get?.id} />
        </Layout>
    );
};

export default Consommation;
