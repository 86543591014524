import React, { useState } from "react";

import { InputList, Boutique } from "components";

import "./TableRow.scss";

const TableRow = ({ data }) => {
    const [dropdown, setDropdown] = useState(false);

    const formatDate = (date) => {
        const dateArray = date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2].split("T")[0];

        const hours = parseInt(dateArray[2].split("T")[1].split(":")[0]) + 1;
        const minutes = dateArray[2].split("T")[1].split(":")[1];

        return `${day}/${month}/${year} à ${hours}h${minutes}`;
    };
    return (
        <>
            {data.isSend ? (
                <>
                    <tr className="SMSTableRow">
                        <td>
                            {/* <InputList.InputClassic
                                id="nomCampagne"
                                placeholder="Campagne SMS Parent d'élève"
                                defaultValue={data.name}
                                type="text"
                                required={true}
                                onChange={() => {}}
                            /> */}
                            <p>{data?.name}</p>
                        </td>

                        <td>
                            <div className="Users">
                                <p>{data.numbers.length}</p>
                                <i style={{ fontSize: "18px" }} className="fas fa-user"></i>
                            </div>
                        </td>

                        <td>
                            <div className="Actions flex justify-center">
                                <i
                                    style={!dropdown ? { color: "black" } : { color: "#264a6e" }}
                                    className="far fa-eye"
                                    onClick={() => setDropdown(!dropdown)}
                                ></i>
                                {/* <i className="far fa-trash"></i> */}
                            </div>
                        </td>

                        <td>{formatDate(data.send_at)}</td>
                    </tr>
                    {dropdown !== false && (
                        <tr>
                            <td colSpan="4">
                                <div style={{ display: "flex", padding: "0 20px", paddingTop: "10px" }}>
                                    <div style={{ width: "50%" }}>
                                        <p style={{ fontSize: "14px" }}>Message :</p>
                                        <InputList.InputTextarea
                                            line={10}
                                            defaultValue={data.text}
                                            onChange={() => {}}
                                            readOnly={true}
                                        />
                                    </div>
                                    <Boutique.EnvoyerSMS.CampaignListNumbers numbers={data.numbers} />
                                </div>
                            </td>
                        </tr>
                    )}
                </>
            ) : null}
        </>
    );
};

export default TableRow;
