import "./Popup.scss";

import { InputList } from "components";

const PopupLayout = ({ setName, name, pressBack, pressNext, input, button, btnText, children, update }) => {
    const click = () => {
        pressNext();
        // cehck if update is a function
        if (typeof update === "function") {
            update();
        }
    };

    return (
        <div className="PopupSMS">
            {input && (
                <div className="Input">
                    <p>Nom :</p>
                    <InputList.InputClassic
                        defaultValue={name}
                        onChange={setName}
                        className=""
                        placeholder="Campagne SMS Parent d'élève"
                    />
                </div>
            )}
            <div className="PopupContent">{children}</div>
            {button && (
                <div className="Button">
                    <button className="précédent" onClick={pressBack}>
                        Précédent
                    </button>
                    <InputList.InputButton text={btnText} onClick={click} />
                </div>
            )}
        </div>
    );
};

export default PopupLayout;
