import api from "utils/api/api";

const UpdateOnboarding = async (data) => {
    try {
        const response = await api.post("/api/v1/auth/onboarding", data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default UpdateOnboarding;
