import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useMessageContext from "contexts/MessageContext";

import "./PaymentSuccess.scss";

import logo from "assets/images/logo/sel.svg";
import deconnect from "assets/icons/deconnect.svg";
import check from "assets/picto/validate.svg";

import SuccessCheckoutSessionUnPaid from "utils/api/unpaid/SuccessCheckoutSessionUnPaid";

const PaymentSuccess = () => {
    const navigate = useNavigate();

    const { showMessage } = useMessageContext();

    // GET THE PARAMS FROM THE URL ?customer_id=1&standard_number_id=1
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        success();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const success = async () => {
        const customer_id = params.get("customer_id");
        const standard_number_id = params.get("standard_number_id");

        if (!customer_id || !standard_number_id) {
            showMessage({
                text: "Une erreur est survenue. Veuillez contacter le support.",
                type: "error",
            });

            return navigate("/");
        }

        const data = await SuccessCheckoutSessionUnPaid(customer_id, standard_number_id);

        if (!data.success) {
            return showMessage({
                text: "Une erreur est survenue. Veuillez contacter le support.",
                type: "error",
            });
        }

        // refresh the page
        window.location.reload();
    };

    return (
        <div className="pagesuccess">
            <div className="navbar">
                <img src={logo} alt="logo" />
                <img src={deconnect} alt="bouton de deconnexion du backoffice" />
            </div>
            <div className="content">
                <div className="content-data">
                    <img src={check} alt="icone check" />
                    <p className="number">Paiement réussi</p>
                    <div className="infos">
                        <div className="info">
                            <p className="name">Montant TTC :</p>
                            <p className="price">23,88 €</p>
                        </div>
                        <button onClick={() => window.reload()}>Redirection vers votre espace client</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
