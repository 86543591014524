import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Outlet, useNavigate } from "react-router-dom";

import { SideBar, Header } from "components";
// import Particle from "components/Particle";

import "./Home.scss";

import useGuideContext from "contexts/GuideContext";
import useMainContext from "contexts/MainContext";

import GuideCompleted from "utils/api/auth/GuideCompleted";
import LocalStorageChecker from "components/common/LocalStorageChecker";

const Home = () => {
    const { customer } = useMainContext();
    const { isDisplayed, data, path, start, guideElement } = useGuideContext();

    const navigate = useNavigate();

    const [selectedElement, setSelectedElement] = React.useState(null);

    useEffect(() => {
        // get the window width and height
        // if the width is less than 1024px, we don't display the guide
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (customer.get?.id && customer.get.isOnboarded && !customer.get.isGuideCompleted) {
            if (width > 1024 && height > 768) {
                start();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.get]);

    useEffect(() => {
        if (!isDisplayed.get && customer.get?.isGuideCompleted === false) {
            GuideCompleted();
            removeGuide();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisplayed.get]);

    useEffect(() => {
        if (path.get && isDisplayed.get) {
            navigate(path.get);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, path]);

    useEffect(() => {
        if (data.get?.element && isDisplayed.get) {
            setTimeout(() => {
                nextElement();
            }, 10);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.get?.element]);

    const nextElement = () => {
        // Remove the previous guide element and its container, if they exist
        removeGuide();

        const element = document.querySelector("#" + data.get.element);

        if (element) {
            element.classList.add("guide");

            // Create a container div for the guideElement
            const container = document.createElement("div");
            container.className = "guide-container"; // Add a class name to identify the container

            // Render the guideElement inside the container
            ReactDOM.render(guideElement, container);

            // Append the container (with the rendered guideElement) to the target element
            element.appendChild(container);

            setSelectedElement(element);
        }
    };

    const removeGuide = () => {
        if (selectedElement) {
            const existingGuideContainer = selectedElement.querySelector(".guide-container");
            if (existingGuideContainer) {
                ReactDOM.unmountComponentAtNode(existingGuideContainer);
                selectedElement.removeChild(existingGuideContainer);
                selectedElement.classList.remove("guide");
            }
        }
    };

    return (
        <div className="page-body">
            <LocalStorageChecker itemKey={"isStripeSepaRequired"} itemValidValue={'false'}/>
            <Header />
            <main>
                <SideBar />
                <div id="Content">
                    {/* <p className="warning">
                        <i className="fa fa-exclamation-triangle" />
                        <span>
                            <b>Attention :</b> Si vous rencontrez des problèmes ou avez des suggestions, veuillez
                            contacter notre équipe support à l'adresse :{" "}
                        </span>
                        <a href="mailto:support@standardenligne.fr">support@standardenligne.fr</a>.
                        <br />
                        <span>
                            <b>Pour consulter les dernières mises à jour :</b> <Link to="release">cliquez-ici</Link>
                        </span>
                        <span className="beta">BETA</span>
                    </p> */}
                    <Outlet />
                    {/* <Particle /> */}
                </div>
            </main>

            {isDisplayed.get && <div className="shadow-layer" />}
            {isDisplayed.get && (
                <p className="guide-skip" onClick={() => isDisplayed.set(false)}>
                    <i className="fas fa-times" />
                    Quitter la visite guidée
                </p>
            )}
        </div>
    );
};

export default Home;
