import api from "../../api";

const ConfirmPayment = async ({ payment_id, script_id }) => {
    try {
        const response = await api.post(`/api/v1/payment/confirm-payment/${payment_id}`, { script_id });

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default ConfirmPayment;
