import api from "../../api";

const AddCredits = async ({ standard_number, credit_package_id, isRecharge, payment_method_id }) => {
    try {
        const response = await api.post("/api/v1/payment/add-credits", {
            standard_number,
            credit_package_id,
            isRecharge,
            payment_method_id,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default AddCredits;
