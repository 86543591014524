import api from "utils/api/api";

const DisableEnableScriptKey = async ({ script_id, key, value }) => {
    try {
        const response = await api.get(`/api/v1/script/${script_id}/${key}/${value}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { DisableEnableScriptKey };
