import api from "utils/api/api";

import { useMutation } from "react-query";

const UploadAudioFile = async (bufferData, audio_message_id) => {
    try {
        const response = await api.patch(`/api/v1/script/audio-message/${audio_message_id}`, {
            buffer: bufferData,
            isText: false,
        });
        console.log("UPLOADED FILE >>> ", response);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useUploadAudioFile = () =>
    useMutation(({ bufferData, audio_message_id }) => UploadAudioFile(bufferData, audio_message_id));

export default useUploadAudioFile;
export { UploadAudioFile };
