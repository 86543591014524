import api from "utils/api/api";

const GuideCompleted = async () => {
    try {
        const response = await api.post("/api/v1/auth/guide-completed");

        return response.data;
    } catch (err) {
        throw err;
    }
};

export default GuideCompleted;
