import React from "react";

import { Layout, HeadPage, Boutique } from "components";

const AjoutCredits = () => {
    return (
        <Layout id="credits" className="pb-5">
            <HeadPage title="Crédits" isProfil={false} />
            <Boutique.Credits.CreditLayout />
        </Layout>
    );
};

export default AjoutCredits;
