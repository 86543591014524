import { useState } from "react";
import "./Message.scss";

import voiceList from "utils/config/studio/voice.json";
import { PatchAudioMessage } from "utils/api/config/StudioVocal/SyntheseVocal/PatchAudioMessage";

import { InputList } from "components";

const AnnonceAccueil = ({ data, update }) => {
    const [voiceId, setVoiceId] = useState(voiceList[0].id);
    const [info, setInfo] = useState({ update: false, text: data?.text || "" });

    const [isLoading, setIsLoading] = useState(false);

    const [audio] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);

    const setVoice = (e) => {
        setVoiceId(e.id);
    };

    const updateMessage = ({ text, voiceId }) => {
        update(voiceId, text);
    };

    const updateVocal = async ({ text, voiceId }) => {
        setIsLoading(true);

        const req = await PatchAudioMessage({
            audio_message_id: data?.id,
            data: {
                text: text || "",
                voiceId,
            },
        });

        setIsLoading(false);

        if (!req.success) {
            return;
        }

        updateMessage({ text: text, voiceId });
        setInfo({ ...info, update: false });

        audio.pause();

        audio.removeEventListener("ended", () => {
            audio.src = "";
        });

        audio.src = req.data.audio_path;

        audio.addEventListener("ended", () => {
            setIsPlaying(false);
        });

        playAudio();
    };

    const playAudio = () => {
        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="MessageContainer">
            <h3>Mon message</h3>

            <div className="VoiceVolume">
                <div className="Voice">
                    <p>Sélectionnez votre voix</p>
                    <InputList.InputDropdown
                        defaultValue={voiceList[0].text}
                        list={voiceList}
                        classNameCustom={"w-[100%]"}
                        mlCustom={"0"}
                        onChange={(e) => {
                            setVoice(e);
                            setInfo({ ...info, update: true });
                        }}
                    />
                </div>
            </div>

            <div className="MessageContent">
                <p>Composez librement votre message :</p>
                <InputList.InputTextarea
                    defaultValue={data?.text || ""}
                    placeholder="Ecrivez ici ..."
                    onChange={(e) => {
                        setInfo({ text: e, update: true });
                    }}
                />
                {isLoading ? (
                    <div className="flex items-center onboard-textarea-button">
                        {/* Replace the SVG code for the loading icon with your desired loading icon */}
                        <div className="loading-icon" />
                        <p>Chargement...</p>
                    </div>
                ) : info.update === false ? (
                    !isPlaying ? (
                        <InputList.InputButton
                            key="play"
                            text={
                                <div key="play" className="flex items-center">
                                    <i className="far fa-volume-up" /> <p key="p2">Écouter</p>
                                </div>
                            }
                            onClick={playAudio}
                            className="onboard-textarea-button"
                        />
                    ) : (
                        <InputList.InputButton
                            key="pause"
                            text={
                                <div key="stop" className="flex items-center">
                                    <i className="fas fa-volume-mute" />
                                    <p>Mettre en pause</p>
                                </div>
                            }
                            onClick={playAudio}
                            className="onboard-textarea-button"
                        />
                    )
                ) : (
                    <InputList.InputButton
                        key="save"
                        text={
                            <div key="save" className="flex items-center">
                                <i className="fas fa-save" /> <p>Enregistrer</p>
                            </div>
                        }
                        onClick={() => updateVocal({ text: info.text, voiceId })}
                        className="onboard-textarea-button"
                    />
                )}
            </div>
        </div>
    );
};

export default AnnonceAccueil;
