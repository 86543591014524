import api from "utils/api/api";
import { useQuery } from "react-query";

const GetCustomerTicket = async (ticketId) => {
    try {
        const response = await api.get(`/api/v1/zendesk/get-ticket/${ticketId}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetCustomerTicket = (ticketId) => {
    return useQuery(["getCustomerTicket", ticketId], () => GetCustomerTicket(ticketId), {
        staleTime: 1000 * 10, // 10 seconds
        enabled: !!ticketId,
    });
};

export { GetCustomerTicket };
export default useGetCustomerTicket;
