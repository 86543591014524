import React from "react";
import "./RenvoyerVers.scss";

const RenvoyerVers = ({ data, onRemove, onChange }) => {
    const handleFieldChange = (event, field) => {
        onChange(field, event.target.value);
    };

    return (
        <div className="RenvoyerVers">
            <label>Nom:</label>
            <input
                type="text"
                placeholder="Service Commercial"
                value={data.name}
                onChange={(event) => handleFieldChange(event, "name")}
            />

            <label>Numéro:</label>
            <input
                type="tel"
                placeholder="+33187200200"
                value={data.number}
                onChange={(event) => handleFieldChange(event, "number")}
            />

            <button className="ButtonRemove" onClick={() => onRemove(data.key)}>
                <i className="fas fa-times-circle"></i>
            </button>
        </div>
    );
};

export default RenvoyerVers;
