import HorairesOuverture from "./HorairesOuverture";
import MenuMultichoix from "./MenuMultichoix";
import MessageAccueil from "./MessageAccueil";
import MessageFermeture from "./MessageFermeture";
import Musique from "./Musique";
import NumeroRenvois from "./NumeroRenvois";
import Reglages from "./Reglages";
import Repondeur from "./Repondeur";

const Config = {
    HorairesOuverture,
    MenuMultichoix,
    MessageAccueil,
    MessageFermeture,
    Musique,
    NumeroRenvois,
    Reglages,
    Repondeur,
};

export default Config;
