import "./Button.scss";

const InputButton = ({ text, isSquare, onClick, className, isActive, type, disabled, style }) => {
    return (
        <button
            type={type || "button"}
            onClick={onClick}
            style={{
                ...(isSquare ? { height: "25px", width: "25px", padding: 0, margin: "0 5px" } : {}),
                ...(isActive !== false ? {} : { backgroundColor: "#cacaca", pointerEvents: "none" }),
                ...style,
            }}
            className={`${className || ""} customButton`}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default InputButton;
