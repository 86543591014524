import api from "utils/api/api";

const PatchHorairesOuvertures = async (data, id) => {
    try {
        const response = await api.patch(`/api/v1/telephone-switchboard/planning/${id}`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { PatchHorairesOuvertures };
