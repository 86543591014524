import { InputList } from "components";

const InputPhone = ({ num, onChange, setIndicatif, ext }) => {
    return (
        <div className="flex items-center z-20">
            <InputList.InputDropdownCountry onChange={setIndicatif} />
            <InputList.InputDouble
                placeholder={"6 12 34 56 78"}
                type={"number"}
                indicatif={ext}
                defaultValue={num}
                onChange={onChange}
            />
        </div>
    );
};
export default InputPhone;
