import React, { useState, useEffect } from "react";

import "./NumeroRenvois.scss";

import { Config, Layout, HeadPage, Table, InputList } from "components";

import { PatchRedirectNumber } from "utils/api/config/NumeroRenvoi/PatchRedirectNumber";
import { GetRedirectNumbers } from "utils/api/config/NumeroRenvoi/GetRedirectNumbers";
import { GetOutOfPackageRateByPrefix } from "utils/api/boutique/package/GetOutOfPackageRateByPrefix";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

const BANNED_NUMBERS = [
    "3374403",
    "3359016",
    "3359034",
    "3359645",
    "3359495",
    "3326307",
    "3326902",
    "3350856",
    "3318947",
    "3325848",
    "3337958",
    "3344931",
    "3352536",
    "3375799",
    "3375716",
    "3397381",
    "3318834",
    "3318698",
    "3346531",
    "3375780",
    "3375781",
    "3375782",
    "3375783",
    "3375784",
    "3325950",
    "3337637",
    "3339223",
    "3341233",
    "3345145",
    "3353707",
    "3378090",
    "3378091",
    "3378092",
    "3378093",
    "3378094",
    "3378095",
    "3378096",
    "3378097",
    "3378098",
    "3378099",
    "3374430",
    "3374431",
    "3374432",
    "3374433",
    "3374434",
    "3374435",
    "3374436",
    "3374437",
    "3374438",
    "3374439",
    "3318962",
    "3397346",
    "3364466",
    "3364467",
    "3364468",
    "3364469",
    "3375680",
    "3375681",
    "3375682",
    "3375683",
    "3375684",
    "3375685",
    "3375686",
    "3375687",
    "3375688",
    "3375689",
    "3375690",
    "3375691",
    "3375692",
    "3375693",
    "3375694",
    "3375695",
    "3375696",
    "3375697",
    "3375698",
    "3375699",
    "3397848",
];

const NumeroRenvois = () => {
    const isAdmin = window.localStorage.getItem("isAdmin") === "true";
    const isCentrex = window.localStorage.getItem("isCentrex") === "true";

    const { customer, telephoneSwitchboard } = useMainContext();

    const { showMessage } = useMessageContext();

    const [update, setUpdate] = useState(0);

    const [profilSelected, setProfilSelected] = useState(0);

    const [list, setList] = useState({});
    const [listProfil, setListProfil] = useState([]);

    useEffect(() => {
        if (customer.get?.id !== undefined && telephoneSwitchboard.get?.id !== undefined) {
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.get?.id, telephoneSwitchboard.get?.id]);

    useEffect(() => {
        if (!list[profilSelected]) {
            setListProfil([]);
            return;
        }

        setListProfil(list[profilSelected]);

        setUpdate(update + 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilSelected]);

    useEffect(() => {
        setList((list) => {
            const newList = { ...list };

            newList[profilSelected] = listProfil;

            return newList;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listProfil]);

    const fetchData = async () => {
        const request = await GetRedirectNumbers({ customer_id: customer.get?.id });

        console.log("request REDIRECT NUMBERS >>>>>> ", request.data);

        // const data = await request?.data;
        const data = await request?.data?.filter((item) => {
            const listProfilId = telephoneSwitchboard.get?.Profile.map((item) => item.id);

            return listProfilId.includes(item.profile_id);
        });

        const tab = {};

        // reset state
        setListProfil([]);
        setList({});
        setProfilSelected(0);

        await Promise.all(
            data
                ?.sort((a, b) => a?.priority - b.priority)
                ?.map(async (item) => {
                    if (!tab[item.profile_id]) {
                        tab[item.profile_id] = [];
                    }

                    const { data: rate } = await GetOutOfPackageRateByPrefix({
                        prefix: item.number,
                    });

                    const data = {
                        isActive: item.isActive,
                        number: item.number,
                        name: item.name,
                        isNew: false,
                        isDelete: false,
                        id: item.id,
                        priority: item.priority,
                        prefix: {
                            isInclude: rate?.isInclude,
                            isEnable: rate?.isEnable,
                            tarif: rate?.tarif,
                        },
                    };

                    return await tab[item.profile_id].push(data);
                })
        );

        // get value of first key
        let firstKey = Object.keys(tab)[0];

        if (!firstKey) {
            firstKey = telephoneSwitchboard.get?.Profile[0]?.id;
        }

        setListProfil(tab[firstKey]);
        setList(tab);
        setProfilSelected(firstKey);
        setUpdate(update + 1);
    };

    const [badNumber, setBadNumber] = useState(false);

    const handleChangeInput = async (keyWord, value, id) => {
        if (keyWord === "number") {
            if (Boolean(BANNED_NUMBERS.find((item) => value.includes(item)))) {
                setBadNumber(true);
                showMessage({
                    text: "Nous ne pouvons pas prendre en charge ce numéro de téléphone. Veuillez en saisir un autre.",
                    type: "error",
                });
                return;
            } else {
                setBadNumber(false);
            }
        }
        const tab = [];

        if (keyWord === "isActive") {
            listProfil.map((item, index) => {
                if (index === id) {
                    item[keyWord] = !item[keyWord];
                }
                return tab.push(item);
            });
            return;
        }

        const { data: rate } = await GetOutOfPackageRateByPrefix({
            prefix: value,
        });

        const prefixData = {
            isInclude: rate?.isInclude,
            isEnable: rate?.isEnable,
            tarif: rate?.tarif,
        };

        listProfil.map((item, index) => {
            if (index === id) {
                item[keyWord] = value;
                item.prefix = prefixData;
            }

            return tab.push({
                ...item,
            });
        });

        setListProfil(tab);
    };

    const handleChangeName = (value, id) => {
        const tab = [];

        listProfil.map((item, index) => {
            if (index === id) {
                item.name = value;
            }
            return tab.push(item);
        });

        setListProfil(tab);
    };

    const handleAdd = (phone, name) => {
        setListProfil([
            ...listProfil,
            {
                isActive: true,
                number: phone,
                name: name,
                isNew: true,
                isDelete: false,
                id: listProfil[listProfil.length - 1]?.id + 1 || 0,
                priority: listProfil.length + 1,
            },
        ]);

        setUpdate(update + 1);
    };

    const handleDelete = (id) => {
        const tab = [];

        listProfil?.map((item, index) => {
            if (index !== id) {
                return tab.push(item);
            } else {
                // Check if it's a new item
                if (item.isNew) {
                    return null;
                } else {
                    item.isDelete = true;
                    return tab.push(item);
                }
            }
        });

        setListProfil(tab);

        setUpdate(update + 1);
    };

    const handleChangePriority = (index, value) => {
        setListProfil((list) => {
            const newList = [...list];

            const item = newList[index];
            item.priority = item.priority + (value === "up" ? -1 : 1);

            const items2 = newList[index + (value === "up" ? -1 : 1)];
            items2.priority = items2.priority + (value === "up" ? 1 : -1);

            newList.sort((a, b) => a.priority - b.priority);

            return newList;
        });

        setUpdate(update + 1);
    };

    const updateNumbers = async () => {
        if (badNumber) {
            showMessage({
                text: "Nous ne pouvons pas prendre en charge ce numéro de téléphone. Veuillez en saisir un autre.",
                type: "error",
            });
            return;
        }
        // Check if any number is empty
        const check = listProfil.filter((item) => item.number === "" || item.name === "");

        if (check.length > 0) {
            showMessage({
                text: "Veuillez remplir tous les champs",
                type: "error",
            });

            return;
        }

        // check if any number prefix isEnable false
        const checkPrefix = listProfil.filter(
            (item) => item.prefix.isEnable === false || item.prefix.isEnable === undefined
        );

        if (checkPrefix.length > 0) {
            showMessage({
                text: "Une ou plusieurs destination ne sont pas autorisées",
                type: "error",
            });

            return;
        }

        // check if any number prefix isInclude false and isEnable true
        const checkPrefixInclude = listProfil.filter(
            (item) => item.prefix.isInclude === false && item.prefix.isEnable === true
        );

        if (checkPrefixInclude.length > 0) {
            // check if credit is enough
            const credits = customer.get?.credit_balance;

            const total = checkPrefixInclude.reduce((acc, item) => {
                return acc + Number(item.prefix.tarif);
            }, 0);

            if (credits < total) {
                showMessage({
                    text: "Une ou plusieurs destinations ne sont pas autorisées car vous n'avez pas assez de crédit sur votre compte",
                    type: "error",
                });

                return;
            }
        }

        showMessage({
            text: "Mise à jour des numéros de renvois en cours...",
            type: "info",
        });

        const request = await PatchRedirectNumber({
            customer_id: customer.get.id,
            data: {
                profile_id: profilSelected,
                standard_number: telephoneSwitchboard?.get?.standard_number?.standard_number,
                redirect_numbers: listProfil,
            },
        });

        if (!request.success) {
            showMessage({
                text: "Une erreur est survenue lors de la mise à jour des numéros de renvois",
                type: "error",
            });

            return;
        }

        showMessage({
            text: "Les numéros de renvois ont bien été mis à jour",
            type: "success",
        });

        fetchData();
    };

    return (
        <Layout id="NumeroRenvois" className="NumeroRenvois">
            <HeadPage title="Numéros de renvois" onChangeProfil={(e) => setProfilSelected(e.id)} />
            <Table key={update} listHead={["Désactiver/Activer", "Téléphone", "Nom", ""]} options={{ isFixed: true }}>
                {listProfil
                    ?.sort((a, b) => a?.priority - b?.priority)
                    ?.map((item, index) => {
                        const data = {
                            ...item,
                            index: index,
                            canDelete: isAdmin ? listProfil.length > 1 : isCentrex ? false : listProfil.length > 1,
                            canUp: index > 0,
                            canDown: index < listProfil.length - 1,
                            handleChangeInput,
                            handleDelete,
                            handleChangePriority,
                            handleChangeName,
                        };
                        if (!item.isDelete) {
                            return <Config.NumeroRenvois.TableRow key={`${item.phone}-${index}`} data={data} />;
                        }
                        return null;
                    })}
            </Table>

            {listProfil?.length < 5 && (
                <>
                    {isAdmin ? (
                        <p
                            onClick={() => handleAdd("+33", "Numéro de renvoi")}
                            className="AddKey onActive onHover pt-5 text-base"
                        >
                            + Ajouter un numéro de renvoi
                        </p>
                    ) : isCentrex ? null : (
                        <p
                            onClick={() => handleAdd("+33", "Numéro de renvoi")}
                            className="AddKey onActive onHover pt-5 text-base"
                        >
                            + Ajouter un numéro de renvoi
                        </p>
                    )}
                </>
            )}
            <div className="flex justify-end pt-10">
                <InputList.InputButton onClick={updateNumbers} text="Sauvegarder" />
            </div>
        </Layout>
    );
};

export default NumeroRenvois;
