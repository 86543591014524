import api from "utils/api/api";

const DisableEnableMenu = async ({ menu_id, value }) => {
    try {
        const response = await api.get(`/api/v1/script/menu/${menu_id}/is-active/${value}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { DisableEnableMenu };
