const TableRow = ({ data }) => {
    const textColor = data?.isInclude ? "text-green-600" : "text-red-600";

    return (
        <tr>
            <td>
                <p className={textColor}>{data?.name}</p>
            </td>
            <td>
                <p className={textColor}>{data?.tarif === "PAS AUTORISE" ? "PAS AUTORISE" : `${data?.tarif}€`}</p>
            </td>
            <td>
                <p className={textColor}>{data?.isInclude ? "Oui" : "Non"}</p>
            </td>
        </tr>
    );
};

export default TableRow;
