import { Layout, HeadPage, Compte, Table } from "components";

import useMainContext from "contexts/MainContext";

import useGetAllInvoiceOfCustomer from "utils/api/customer/invoice/GetAllInvoiceOfCustomer";
import { GetInvoicePdf } from "utils/api/customer/invoice/GetInvoicePdf";

const Factures = () => {
    const { customer } = useMainContext();

    const { data: invoices, isLoading } = useGetAllInvoiceOfCustomer({ customer_mail: customer.get?.email });

    const downloadInvoice = async (id) => {
        const invoice = await GetInvoicePdf({ invoice_id: id });
        console.log(invoice);
        window.open(invoice?.data?.link, "_blank");
    };

    if (isLoading) {
        return (
            <Layout>
                <HeadPage isProfil={false} title="Factures" />
                <div className="flex justify-center items-center p-8">
                    <p className="text-gray-500">Chargement des factures...</p>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <HeadPage isProfil={false} title="Factures" />

            {(!invoices?.data || invoices.data.length === 0) ? (
                <div className="flex justify-center items-center p-8">
                    <p className="text-gray-500">Aucune facture actuellement</p>
                </div>
            ) : (
                <Table listHead={["", "Date", "Montant", "Statut", ""]} options={{ isFixed: true }}>
                    {invoices.data.map((item, index) => {
                        const data = {
                            ...item,
                            index: index,
                        };
                        if (data) {
                            return (
                                <Compte.Factures.TableRow
                                    key={item?.id}
                                    data={data}
                                    handleDownload={() => downloadInvoice(item?.id)}
                                />
                            );
                        }
                    })}
                </Table>
            )}
        </Layout>
    );
};

export default Factures;
