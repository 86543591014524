import { useQuery } from "react-query";

import api from "../api";

const GetCallsCountForStandardNumber = async ({ standard_number_id, period }) => {
    try {
        const response = await api.get(`/api/v1/call/${standard_number_id}/${period}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetCallsCountForStandardNumber = ({ standard_number_id, period }) =>
    useQuery(
        ["callsCount", standard_number_id, period],
        async () => await GetCallsCountForStandardNumber({ standard_number_id, period }),
        {
            enabled: standard_number_id !== undefined,
        }
    );

export default useGetCallsCountForStandardNumber;
export { GetCallsCountForStandardNumber };
