import { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import "./ImportedFile.scss";

import { Boutique, Table } from "components";
import Blocked from "utils/blocked_numbers.json";

const ImportedFile = ({ updateCampaign, file }) => {
    const [data, setData] = useState(null);
    const [column, setColumn] = useState(null);
    const [lines, setLines] = useState(null);
    const [numbers, setNumbers] = useState([]);

    useEffect(() => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = xlsx.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

            data[0]?.some(() => {
                setLines(data.length);
                setData(data);

                return true;
            });
        };
        reader.readAsBinaryString(file);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    useEffect(() => {
        let nums = [];
        if (column)
            for (let i = 1; i < lines; i++) {
                if (
                    data[i][column] &&
                    !Blocked.blocked.includes(data[i][column].toString().replace(/33/g, "").slice(0, 5))
                ) {
                    nums.push(data[i][column].toString().replace(/33/g, ""));
                }
            }

        setNumbers(nums);
        updateCampaign(nums, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column]);

    const removeNumber = (index) => {
        let newNumbers = [...numbers];
        newNumbers.splice(index, 1);
        setNumbers(newNumbers);
        updateCampaign(newNumbers, true);
    };

    return (
        <div className="ImportedFileSMSPopup">
            <h3>Importer un fichier</h3>
            <div className="FileName">
                <i className="fas fa-file"></i>
                <p>{file.name}</p>
            </div>
            <h2>Selectionner une colonne</h2>
            <div className="selectColumn">
                {data?.[0]?.map((e, i) => {
                    return (
                        <p
                            onClick={(e) => {
                                setColumn(e.target.id);
                            }}
                            id={i}
                        >
                            - {e}
                        </p>
                    );
                })}
            </div>

            {column && data && (
                <Table listHead={["Numéro", "Action"]} options={{ isFixed: true }}>
                    {numbers.map((number, index) => (
                        <Boutique.EnvoyerSMS.Popup.ListNumbersTableRow
                            i={index}
                            removeNum={removeNumber}
                            data={number}
                            key={index}
                        />
                    ))}
                </Table>
            )}
        </div>
    );
};

export default ImportedFile;
