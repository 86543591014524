import React, { useState, useEffect } from "react";

const InputComboboxes = ({ defaultValue, className, list, onChange, options }) => {
    const [query, setQuery] = useState(defaultValue || "");
    const [isUpdate, setIsUpdate] = useState(false);
    const [filteredValues, setFilteredValues] = useState([]);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        setFilteredValues(
            list.filter((item) => item[(options && options.word) || "text"].toLowerCase().includes(query.toLowerCase()))
        );
        if (isUpdate) {
            setShowList(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const handleSearch = (e) => {
        setQuery(e);
        setIsUpdate(true);
        if (e === "") {
            setShowList(false);
        }
        onChange(e);
    };

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (!e.target.closest(".input-comboboxes")) {
                setShowList(false);
            }
        });
    }, []);

    return (
        <div className={`relative w-32 ${className || ""} input-comboboxes`}>
            <input
                className="CloseInputCombo relative w-full bg-white border-[1px] border-gray-300 rounded-md shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 sm:text-sm h-9 flex items-center"
                role="combobox"
                aria-controls="options"
                aria-expanded="false"
                value={query}
                onChange={(e) => handleSearch(e.target.value)}
            />
            <button
                type="button"
                onClick={() => setShowList(!showList)}
                className="CloseInputCombo absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
                <svg
                    className="CloseInputCombo h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {showList && filteredValues.length >= 1 && (
                <ul
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    id="options"
                    role="listbox"
                >
                    {filteredValues.map((x, index) => (
                        <li
                            className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                            id={`option-${index}`}
                            tabIndex="-1"
                            key={index}
                            onClick={() => handleSearch(x[(options && options.word) || "text"]) || setShowList(false)}
                        >
                            <span className="block truncate">{x[options?.word || "text"]}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default InputComboboxes;
