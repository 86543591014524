import React, { useEffect, useState } from "react";

import { Config, InputList } from "components";

import "./TableRow.scss";

const TableRow = ({ data, handleUpdateItem, listNumber, listKey, listAction, isLoading }) => {
    const { canDelete, handleChangeInput } = data;

    const [dropdown, setDropdown] = useState(false);
    const [isRedirect, setIsRedirect] = useState(null);

    useEffect(() => {
        if (isRedirect !== null) return;

        setIsRedirect(data.isPhoneRedirect);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.isPhoneRedirect]);

    const handleKeyChange = (event) => {
        const input = event.target.value;
        const regex = /^[0-9#*]+$/;
        const lastChar = input.charAt(input.length - 1);

        if (!regex.test(lastChar)) {
            event.target.value = input.slice(0, -1);
        } else {
            handleChangeInput("key", input, data.index);
        }
    };

    const handleActionChange = (value) => {
        setIsRedirect(value.id === 1 ? true : false);

        if (value.id === 1) {
            // check if there is a number in the list of redirect number and if not, add one
            if (data.MenuItem_RedirectNumber.length === 0) {
                handleUpdateItem(data.index, {
                    isPhoneRedirect: true,
                    redirect_number: [
                        {
                            id: listNumber[0].id,
                            number: listNumber[0].number,
                        },
                    ],
                });
            } else {
                handleUpdateItem(data.index, {
                    isPhoneRedirect: true,
                });
            }
        } else {
            // check if there is a message in the list of audio message and if not, add one
            if (data.MenuItem_AudioMessage.length === 0) {
                handleUpdateItem(data.index, {
                    isPhoneRedirect: false,
                    audioMessage: {
                        isText: true,
                        text: "choix " + data.key,
                        voiceId: "Lea",
                    },
                });
            } else {
                handleUpdateItem(data.index, {
                    isPhoneRedirect: false,
                });
            }
        }

        // handleChangeInput("isPhoneRedirect", value.id === 1 ? true : false, data.index);
    };

    const handleNameChange = (value) => {
        handleChangeInput("name", value.target.value, data.index);
    };

    const handleItemChange = (value) => {
        setDropdown(!dropdown);

        handleUpdateItem(data.index, value);

        return;
    };

    const handleUpdateAudio = async (value) => {
        const infos = {
            isPhoneRedirect: false,
            audioMessage: {
                isText: value.text ? true : false,
                text: value.text,
                voiceId: value.voiceId,
                buffer: value.base64data,
            },
        };

        handleUpdateItem(data.index, infos);

        // setDropdown(false);
    };

    return (
        <>
            <tr>
                <td>
                    <InputList.InputClassic
                        key={data.index}
                        defaultValue={data.key}
                        list={listKey}
                        className="w-9"
                        onChange={handleKeyChange}
                        readOnly={isLoading}
                    />
                </td>
                <td>
                    <InputList.InputClassic
                        key={data.index}
                        placeholder={"Nom"}
                        defaultValue={data.name}
                        onChange={handleNameChange}
                        wCustom="w-[80%]"
                        readOnly={isLoading}
                    />
                </td>
                <td>
                    <InputList.InputDropdown
                        key={data.index}
                        defaultValue={listAction[data.isPhoneRedirect ? 0 : 1].text}
                        list={listAction}
                        mlCustom="ml-0"
                        wCustom="w-78"
                        onChange={handleActionChange}
                        style={{ wordBreak: "break-all" }}
                        isDisable={isLoading}
                    />
                </td>
                <td>
                    <div className="h-full flex justify-start">
                        <InputList.InputButton
                            key={`edit-${data.index}`}
                            text={<i style={{ fontSize: "20px" }} className="far fa-edit"></i>}
                            isSquare
                            onClick={() => setDropdown(!dropdown)}
                            className={"icon-btn"}
                            disabled={isLoading}
                        />
                        {canDelete && (
                            <InputList.InputButton
                                key={`del-${data.index}`}
                                text={<i style={{ fontSize: "20px" }} className="far fa-trash"></i>}
                                isSquare
                                onClick={() => data.handleDelete(data.index)}
                                className={"icon-btn"}
                                disabled={isLoading}
                            />
                        )}
                    </div>
                </td>
            </tr>
            {dropdown !== false && (
                <tr>
                    <td colSpan="4" style={{ padding: "25px" }}>
                        {isRedirect ? (
                            <Config.MenuMultiChoix.ListeRenvois
                                key={"s1"}
                                handleItemChange={handleItemChange}
                                data={listNumber}
                                itemList={data?.MenuItem_RedirectNumber}
                            />
                        ) : (
                            // <p>Temporairement indisponible</p>
                            <Config.StudioVocal.StudioVocalLayout
                                list={[
                                    {
                                        id: 1,
                                        text: "Synthèse vocale",
                                        component: (
                                            <Config.StudioVocal.SyntheseVocal
                                                key={"s3"}
                                                data={data?.MenuItem_AudioMessage[0]?.audio_message || {}}
                                                onSave={handleUpdateAudio}
                                            />
                                        ),
                                    },
                                    {
                                        id: 2,
                                        text: "Fichier audio",
                                        component: (
                                            <Config.StudioVocal.MP3
                                                key={"s2"}
                                                data={data?.MenuItem_AudioMessage[0]?.audio_message || {}}
                                                onSave={handleUpdateAudio}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        )}
                    </td>
                </tr>
            )}
        </>
    );
};

export default TableRow;
