import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from "chart.js";

import { cloneDeep } from "lodash";

import { InputList } from "components";

import labels from "utils/dataset-label.json";
import list from "utils/call-list.json";

import useMainContext from "contexts/MainContext";

import useGetCallsCountForStandardNumber from "utils/api/call/GetCallsCountForStandardNumber";

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, BarElement, Legend);

const NombreAppel = () => {
    const { telephoneSwitchboard } = useMainContext();

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                min: 0,
                stacked: false,
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
    };

    const [selectedPeriod, setSelectedPeriod] = useState(1);

    const [dataset, setDataset] = useState({
        labels: labels.day,
        datasets: [
            {
                label: "Messagerie vocale",
                backgroundColor: "#f9be2b",
                borderColor: "#f9be2b",
                border: 1,
                data: [],
            },
            {
                label: "Appels répondus",
                backgroundColor: "#31b90d",
                borderColor: "#31b90d",
                border: 1,
                data: [],
            },
            {
                label: "Appels manqués",
                backgroundColor: "#e82a2a",
                borderColor: "#e82a2a",
                border: 1,
                data: [],
            },
        ],
    });

    const { data: callsCount } = useGetCallsCountForStandardNumber({
        standard_number_id: telephoneSwitchboard.get?.standard_number?.id,
        period: selectedPeriod,
    });

    useEffect(() => {
        if (callsCount?.data) {
            const chartDataCopy = cloneDeep(dataset);

            switch (selectedPeriod) {
                case 1:
                    chartDataCopy.labels = labels.day;
                    break;
                case 2:
                    chartDataCopy.labels = labels.week;
                    break;
                case 3:
                    chartDataCopy.labels = labels.month;
                    break;
                case 4:
                    chartDataCopy.labels = labels.year;
                    break;
                default:
                    break;
            }

            chartDataCopy.datasets[0].data = callsCount?.data[0];
            chartDataCopy.datasets[1].data = callsCount?.data[1];
            chartDataCopy.datasets[2].data = callsCount?.data[2];

            setDataset(chartDataCopy);
        }

        // eslint-disable-next-line
    }, [callsCount?.data]);

    return (
        <div id="stats" className="mt-20">
            <h2 className="pb-4">Nombres d'appels </h2>
            <div>
                <InputList.InputDropdown
                    defaultValue={list[0].text}
                    list={list}
                    wCustom="w-52"
                    onChange={(e) => setSelectedPeriod(e.id)}
                />
            </div>
            <div style={{ height: "500px" }}>
                <Bar data={dataset} options={options} />
            </div>
        </div>
    );
};

export default NombreAppel;
