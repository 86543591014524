import { useQuery } from "react-query";

import api from "utils/api/api";

const GetAllCreditPackage = async () => {
    try {
        const response = await api.get("/api/v1/credit-package");

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetAllCreditPackage = () => useQuery("GetAllCreditPackage", GetAllCreditPackage);

export default useGetAllCreditPackage;
export { GetAllCreditPackage };
