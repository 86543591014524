import "./GuidePopup.scss";
import StepDot from "./StepDot/StepDot";

const GuidePopup = ({ id, title, desc, position, currentStep, stepsNumber, onNext, onFinish }) => {
    const stepsArray = Array.from({ length: stepsNumber }, (_, index) => index + 1);

    // Mettre à jour l'étape actuelle et appeler onNext ou onFinish
    const handleNext = () => {
        onNext && onNext();
    };

    const handleFinish = () => {
        onFinish && onFinish();
    };

    return (
        // <div className="popup-container">
        <div
            id={id || ""}
            className="popup"
            style={{ top: position.y, left: position.x, width: position?.width || "" }}
        >
            <h3>{title}</h3>
            <p>{desc}</p>
            <div className="stepsContainer">
                <div className="steps">
                    {stepsArray.map((step) => (
                        <StepDot
                            key={step}
                            isActive={step === currentStep}
                            isCompleted={step < currentStep}
                            isFirstStep={step === 1}
                            step={step} // Passer la valeur de l'étape à StepDot
                        />
                    ))}
                </div>
                <div className="buttons">
                    {onNext && <button onClick={handleNext}>Suivant</button>}
                    {onFinish && <button onClick={handleFinish}>Terminer</button>}
                </div>
            </div>
            <i className="fas fa-caret-up arrow" style={position?.arrow}></i>
        </div>
        // </div>
    );
};

export default GuidePopup;
