import api from "utils/api/api";

const PatchScript = async ({ script_id, data }) => {
    try {
        const response = await api.patch(`/api/v1/script/${script_id}`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { PatchScript };
