import { useQuery } from "react-query";

import api from "utils/api/api";

const ListMusic = async () => {
    try {
        const response = await api.get(`/api/v1/music`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useListMusic = () => useQuery(["listMusic"], ListMusic);

export { ListMusic, useListMusic };
