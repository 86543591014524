import { useState, useEffect } from "react";

import "./HorairesOuverture.scss";

import { Layout, HeadPage, InputList, Config } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import { PatchHorairesOuvertures } from "utils/api/config/HorairesOuverture/PatchHorairesOuverture";
import { useGetPlanningOfCustomer } from "utils/api/config/HorairesOuverture/GetPlanningOfCustomer";

const HorairesOuverture = () => {
    const { showMessage } = useMessageContext();
    const { customer, telephoneSwitchboard } = useMainContext();

    const { data: planning, refetch: refetchPlanning } = useGetPlanningOfCustomer(customer.get?.id);

    const [planningSwitchboard, setPlanningSwitchboard] = useState([]);

    useEffect(() => {
        if (!planning?.data) return;

        const planningSwitchboard = planning?.data?.filter(
            (item) => item.telephone_switchboard_id === telephoneSwitchboard?.get?.id
        );

        setPlanningSwitchboard(planningSwitchboard);
    }, [planning?.data, telephoneSwitchboard?.get]);

    const saveToDB = async (data) => {
        const planningUpdate = {};

        if (!planningSwitchboard) return;

        showMessage({
            type: "info",
            text: "Modification en cours...",
        });

        await Promise.all(
            planningSwitchboard?.map((item) => {
                const days = data?.days?.filter((day) => day.profile_id === item.profile_id);
                // remove profile_id from days
                days.map((day) => delete day.profile_id);

                if (planningUpdate[item.id]) {
                    return (planningUpdate[item.id] = [...planningUpdate[item.id], ...days]);
                } else {
                    return (planningUpdate[item.id] = [...days]);
                }
            })
        );

        // get all keys of planningUpdate and loop through them
        const keys = Object.keys(planningUpdate);

        await Promise.all(
            keys.map(async (key) => {
                const item = planningUpdate[key];

                const request = await PatchHorairesOuvertures(
                    {
                        name: data.name,
                        days: item,
                    },
                    key
                );

                if (!request.success) {
                    showMessage({
                        type: "error",
                        text: "La modification n'a pas été prise en compte.",
                    });

                    return Promise.reject();
                }

                showMessage({
                    type: "success",
                    text: "La modification a été prise en compte.",
                });

                return;
            })
        );

        refetchPlanning();
    };

    return (
        <Layout className="HorairesOuvertureContainer">
            <HeadPage title="Horaires d'ouverture" isProfil={false}>
                <div className="flex items-center justify-end mt-4">
                    <p className="mr-4">Fuseau Horaire</p>
                    <InputList.InputDropdown defaultValue={"Paris"} list={[{ id: 0, text: "Paris" }]} />
                </div>
            </HeadPage>
            <Config.HorairesOuverture.HorairesOuverture
                key={telephoneSwitchboard.get?.id + "" + planningSwitchboard?.length}
                data={planningSwitchboard}
                onSave={saveToDB}
            />
        </Layout>
    );
};

export default HorairesOuverture;
