// import dependencies
import axios from "axios";

// create a new axios instance with a custom config for the backoffice app
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

// Path: backoffice_app/src/utils/api/api.js
// interceptors are functions that will be executed before the request is sent
api.interceptors.request.use(
    (config) => {
        // Get the token from the cookie
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${process.env.REACT_APP_COOKIE_NAME}=`);
        const token = parts.pop().split(";").shift();

        // check if is expired
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const expirationDate = new Date(decodedToken.exp * 1000);
        const isExpired = expirationDate < new Date();

        // if is expired, remove the cookie and redirect to login
        if (isExpired) {
            document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            window.location.href = "/login";
        }

        // Add the token to the request header
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
// Path: backoffice_app/src/utils/api/api.js
// interceptors are functions that will be executed before the response is returned
api.interceptors.response.use(
    async (response) => {        
        if (response.status >= 200 && response.status < 300) {
            return {
                ...response,
                data: {
                    success: true,
                    error: null,
                    data: response.data,
                    code: response.status,
                },
            };
        } else {
            return {
                ...response,
                data: {
                    success: false,
                    error: response.data,
                    data: null,
                    code: response.status,
                },
            };
        }
    },
    async (error) => {
        console.log({error})
        if(error.response.status === 402){
            const customer = error.response.data.data;
            localStorage.setItem('isStripeSepaRequired',true)
            localStorage.setItem('customer',JSON.stringify(customer))
            if(window.location.pathname !== '/compte/information-bancaire/setup-stripe-sepa') window.location.href = '/compte/information-bancaire/setup-stripe-sepa'
        }
        return {
            ...error,
            data: {
                success: false,
                error: error.cause,
                data: null,
                code: error?.response?.status,
            },
        };
    }
);

// Path: backoffice_app/src/utils/api/api.js
// export the api instance
export default api;
