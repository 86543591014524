import api from "utils/api/api";
import { useQuery } from "react-query";

const GetCustomerTickets = async () => {
    try {
        const response = await api.get(`/api/v1/zendesk/get-tickets`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetCustomerTickets = () => {
    return useQuery("getCustomerTickets", GetCustomerTickets);
};

export { GetCustomerTickets };
export default useGetCustomerTickets;
