import { useState } from "react";

import { InputList } from "components";

import Line from "./Line";

import appelant from "utils/config/reglages/appelant.json";
import ring_duration from "utils/config/reglages/ring-duration.json";
import appels_type from "utils/config/reglages/appels-type-list.json";
// import waiting_time from "utils/config/reglages/waiting-time.json";

const Reglages = ({ script, handleUpdate }) => {
    const [desc, setDesc] = useState(appels_type?.find((item) => item.value === script?.appels_type_id)?.description);

    const toggleRepondeur = (toggle) => {
        handleUpdate({ value: toggle, key: "isRepondeur" });
    };

    const handleUpdateDesc = (value) => {
        setDesc(value.description);
        handleUpdate(value);
    };

    const toggleAnonyme = (toggle) => {
        handleUpdate({ value: toggle, key: "isAnonyme" });
    };

    return (
        <div className="param-container">
            <Line
                title="Numéro présenté lorsqu'un appel est renvoyé  "
                description="Lorsque vous recevez un appel venant du Standard, vous pouvez choisir de faire afficher le numéro de
                l'appelant ou votre numéro Standard en ligne. Ainsi, vous pouvez détecter facilement s'il s'agit
                d'un appel professionnel ou personnel.  "
            >
                {script && (
                    <InputList.InputDropdown
                        key={1}
                        defaultValue={appelant[script?.isNumeroAppelant ? 1 : 0].text}
                        wCustom={"w-52"}
                        list={appelant}
                        onChange={handleUpdate}
                    />
                )}
            </Line>
            <Line title="Durée de la sonnerie" description="Durée pendant laquelle le téléphone de renvoi sonne.">
                {script && (
                    <InputList.InputDropdown
                        key={2}
                        defaultValue={ring_duration.find((item) => item.value === script?.ring_duration)?.text}
                        wCustom={"w-52"}
                        list={ring_duration}
                        onChange={handleUpdate}
                    />
                )}
            </Line>
            <Line title="Mode de renvoi" description={desc}>
                {script && (
                    <InputList.InputDropdown
                        key={4}
                        defaultValue={appels_type.find((item) => item.value === script?.appels_type_id)?.name}
                        word="name"
                        wCustom="w-52"
                        list={appels_type}
                        onChange={handleUpdateDesc}
                    />
                )}
            </Line>
            <Line title="Activer le répondeur" description="Activer ou désactiver votre repondeur">
                {script && (
                    <InputList.InputToggle
                        onChange={toggleRepondeur}
                        defaultValue={script?.isRepondeur}
                        key="isRepondeur"
                    />
                )}
            </Line>
            <Line title="Activer les numéros masqués" description="Activer ou désactiver les numéros masqués">
                {script && (
                    <InputList.InputToggle onChange={toggleAnonyme} defaultValue={script?.isAnonyme} key="isAnonyme" />
                )}
            </Line>
        </div>
    );
};

export default Reglages;
