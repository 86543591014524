import "./SideBar.scss";

import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import useMainContext from "contexts/MainContext";

import { ReactComponent as Home } from "./images/home_icon.svg";
import { ReactComponent as Phone } from "./images/phone_icon.svg";
import { ReactComponent as Gear } from "./images/gear_icon.svg";
import { ReactComponent as User } from "./images/user_icon.svg";
import { ReactComponent as Shop } from "./images/shop_icon.svg";
import { ReactComponent as Help } from "./images/help_picto.svg";
import { ReactComponent as Exit } from "./images/exit_icon.svg";

import SidebarPopup from "./SidebarPopup";
import SidebarTitle from "./SidebarTitle";
import api from "utils/api/api";

const SideBar = () => {
    const { token } = useMainContext();

    const ConfigurationPopupRef = useRef(null);
    const ComptePopupRef = useRef(null);
    const BoutiquePopupRef = useRef(null);
    const AidePopupRef = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies();

    const pages = [
        {
            id: 1,
            name: "Configuration",
            popupName: "ConfigurationPopup",
            popupContainer: "ConfigurationPopupContainer",
            ref: ConfigurationPopupRef,
            svg: Gear,
            children: [
                {
                    id: 1,
                    title: "Studio Vocal",
                    children: [
                        {
                            id: 1,
                            text: "Message d'accueil",
                            link: "/config/message-accueil",
                        },
                        {
                            id: 2,
                            text: "Message de fermeture",
                            link: "/config/message-fermeture",
                        },
                        {
                            id: 3,
                            text: "Musique",
                            link: "/config/musique",
                        },
                    ],
                },
                {
                    id: 2,
                    title: "Paramètres",
                    children: [
                        {
                            id: 1,
                            text: "Numéros de renvois",
                            link: "/config/numero-renvois",
                        },
                        {
                            id: 2,
                            text: "Menu multichoix",
                            link: "/config/menu-multichoix",
                        },
                        // {
                        //     id: 3,
                        //     text: "Répondeur",
                        //     link: "/config/repondeur",
                        // },
                        {
                            id: 4,
                            text: "Règlages",
                            link: "/config/reglages",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "Calendrier",
                    children: [
                        {
                            id: 1,
                            text: "Horaires d'ouverture",
                            link: "/config/horaires-ouverture",
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            name: "Compte",
            popupName: "ComptePopup",
            popupContainer: "ComptePopupContainer",
            ref: ComptePopupRef,
            svg: User,
            children: [
                {
                    id: 1,
                    title: "Offre",
                    children: [
                        {
                            id: 1,
                            text: "Tarifs",
                            link: "/compte/tarifs",
                        },
                        {
                            id: 2,
                            text: "Recharge Automatique",
                            link: "/compte/recharge-automatique",
                        },
                    ],
                },
                {
                    id: 2,
                    title: "Informations",
                    children: [
                        {
                            id: 1,
                            text: "Coordonnées",
                            link: "/compte/coordonnees",
                        },
                        {
                            id: 2,
                            text: "Modifier mon mot de passe",
                            link: "/compte/identifiant-connexion",
                        },
                        {
                            id: 3,
                            text: "Informations bancaires",
                            link: "/compte/information-bancaire",
                        },
                        {
                            id: 4,
                            text: "Factures",
                            link: "/compte/factures",
                        },
                    ],
                },
            ],
        },
        {
            id: 3,
            name: "Boutique",
            popupName: "BoutiquePopup",
            popupContainer: "BoutiquePopupContainer",
            ref: BoutiquePopupRef,
            svg: Shop,
            children: [
                {
                    id: 1,
                    title: "Crédits",
                    children: [
                        {
                            id: 1,
                            text: "Ajouter des crédits",
                            link: "/boutique/ajouter-credits",
                        },
                    ],
                },
                {
                    id: 2,
                    title: "Options",
                    children: [
                        {
                            id: 1,
                            text: "Ajouter un profil",
                            link: "/boutique/ajouter-profil",
                        },
                        {
                            id: 2,
                            text: "Ajouter des redirections",
                            link: "/boutique/numero-redirection",
                        },
                        {
                            id: 3,
                            text: "Ajouter des choix",
                            link: "/boutique/choix-supplementaire",
                        },
                        {
                            id: 4,
                            text: "Enregistrer les appels",
                            link: "/boutique/enregistrement-appel",
                        },
                        // {
                        //     id: 5,
                        //     text: "Ajouter portabilité entrante",
                        //     link: "/boutique/portabilite-entrante",
                        // },
                        // {
                        //     id: 6,
                        //     text: "Ajouter portabilité sortante",
                        //     link: "/boutique/portabilite-sortante",
                        // },
                        {
                            id: 5,
                            text: "Télésecrétariat",
                            link: "/boutique/pack-telesecretariat",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "SMS",
                    children: [
                        {
                            id: 1,
                            text: "Envoyer des SMS",
                            link: "/boutique/envoyer-sms",
                        },
                    ],
                },
                {
                    id: 4,
                    title: "Portabilité",
                    children: [
                        {
                            id: 1,
                            text: "Porter un numéro existant",
                            link: "/boutique/portabilite-entrante",
                        },
                        {
                            id: 2,
                            text: "Obtenir mon numéro RIO",
                            link: "/boutique/portabilite-sortante",
                        },
                    ],
                },
            ],
        },
        // {
        //     id: 4,
        //     name: "Support",
        //     popupName: "AidePopup",
        //     popupContainer: "AidePopupContainer",
        //     ref: AidePopupRef,
        //     svg: Help,
        //     children: [
        //         {
        //             id: 1,
        //             title: "Centre d'aide",
        //             children: [
        //                 {
        //                     id: 1,
        //                     text: "FAQ",
        //                     link: "/support/faq",
        //                 },
        //                 {
        //                     id: 2,
        //                     text: "Tickets",
        //                     link: "/support/tickets",
        //                 },
        //             ],
        //         },
        //     ],
        // },
    ];

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (
                !e.target.classList.contains("preventClose")
                // && cookies['standard-token'] !== undefined
            ) {
                BoutiquePopupRef?.current?.classList.add("hidden");
                ConfigurationPopupRef?.current?.classList.add("hidden");
                ComptePopupRef?.current?.classList.add("hidden");
                AidePopupRef?.current?.classList.add("hidden");
            }
        });
    }, [cookies]);

    const logout = () => {
        api.interceptors.request.eject(0);
        document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        token.set("");
        window.localStorage.removeItem('isStripeSepaRequired')
        window.location.href = "/";
    };

    const handleClickCat = (ref) => {
        ConfigurationPopupRef.current.classList.add("hidden");
        ComptePopupRef.current.classList.add("hidden");
        BoutiquePopupRef.current.classList.add("hidden");
        AidePopupRef.current.classList.add("hidden");

        if (ref.current.classList.contains("hidden")) {
            ref.current.classList.remove("hidden");
        }
    };

    const handleClickLink = (ref) => {
        ref.current.classList.add("hidden");
    };

    return (
        <div id="SideBar" className="py-5 bg-gray-200 ">
            <nav className="flex flex-col px-2 space-y-5 justify-between overflow-y-visible h-full">
                <div className="flex flex-col gap-y-4">
                    <Link
                        to="/"
                        className="text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    >
                        <SidebarTitle title="Accueil" Imagesvg={Home} />
                    </Link>

                    <Link
                        to="/journal-appel"
                        className="text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    >
                        <SidebarTitle title="Journal d'appel" Imagesvg={Phone} />
                    </Link>

                    {pages.map((cat) => {
                        return (
                            <div
                                key={cat.id}
                                className="PopupContainer relative text-white group flex items-center px-2 py-2 text-base font-medium rounded-md preventClose"
                            >
                                <SidebarTitle
                                    onClick={() => handleClickCat(cat.ref)}
                                    title={cat.name}
                                    Imagesvg={cat.svg}
                                />
                                <div ref={cat.ref} id={cat.popupName} className="hidden popup preventClose">
                                    <div id={cat.popupContainer} className="popupcont preventClose">
                                        <div className="triangle preventClose"></div>

                                        <div className="preventClose">
                                            <ul className="preventClose">
                                                {cat.children &&
                                                    cat.children.map((sub) => {
                                                        return (
                                                            <SidebarPopup
                                                                key={sub.id}
                                                                sub={sub}
                                                                handleClick={() => handleClickLink(cat.ref)}
                                                            />
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div id="BottomNav" className="text-base font-medium rounded-md gap-y-4">
                    <div
                        className="flex items-center PopupContainer preventClose px-2 py-2"
                        // onClick={() => handleClickCat(AidePopupRef)}
                    >
                        {/* <a href="mailto:support@standardenligne.fr">
                            <SidebarTitle title="Support" Imagesvg={Help} />
                        </a> */}
                        <SidebarTitle onClick={() => handleClickCat(AidePopupRef)} title={"Support"} Imagesvg={Help} />
                        <div ref={AidePopupRef} id="AidePopup" className="hidden popup preventClose">
                            <div id="AidePopupContainer" className="popupcont preventClose">
                                <div className="triangle preventClose"></div>
                                <div className="preventClose">
                                    <ul className="preventClose">
                                        {/* <li className="preventClose" style={{ cursor: "pointer" }}>
                                            <a href="mailto:support@standardenligne.fr">Centre d'aide</a>
                                        </li> */}
                                        <li>
                                            <h4 className="preventClose">Support</h4>
                                            <ul className="PopupList preventClose">
                                                <Link
                                                    to="/support/tickets"
                                                    onClick={() => handleClickLink(AidePopupRef)}
                                                >
                                                    <li className="preventClose">Tickets</li>
                                                </Link>
                                                <Link to="/release" onClick={() => handleClickLink(AidePopupRef)}>
                                                    <li className="preventClose">Version 1.4.0</li>
                                                </Link>
                                                {/* <Link to="/support/tic"> */}
                                                <li className="preventClose">Centre d'aide (En cours)</li>
                                                {/* </Link> */}
                                                {/* <li className="preventClose">
                                                    <a href="mailto:support@standardenligne.fr">
                                                        Contacter le Support Technique
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center  px-2 py-2" onClick={logout}>
                        <SidebarTitle title="Déconnexion" Imagesvg={Exit} />
                    </div>
                </div>
            </nav>
        </div>
    );
};
export default SideBar;
