import "./PopupStripe.scss";

import PopupClassic from "../Classic";
import CheckoutForm from "./CheckoutForm";

const PopupStripe = ({ desc, credit_package, onClose, standard_number, option, porta }) => {
    return (
        <PopupClassic className="PopupStripe">
            <h3>Paiement</h3>
            <i
                className="fas fa-times onClose"
                onClick={() => {
                    onClose("close");
                }}
            />
            <p className="Desc">{desc}</p>
            <p className="Price HT">Montant HT : {credit_package?.price || option?.price || porta?.price || "XX"}€</p>
            <p className="Price TTC">
                Montant TTC : {credit_package?.price * 1.2 || option?.price * 1.2 || porta?.price * 1.2 || "XX"}€
            </p>
            <CheckoutForm
                close={onClose}
                standard_number={standard_number}
                credit_package_id={credit_package?.id}
                option={option}
                porta={porta}
            />
        </PopupClassic>
    );
};

export default PopupStripe;
