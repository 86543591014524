import api from "utils/api/api";

const UpdateCustomerTicket = async (id, answer, uploads) => {
    try {
        const response = await api.post(`/api/v1/zendesk/answer-ticket/${id}`, {
            answer,
            uploads,
        });

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { UpdateCustomerTicket };
