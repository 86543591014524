import "./Info.scss";

const Info = ({ title, children, className }) => {
    return (
        <div className={`${className || ""} InfoLayout`}>
            <div className="Head">
                <h2>{title}</h2>
            </div>
            <div className="Body">{children}</div>
        </div>
    );
};

export default Info;
