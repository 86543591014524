import { useState, useEffect } from "react";

import "./MessageFermeture.scss";

import { Layout, Config, HeadPage } from "components";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import { DisableEnableScriptKey } from "utils/api/config/reglages/DisableEnableScriptKey";

const MessageFermeture = () => {
    const { telephoneSwitchboard } = useMainContext();
    const { showMessage } = useMessageContext();

    const [message, setMessage] = useState({});

    const [listAction, setListAction] = useState([]);

    useEffect(() => {
        if (telephoneSwitchboard.get?.script) {
            setMessage(
                telephoneSwitchboard.get?.script?.Audio_Message?.find((item) => item.message_type === "Fermeture")
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telephoneSwitchboard.get]);

    const handleUpdateMessage = (text) => {
        setMessage({ ...message, text });
    };

    const handleChangeToogle = async (data) => {
        const req = await DisableEnableScriptKey({
            script_id: telephoneSwitchboard.get?.script?.id,
            key: "isCloseMessage",
            value: data,
        });

        if (!req.success) {
            return showMessage({
                type: "error",
                text: "La modification n'a pas été prise en compte.",
            });
        }

        showMessage({
            type: "success",
            text: "La modification a été prise en compte.",
        });
    };

    useEffect(() => {
        if (message?.id !== undefined) {
            const actions = [
                {
                    id: 1,
                    text: "Synthèse vocale",
                    component: (
                        <Config.StudioVocal.SyntheseVocal
                            key={"s1" + message?.id}
                            data={{ ...message, type: "Fermeture" }}
                            updateMessage={handleUpdateMessage}
                        />
                    ),
                },
                {
                    id: 2,
                    text: "Importer un fichier son",
                    component: <Config.StudioVocal.MP3 key={"s2" + message?.id} data={{ ...message }} />,
                },
            ];

            setListAction(actions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <Layout>
            <HeadPage isProfil={false} title="Studio Vocal" />
            <Config.StudioVocal.StudioVocalLayout
                key={message?.id + "" + listAction?.length}
                title="Message de fermeture"
                list={listAction}
                handleChangeToogle={handleChangeToogle}
                defaultToogle={telephoneSwitchboard.get?.script?.isCloseMessage}
            />
        </Layout>
    );
};

export default MessageFermeture;
