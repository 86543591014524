import { useEffect, useState, useContext } from "react";

import { MainContext } from "contexts";

import { InputList } from "components";

const Repondeur = () => {
    const { token } = useContext(MainContext);

    const [value, setValue] = useState(false);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/script/1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `${token.get}`,
            },
        });

        const result = await data.json();
        // console.log(result);
        setValue(result);
    };

    const toggleRepondeur = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/api/v1/script/repondeur/1`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                authorization: `${token.get}`,
            },
        });
    };

    return (
        <div>
            <p>Activer le repondeur</p>
            {value ? <InputList.InputToggle onChange={toggleRepondeur} defaultValue={value.repondeur} /> : null}
        </div>
    );
};

export default Repondeur;
