import { useEffect, useState } from "react";

import "./Impayes.scss";

import { useCookies } from "react-cookie";

import logo from "assets/images/logo/sel.svg";
import deconnect from "assets/icons/deconnect.svg";
import lock from "assets/icons/lock.svg";

import useMainContext from "contexts/MainContext";
import useMessageContext from "contexts/MessageContext";

import CreateCheckoutSession from "utils/api/unpaid/CreateCheckoutSession";

const Impayes = () => {
    const { token, customer, telephoneSwitchboard } = useMainContext();
    const { showMessage } = useMessageContext();

    const [listUnpaid, setListUnpaid] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies();

    const priceRejet = 20;
    const priceAbo = Number(listUnpaid?.[0]?.telephone_package?.price);
    const total = priceAbo + priceRejet;

    useEffect(() => {
        const isUnpaid = customer.get?.telephoneSwitchboard.filter((item) => item?.standard_number?.isUnpaid === true);

        if (isUnpaid.length > 0) {
            return setListUnpaid(isUnpaid);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        token.set(undefined);
        removeCookie(process.env.REACT_APP_COOKIE_NAME);
    };

    const handlePay = async () => {
        const response = await CreateCheckoutSession({
            customer_id: customer.get?.id,
            standard_number_id: listUnpaid?.[0]?.standard_number?.id,
            amount: (total * 1.2).toFixed(2),
        });

        if (!response.success) {
            // Faire la gestion d'erreur
            showMessage({
                message: "Une erreur est survenue. Veuillez contacter le support.",
                type: "error",
            });
            return;
        }

        // go to url (response.data.url)
        window.location.href = response.data.url;
    };

    return (
        <div className="page">
            <div className="navbar">
                <img src={logo} alt="logo" loading="lazy" />
                <img src={deconnect} onClick={logout} alt="bouton de deconnexion du backoffice" loading="lazy" />
            </div>
            <div className="content">
                <div className="content-data">
                    <img src={lock} alt=" cadenas qui indique que le compte a été bloqué" loading="lazy" />
                    <p className="number">
                        {listUnpaid?.[0]?.standard_number?.standard_number
                            ? "+" +
                              telephoneSwitchboard?.get?.standard_number?.standard_number.replace(
                                  /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
                                  "$1 $2 $3 $4 $5 $6"
                              )
                            : "+33 X XX XX XX XX"}
                    </p>
                    <p className="info-text">
                        Votre accès à votre espace client Standard En Ligne est bloqué. Pour débloquer votre compte et
                        réactiver votre standard, veuillez régler la facture suivante:
                    </p>
                    <div className="infos">
                        <div className="info">
                            <p className="name">
                                ABONNEMENT {listUnpaid?.[0]?.telephone_package?.name.toUpperCase()}:{" "}
                            </p>
                            <p className="price">{priceAbo.toFixed(2)} €</p>
                        </div>
                        <div className="info">
                            <p className="name">FRAIS DE REJET DE PAIEMENT: </p>
                            <p className="price">{priceRejet.toFixed(2)} €</p>
                        </div>
                        <div className="info">
                            <p className="name">Montant HT :</p>
                            <p className="price">{total.toFixed(2)} €</p>
                        </div>
                        <div className="info">
                            <p className="name">TVA (20,0%) :</p>
                            <p className="price">{(total * 0.2).toFixed(2)} €</p>
                        </div>
                        <div className="info">
                            <p className="name">Montant TTC :</p>
                            <p className="price">{(total * 1.2).toFixed(2)} €</p>
                        </div>
                        <button onClick={handlePay}>RÉGLER LA FACTURE</button>
                    </div>
                    <p className="info-text">Nous restons à votre écoute au 01 87 200 200 pour plus d’informations</p>
                </div>
            </div>
        </div>
    );
};

export default Impayes;
