import { React } from "react";
import "./AnnonceAccueil.scss";

import { Config } from "components";

import Message from "./Message/Message";

import useMessageContext from "contexts/MessageContext";

import { UpdateMusic } from "utils/api/config/StudioVocal/music/UpdateMusic";
import { DisableEnableScriptKey } from "utils/api/config/reglages/DisableEnableScriptKey";

const AnnonceAccueil = ({ setMusic, setWelcome, welcome, script }) => {
    const { showMessage } = useMessageContext();

    const handleChangeToogle = async (data) => {
        const req = await DisableEnableScriptKey({
            script_id: script?.id,
            key: "isMusic",
            value: data,
        });

        if (!req.success) {
            showMessage({
                type: "error",
                text: "La modification n'a pas été prise en compte.",
            });
            return;
        }

        showMessage({
            type: "success",
            text: "La modification a été prise en compte.",
        });
    };

    const handleChangeMusic = async (data) => {
        const response = await UpdateMusic({
            script_id: script?.id,
            data: data,
        });

        if (!response.success) {
            showMessage({
                type: "error",
                text: "La modification n'a pas été prise en compte.",
            });
            return;
        }

        setMusic(data);

        showMessage({
            type: "success",
            text: "La modification a été prise en compte.",
        });
    };

    const listAction = [
        {
            id: 1,
            text: "Catalogue",
            component: <Config.StudioVocal.FondMusical onChange={(music) => handleChangeMusic(music)} key={"s1"} />,
        },
    ];

    return (
        <div className="AnnonceAccueil">
            <h2>Mon annonce d’accueil</h2>
            <div className="AnnonceContent">
                <Message
                    data={welcome}
                    update={(id, text) => {
                        setWelcome({
                            text,
                            voiceId: id,
                        });
                    }}
                />
                <Config.StudioVocal.StudioVocalLayout
                    handleChangeToogle={handleChangeToogle}
                    title="Musique d'attente"
                    list={listAction}
                />
            </div>
        </div>
    );
};

export default AnnonceAccueil;
