import { useState } from "react";

import "./MandatSEPA.scss";

import { InputList } from "components";

const MandatSEPA = ({ update }) => {
    const [formValues, setFormValues] = useState({
        lastname: "",
        firstname: "",
        company_name: "",
        iban: "",
        country: "",
        address: "",
        zip: "",
        city: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        update({
            billing: { ...formValues, [name]: value },
        });
    };

    return (
        <div className="MandatSEPA">
            <div className="title">
                <h3>Mandat SEPA</h3>
                <i
                    className="fas fa-info-circle"
                    title="Nous vous demandons de renseigner les informations liées à votre Relevé d’Identité Bancaire pour plusieurs raisons:
                > Sécurité: Avec ces informations, nous pouvons attester légalement que vous, ou votre entreprise, association, êtes bien le titulaire de la ligne demandée. Nous devons nous assurer que l’entité qui est facturée de l’abonnement correspond à l’entité utilisatrice de la ligne.
                > Sérénité: En nous transmettant les informations liées à votre Relevé d’Identité Bancaire, vous nous autorisez à présenter en prélèvement SEPA la tentative de débit par carte bancaire qui a échouée (sans frais supplémentaire). Ainsi, votre ligne ne sera pas suspendue pour défaut de paiement. Si nous ne rencontrons pas d’incident de paiement, nous continuerons de débiter votre abonnement selon sa périodicité sur le moyen de paiement renseigné lors de la commande (Carte Bancaire ou PayPal)"
                />
            </div>

            <div className="MandatContainer">
                <div className="MandatLeft">
                    <div className="label">
                        <label>Nom</label>
                        <InputList.InputClassic
                            type={"text"}
                            name={"lastname"}
                            onChange={handleInputChange}
                            placeholder={"Doe"}
                        />
                    </div>

                    <div className="label">
                        <label>Nom de le société</label>
                        {/* <input type="text" placeholder="Service Commercial" /> */}
                        <InputList.InputClassic
                            type={"text"}
                            name={"company_name"}
                            onChange={handleInputChange}
                            placeholder={"Service Commercial"}
                        />
                    </div>

                    <div className="label">
                        <label>Pays</label>
                        <InputList.InputClassic
                            type={"text"}
                            name={"country"}
                            onChange={handleInputChange}
                            placeholder={"France"}
                        />
                    </div>

                    <div className="label">
                        <label>Code postal</label>
                        <InputList.InputClassic
                            type={"number"}
                            name={"zip"}
                            onChange={handleInputChange}
                            placeholder={"92170"}
                        />
                    </div>
                </div>

                <div className="MandatRight">
                    <div className="label">
                        <label>Prénom</label>
                        <InputList.InputClassic
                            type={"text"}
                            name={"firstname"}
                            onChange={handleInputChange}
                            placeholder={"John"}
                        />
                    </div>

                    <div className="label">
                        <label>IBAN</label>
                        <InputList.InputClassic
                            type={"text"}
                            name={"iban"}
                            onChange={handleInputChange}
                            placeholder={"FR 5112789472988979873289"}
                        />
                    </div>

                    <div className="label">
                        <label>Adresse</label>
                        <InputList.InputClassic
                            type={"text"}
                            name={"address"}
                            onChange={handleInputChange}
                            placeholder={"7 Rue du 4 Septembre"}
                        />
                    </div>

                    <div className="label">
                        <label>Ville</label>
                        <InputList.InputClassic
                            max={5}
                            type={"text"}
                            name={"city"}
                            onChange={handleInputChange}
                            placeholder={"Vanves"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MandatSEPA;
