import api from "utils/api/api";

const PatchCallLog = async (id, comment) => {
    try {
        const response = await api.patch(`/api/v1/call/${id}/${comment}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};
export { PatchCallLog };
