import api from "utils/api/api";

import { useMutation } from "react-query";

const PatchCustomerInfo = async (uuid, data) => {
    try {
        const response = await api.patch(`/api/v1/customer/${uuid}`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const usePatchCustomerInfo = () => useMutation((uuid, data) => PatchCustomerInfo(uuid, data));

export default usePatchCustomerInfo;
export { PatchCustomerInfo };
