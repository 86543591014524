import "./Release.scss";

const Release = () => {
    return (
        <section className="release">
            <h1>Mise à jour : 1.4.0</h1>
            <p>
                Nous sommes heureux d'annoncer la sortie de la version 1.4.0 de notre dashboard, et nous tenons à
                remercier chacun d'entre vous pour vos retours précieux. Grâce à vos suggestions, nous avons pu
                améliorer certaines fonctionnalités et corriger des problèmes techniques, rendant ainsi notre dashboard
                plus performant que jamais.
            </p>
            <ul>
                <li>
                    <h2>Nouvelles fonctionnalités :</h2>
                    <ul>
                        <li>
                            Vous avez désormais la possibilité de suivre la gestion de vos tickets. Dans la section
                            Support {">"} Tickets. (Vous recevrez un mail à chaque fois qu'un ticket est mis à jour)
                        </li>
                        <li>Nous avons intégré une visite guidée pour vous aider à prendre en main notre dashboard.</li>
                    </ul>
                </li>
                <li>
                    <h2>Améliorations :</h2>
                    <ul>
                        <li>Nous avons ajouté la possibilité de mettre 15 secondes en durée de sonnerie.</li>
                    </ul>
                </li>
                <li>
                    <h2>Correctifs techniques :</h2>
                    <ul>
                        <li>Lorsque l'on rafraichit la page reglages les paramètres ne se mettaient pas à jour.</li>
                    </ul>
                </li>
            </ul>
            <p className="footer">
                Chez Standard En Ligne, nous sommes déterminés à fournir un service de qualité, et vos commentaires
                jouent un rôle crucial dans l'amélioration continue de notre dashboard. Nous sommes reconnaissants pour
                votre soutien et vos suggestions. Restez à l'écoute, car nous continuerons à développer de nouvelles
                fonctionnalités passionnantes pour vous offrir la meilleure expérience possible.
            </p>
        </section>
    );
};

export default Release;
