import Skeleton from "react-loading-skeleton";

import { InputList } from "components";

import useMainContext from "contexts/MainContext";

const HeadPage = ({ title, isProfil, children, onChangeProfil, className }) => {
    const { customer, telephoneSwitchboard } = useMainContext();

    return (
        <div className={`mb-5 ${className || ""}`}>
            <div className="flex items-center justify-between">
                <h1>
                    <b>{title}</b>
                </h1>

                {isProfil !== false &&
                    (customer.get?.id ? (
                        <InputList.InputDropdown
                            defaultValue={"Profil " + telephoneSwitchboard.get?.Profile?.[0]?.profile_number}
                            list={telephoneSwitchboard.get?.Profile?.map((item) => ({
                                id: item.id,
                                text: "Profil " + item?.profile_number,
                            }))}
                            onChange={onChangeProfil}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="115px" height={"35px"} />
                    ))}
            </div>
            {children}
        </div>
    );
};

export default HeadPage;
