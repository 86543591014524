import { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { InputList } from "components";

import getBase64FromFile from "utils/helpers/getBase64FromFile";

import list from "utils/data/support-type.json";

import attach from "assets/icons/attach.svg";
// import validate from "assets/picto/validate.svg";

import { UploadFile } from "utils/api/zendesk/UploadFile";
import { CreateCustomerTicket } from "utils/api/zendesk/CreateCustomerTicket";

import useMessageContext from "contexts/MessageContext";

const SupportPopup = ({ onFinish }) => {
    const { showMessage } = useMessageContext();

    const [subject, setSubject] = useState(list[0]);
    const [selectedImages, setSelectedImages] = useState([]);

    const [step, setStep] = useState(1);

    const submitTicket = async (e) => {
        e.preventDefault();

        let uploads = [];
        // check if selectedImages is not empty
        if (selectedImages.length > 0) {
            console.log("selectedImages", selectedImages);
            // upload images
            await Promise.all(
                selectedImages.map((image) => {
                    return new Promise(async (resolve) => {
                        // name : image.info.name
                        // data : image.imageData

                        const uploadData = await UploadFile({
                            name: image.info.name,
                            data: image.imageData,
                        });

                        if (uploadData.success) {
                            uploads.push(uploadData.data.upload.token);
                        }

                        resolve();
                    });
                })
            );
        }

        // return console.log("uploads", uploads);

        const response = await CreateCustomerTicket({
            subject: e.target.title.value,
            description: e.target.description.value,
            type: subject.type,
            priority: "normal",
            uploads,
        });

        if (response.success) {
            showMessage({
                text: "Votre ticket est envoyé",
                type: "success",
            });

            onFinish();
        } else {
            showMessage({
                text: "Une erreur est survenue",
                type: "error",
            });
        }
    };

    const fileInputRef = useRef(null);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file && isImageFile(file)) {
            // Créer l'objet contenant les informations de l'image
            const imageInfo = {
                lastModified: file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type,
            };

            // Convertir l'image en données base64
            getBase64FromFile(file, (result) => {
                // Ajouter l'image et ses informations à l'état selectedImages
                setSelectedImages((prevImages) => [...prevImages, { imageData: result, info: imageInfo }]);
            });
        } else {
            showMessage({
                text: "Veuillez sélectionner une image valide (format .jpg, .png, .gif, etc.).",
                type: "error",
            });
        }
    };

    const isImageFile = (file) => {
        const acceptedImageTypes = ["image/jpeg", "image/png", "image/gif"];
        return file && acceptedImageTypes.includes(file.type);
    };

    const handleOpenFileInput = () => {
        fileInputRef.current.click();
    };

    if (step === 1) {
        return (
            <div className="support-popup new">
                <b>
                    <p>Ouvrir un Nouveau Ticket</p>
                </b>
                <span>Veuillez selectionner un sujet</span>
                <div>
                    <InputList.InputDropdown
                        onChange={(e) => setSubject(e)}
                        defaultValue={subject?.text}
                        list={list}
                        wCustom={"w-80"}
                    />
                    <InputList.InputButton onClick={() => setStep(2)} text="Suivant" />
                </div>
            </div>
        );
    }

    if (step === 2) {
        return (
            <div className="support-popup form">
                <p>
                    <b>Ouvrir un Nouveau Ticket</b> pour <b>{subject.text}</b>
                </p>
                <div className="popup-details">
                    <p className="article-text">Les articles ci-dessous contiennent peut-être votre problème</p>
                    <div className="articles">
                        {subject?.articles?.map((article) => {
                            return (
                                <Link
                                    key={article.link}
                                    className="article"
                                    to={article.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {article.text}
                                </Link>
                            );
                        })}
                    </div>

                    <form onSubmit={submitTicket}>
                        <p>Votre question :</p>
                        <InputList.InputClassic required={true} id="title" />

                        <p>Votre message :</p>
                        <div className="relative">
                            <InputList.InputTextarea defaultValue="" id="description" required={true} />

                            <label className="file-label" onClick={handleOpenFileInput}>
                                <img src={attach} alt="attach" /> Piece jointe
                            </label>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileInputChange}
                                accept=".jpg, .jpeg, .png, .gif"
                            />
                        </div>

                        {selectedImages?.length > 0 && (
                            <div className="selected-images-container">
                                <div className="selected-images">
                                    {selectedImages.map((imageData, index) => (
                                        <div key={index} className="image-container">
                                            <i
                                                onClick={() =>
                                                    setSelectedImages((prevImages) =>
                                                        prevImages.filter(
                                                            (image) => image.imageData !== imageData.imageData
                                                        )
                                                    )
                                                }
                                                className="far fa-trash"
                                            ></i>
                                            <img src={imageData.imageData} alt={`Fichier n${index}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <InputList.InputButton className={"submit-btn"} text="Envoyer" type="submit" />
                    </form>
                </div>
            </div>
        );
    }
};

export default SupportPopup;
