import { useState } from "react";

const InputToggle = ({ onChange, defaultValue }) => {
    const [toggle, setToggle] = useState(defaultValue || false);

    return (
        <button
            onClick={() => {
                setToggle(!toggle);
                onChange(!toggle);
            }}
            type="button"
            style={toggle ? { backgroundColor: "#264a6e" } : {}}
            className={`${
                toggle ? "" : "bg-gray-200"
            } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#264a6e]`}
            role="switch"
            aria-checked="false"
        >
            <span
                aria-hidden="true"
                className={`${
                    toggle ? "translate-x-5" : "translate-x-0"
                } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            ></span>
        </button>
    );
};

export default InputToggle;
