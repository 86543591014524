import React from "react";
import { Layout, HeadPage, Compte } from "components";

const Coordonees = () => {
    return (
        <Layout>
            <HeadPage isProfil={false} title="Mes coordonnées" />
            <Compte.Coordonnees />
        </Layout>
    );
};

export default Coordonees;
