import "./PopupClassic.scss";

const PopupClassic = ({ onClose, children, className }) => {
    const checkIfClickedOutside = (e) => {
        if (e.target.className === "PopupClassic") {
            onClose && onClose();
        }
    };

    return (
        <div className="PopupClassic" onClick={checkIfClickedOutside}>
            <div className={`${className || ""} Popup`}>
                {typeof onClose === "function" && (
                    <span className="Close" onClick={onClose}>
                        &times;
                    </span>
                )}
                <div className="Content">{children}</div>
            </div>
        </div>
    );
};

export default PopupClassic;
