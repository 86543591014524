import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ children, progress }) => {
    return (
        <div className="Progress">
            <div className="Progressbar">
                <div className="ProgressbarFill" style={{ width: `${progress}%` }} />
            </div>
            {children}
        </div>
    );
};

export default ProgressBar;
