import React from 'react';
import {useStripe, useElements, IbanElement} from '@stripe/react-stripe-js';

import IbanForm from './IbanForm';
import useMessageContext from 'contexts/MessageContext';
import { attachPaymentMethod } from 'utils/api/stripe';
import { useNavigate } from 'react-router-dom';

export default function PaymentSetupForm({clientSecret}) {
  const stripe = useStripe();
  const elements = useElements();
  const { showMessage } = useMessageContext()
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const iban = elements.getElement(IbanElement);

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name and email. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = event.target['accountholder-name'];
    const email = event.target.email;

    const result = await stripe.confirmSepaDebitSetup(`${clientSecret}`, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      }
    });

    if (result.error) {
      // Show error to your customer.
      console.log(result.error);
      showMessage({
        type: "error",
        text: result.error.message,
    });
    } else {
      // Show a confirmation message to your customer.
      // The SetupIntent is in the 'succeeded' state.
      if(result.setupIntent.status === 'succeeded'){
        const payment_method = result.setupIntent.payment_method;
        const payment_method_type = result.setupIntent.payment_method_types[0];
        attachPaymentMethod({payment_method,payment_method_type}).then((res)=>{
          console.log({res})
          localStorage.removeItem('isStripeSepaRequired')
          window.location.href = '/';
        }).catch((err)=>{
          console.log({err})
        })
        console.log({payment_method,payment_method_type})
      }

      console.log({result})
    }
  };

  return (
    <IbanForm onSubmit={handleSubmit} disabled={!stripe} />
  );
}