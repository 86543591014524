import "./CreditCard.scss";

import { InputList } from "components";

import { ReactComponent as Visa } from "assets/icons/money/visa.svg";
import { ReactComponent as MasterCard } from "assets/icons/money/mastercard.svg";

const CreditCard = ({ remove, card, changeDefault, id, addCard }) => {
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div
            className="CreditCardContainer"
            style={
                card
                    ? {}
                    : {
                          borderStyle: "dashed",
                          borderColor: "var(--primary-gray)",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                      }
            }
        >
            {card ? (
                <>
                    <div className="CreditCardHeader">
                        <p>Carte bancaire {card?.isDefault ? "principale" : "secondaire"}</p>
                        <InputList.InputCheckbox id={id} checked={card?.isDefault} onChange={changeDefault} />
                    </div>
                    <div className="CreditCardContent">
                        <div className="Infos">
                            <div className="flex items-center">
                                {card?.brand === "visa" ? <Visa className="Icon" /> : <MasterCard className="Icon" />}
                                <p className="credit-card-hidden">**** **** **** {card?.last4}</p>
                            </div>
                            <p className="card-details">
                                {capitalizeFirstLetter(card?.brand)} - Expire le {card?.exp_month}/{card?.exp_year}
                            </p>
                        </div>
                    </div>
                    <div className="CreditCardFooter">
                        <div>
                            {!card?.isDefault && (
                                <i
                                    className="fas fa-trash-alt"
                                    onClick={() => {
                                        remove(card.id);
                                    }}
                                />
                            )}
                            {card?.error && <p className="Error">{card.error || ""}</p>}
                            {card?.warning && <p className="Warning">{card.warning || ""}</p>}
                        </div>
                        {/* <p className="Option">Mettre à jour</p> */}
                    </div>
                </>
            ) : (
                <div className="CreditCardAdd" onClick={addCard}>
                    <div className="CreditCardHeader">
                        <p>Carte bancaire secondaire</p>
                    </div>
                    <i className="fal fa-plus" />
                </div>
            )}
        </div>
    );
};

export default CreditCard;
