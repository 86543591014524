import { useQuery } from "react-query";

import api from "utils/api/api";

const GetAllInvoiceOfCustomer = async ({ customer_mail }) => {
    try {
        const response = await api.get(`/api/v1/invoice/invoices/${customer_mail}`);
        console.log("response >> ", response.data);
        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetAllInvoiceOfCustomer = ({ customer_mail }) =>
    useQuery(["useGetAllInvoiceOfCustomer", customer_mail], async () => await GetAllInvoiceOfCustomer({ customer_mail }), {
        enabled: customer_mail !== undefined,
    });

export default useGetAllInvoiceOfCustomer;
export { GetAllInvoiceOfCustomer };
