import api from "utils/api/api";

const RemoveItem = async ({ item_id }) => {
    try {
        if (item_id === undefined) throw new Error("item_id is undefined");

        const response = await api.delete(`/api/v1/script/menu/item/${item_id}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { RemoveItem };
