import { useQuery } from "react-query";

import api from "utils/api/api";

const GetInvoiceUpcomingForStandardNumber = async ({ standard_number_id }) => {
    try {
        if (!standard_number_id) throw new Error("standard_number_id is required");

        const response = await api.get(`/api/v1/payment/invoice-upcoming/${standard_number_id}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetInvoiceUpcomingForStandardNumber = ({ standard_number_id }) =>
    useQuery(
        ["useGetInvoiceUpcoming", standard_number_id],
        async () => await GetInvoiceUpcomingForStandardNumber({ standard_number_id }),
        {
            enabled: standard_number_id !== undefined,
        }
    );

export default useGetInvoiceUpcomingForStandardNumber;
export { GetInvoiceUpcomingForStandardNumber };
