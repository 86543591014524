import api from "utils/api/api";

const PatchAddressInfo = async (uuid, data) => {
    try {
        const response = await api.patch(`/api/v1/address/${uuid}`, data);

        return response.data;
    } catch (err) {
        throw err;
    }
};

export { PatchAddressInfo };
