import React, { useState } from "react";

import { InputList } from "components";

import "./WriteMessage.scss";

const WriteMessage = ({ updateMsg }) => {
    const [count, setCount] = useState(0);
    const [message, setMessage] = useState("");
    const [smsCount, setSmsCount] = useState(1);

    const onChangeText = (text) => {
        setCount(text.length);
        setMessage(text);
        setSmsCount(Math.ceil(text.length / 160));
        updateMsg(text, smsCount);
    };

    const calcRemains = () => {
        return 160 * smsCount - count;
    };

    return (
        <div className="WriteMessageSMSPopup">
            <h3>Rédigez votre message</h3>
            <InputList.InputTextarea className="" defaultValue={message} onChange={onChangeText} />
            <p>
                SMS : {smsCount} &nbsp;{" "}
                <span style={calcRemains() <= 80 ? (calcRemains() <= 20 ? { color: "red" } : { color: "orange" }) : {}}>
                    {160 * smsCount - count} caractères restants
                </span>
            </p>
            <p>Attention : 1 SMS comporte 160 caractères</p>
        </div>
    );
};
export default WriteMessage;
